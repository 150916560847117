// services
const LOS_SERVICE = 'los:';
const USER_SERVICE = 'user:';
const MENU_SERVICE = 'menu:';
const LOAD_SERVICE = 'route:';
const USER_CONTROLLER = 'user';
const FLEET_SERVICE = 'fleet:';
const REPORT_SERVICE = 'report:';
const AI_SERVICE = 'ai-service:';
const CARRIER_SERVICE = 'carrier:';
const DISTANCE_SERVICE = 'distance:';
const CUSTOMER_SERVICE = 'customer:';
const TEMPLATES_SERVICE = 'templates:';
const STATISTIC_SERVICE = 'statistic:';
const REFERENCE_SERVICE = 'reference:';
const LOAD_BOARD_SERVICE = 'loadboard:';
const ENTERPRISE_SERVICE = 'enterprise:';
const EXT_INTEGRATION = 'ext-integration:';
const INTEGRATION_SERVICE = 'integration:';
const MAIL_SENDING_SERVICE = 'mail-sending:';
const NOTIFICATION_SERVICE = 'notification:';
const CONFIGURATION_SERVICE = 'configuration:';
const WEB_SOCKET_SERVICE = 'web-socket-service:';
const LOAD_BOARD_SERVICE_NEW = 'load-board-service:';
const LOAD_BOARD_LB123_SERVICE = 'load-board-lb123-service:';
// TODO: remove after back-end will deploy fix on dev
// const TASKMANAGEMENT_SERVICE = 'taskmanagement:';
const TASKMANAGEMENT_SERVICE = 'task-management:';
const DOCUMENT_TEMPLATE_SERVICE = 'document-template:';
const LOAD_BOARD_LOAD_SERVICE = 'load-board-load-service:';
// controllers
// load board search filter controller
const SEARCH_FILTER_CONTROLLER = 'search-filter';
// load board search filter template controller
const SEARCH_FILTER_TEMPLATE_CONTROLLER = 'search-filter-template';
// load board search filter state controller
const SEARCH_FILTER_STATE_CONTROLLER = 'search-filter-state';
// load board login controller
const LB_LOGIN = 'login';
// load board config controller
const LOAD_BOARD_CONFIG_NEW = 'load-board-config';
// load board committed shipment
const COMMITTED_SHIPMENT = 'committed-shipment';
// load board denied company
const DENIED_COMPANY = 'denied-company';
// load board posted shipment
const POSTED_SHIPMENT = 'posted-shipment';
// load board posted shipment state
const POSTED_SHIPMENT_STATE = 'posted-shipment-state';
// los service subscription controller
const LOS_SERVICE_SUBSCRIPTION_CONTROLLER = `${LOS_SERVICE}subscription/`;
// mail service integration config controller
const MAIL_SERVICE_INTEGRATION_CONFIG = 'mailServiceIntegration/config';
// mail service integration connect controller
const MAIL_SERVICE_INTEGRATION_CONNECT = 'mailServiceIntegration/connect';
// mail template controller
const MAIL_TEMPLATE_CONTROLLER = 'mail/template';
// mail template template controller
const MAIL_TEMPLATE_TEMPLATE_CONTROLLER = `${MAIL_TEMPLATE_CONTROLLER}/template`;
// eia-fuel-price-controller
const EIA_FUEL_PRICE_CONTROLLER = 'eia/fuelPrice';
// load-board config
const LOAD_BOARD_CONFIG = 'loadBoardConfig';
// user
const USER_ROLE_CONTROLLER = 'role';
const USER_PROFILE_CONTROLLER = 'userProfile';
const USER_REFERENCE_CONTROLLER = 'userReference';
// enterprise
const ENTERPRISE_CONTROLLER = 'enterprise';
const ENTERPRISE_STYLING_CONTROLLER = 'styling';
const ENTERPRISE_SPLASH_SCREEN_CONTROLLER = 'splashScreen';
const ENTERPRISE_DOCUMENT_CONTROLLER = 'enterprise/document';
const ENTERPRISE_COMMISSION_ASSIGNEE_CONTROLLER = '/commissionAssignee';

// templates
const TEMPLATES_ITEM_CONTROLLER = 'item';
const CONTACT_BOOK_CONTROLLER = 'contactBook';
const CONTAINER_TYPE_CONTROLLER = 'containerType';
const ROUTE_TEMPLATE_CONTROLLER = 'route/template';
const TEMPLATES_ITEM_REFERENCE_CONTROLLER = 'itemReference';
const TEMPLATES_LOCATION_TEMPLATE_CONTROLLER = 'locationTemplate';
const TEMPLATES_CONTAINER_TEMPLATE_CONTROLLER = 'containerTemplate';
const TEMPLATES_LOCATION_REFERENCE_CONTROLLER = 'locationReference';
const TEMPLATES_COMPENSATION_TEMPLATE_CONTROLLER = 'compensationTemplate';
// inspection
const INSPECTION_TEMPLATE_CONTROLLER = 'inspection/template';
// geo-fencing-location
const GEO_FENCING_LOCATION_CONTOILLER = 'geofencingLocation';
// configs
const CONFIGS_CONTROLLER = 'configs';
const TASK_TYPE_CONTROLLER = 'task/type';
const TASK_STATUS_CONTROLLER = 'task/status';
const CONFIG_SEQUENCE_CONTROLLER = 'sequence';
const GL_CODE_MAPPING_CONTROLLER = 'glCodeMapping';
const CONFIG_STATUS_CODE_CONTROLLER = 'statusCode';
const CONFIG_ASSESSORIAL_CONTROLLER = 'assessorial';
const CONFIG_NOTIFICATION_CONTROLLER = 'notification';
const QB_WEB_CONNECTOR_CONFIG = 'qb/webConnectorConfig';
const CONFIG_DI_STATUS_CONTROLLER = 'driverInvoiceStatus';
const CUSTOMER_ID_MAPPING_CONTROLLER = 'customerIdMapping';
const CONFIG_CI_STATUS_CONTROLLER = 'customerInvoiceStatus';
const SAGE_ACCOUNT_MAPPING_CONTROLLER = 'sageAccountMapping';
const DEPARTMENT_ID_MAPPING_CONTROLLER = 'departmentIdMapping';
const MARGIN_VIOLATION_RULE_CONTROLLER = 'marginViolationRule';
const QB_ACCOUNT_MAPPING_CONTROLLER = 'quickBooksAccountMapping';
const CONFIG_SHARED_ACCESSORIAL_CONTROLLER = 'sharedAssessorial';
const CONFIG_TRUCK_TYPE_MAPPING_CONTROLLER = 'truckType/mapping';
const CONFIG_SERVICE_TYPE_MAPPING_CONTROLLER = 'serviceType/mapping';
const CONFIG_TRAILER_TYPE_MAPPING_CONTROLLER = 'trailerType/mapping';
const CONFIG_DOCUMENT_TYPE_MAPPING_CONTROLLER = 'documentType/mapping';
const CONFIG_CARRIER_INVOICE_STATUS_CONTROLLER = 'carrierInvoiceStatus';
const QB_CUSTOM_FIELD_MAPPING_CONTROLLER = 'quickBooks/customFieldMapping';
const CONFIG_CARRIER_DEACTIVATION_RULE_CONTROLLER = 'carrierDeactivationRule';
const CONFIG_DEFAULT_INVOICE_STATUS_RULE_CONTROLLER = 'defaultInvoiceStatusRule';
const CONFIG_DEFAULT_CARRIER_ACCESSORIAL_CONTROLLER = 'defaultCarrierAssessorial';
const QB_IIF_ASSESSORIAL_MAPPING_CONTROLLER = 'quickBooks/iif/assessorialMapping';
const CONFIG_FLEET_SERVICE_INVOICE_STATUS_CONTROLLER = 'fleetServiceInvoiceStatus';
const CONFIG_CLO_DISPATCH_BOARD_ACTION_PANEL_CONTROLLER = 'cloDispatchBoardActionPanel';
const CONFIG_TEL_DISPATCH_BOARD_ACTION_PANEL_CONTROLLER = 'telDispatchBoardActionPanel';
// fleet
const FLEET_FUEL_CARD_CONTROLLER = 'fleet/fuelCard';
const FLEET_TOLL_CHARGE_CONTROLLER = 'fleet/tollCharge';
const TOLL_CHARGE_CONFIG_CONTROLLER = 'tollChargeConfig';
const FLEET_ADVANCE_PAYMENT_CONTROLLER = 'advancePayment';
const FLEET_DRIVER_EXPENSE_CONTROLLER = 'fleet/driver/expense';
const ELD_INTEGRATION_CONFIG_CONTROLLER = 'eldIntegrationConfig';
const FLEET_DRIVER_DAILY_LOG_CONTROLLER = 'fleet/driver/dailyLog';
const FLEET_ADVANCE_PAYMENT_CONFIG_CONTROLLER = 'advancePaymentConfig';
const FLEET_VENDOR_MONTHLY_PAYMENT_CONTROLLER = 'fleetVendor/monthlyPayment';
const FLEET_DRIVER_MONTHLY_PAYMENT_CONTROLLER = 'fleet/driver/monthlyPayment';
const FLEET_DRIVER_PENDING_ACTIVITY_CONTROLLER = 'fleet/driver/pendingActivity';
const FLEET_DRIVER_EXPENSE_DOCUMENT_CONTROLLER = 'fleet/driver/expense/document';
const DRIVER_PAYROLL_COMPENSATION_CONTROLLER = 'fleet/driver/payrollCompensation';
const DRIVER_ONBOARDING_INTEGRATION_CONFIG = 'driver/onboarding/integrationConfig';
const FLEET_DRIVER_UNAVAILABLE_PERIOD_CONTROLLER = 'fleet/driver/unavailablePeriod';
// clo
const CLO_CONTROLLER = 'clo';
const CLO_NOTE_CONTROLLER = 'clo/note';
const CLO_RATE_CONTROLLER = 'clo/rate';
const CLO_EVENT_CONTROLLER = 'clo/event';
const CLO_INVOICE_CONTROLLER = 'clo/invoice';
const CLO_DOCUMENT_CONTROLLER = 'clo/document';
const CLO_REFERENCE_CONTROLLER = 'clo/reference';
const CLO_CHAT_MSG_CONTROLLER = 'clo/chatMessage';
const CLO_RATE_CHARGE_CONTROLLER = 'clo/rate/charge';
const CLO_STATUS_MSG_CONTROLLER = 'clo/statusMessage';
const CLO_DISPATCHBOARD_CONTROLLER = 'clo/dispatchBoard';
const CLO_FLEET_ASSIGNMENT_CONTROLLER = 'clo/fleetAssignment';
const CUSTOMER_MASTER_INVOICE_CONTROLLER = 'clo/masterInvoice';
const CUSTOMER_MASTER_INVOICE_FACTORING_CONTROLLER = 'masterInvoice/factoring';
// claim
const CLAIM_NOTE_CONTROLLER = 'claim/note';
const CLAIM_DOCUMENT_CONTROLLER = 'claim/document';
const CLAIM_ATTORNEY_CONTROLLER = 'claim/attorney';
const CLAIM_ACCIDENT_CONTROLLER = 'claim/accident';
const CLAIM_DAMAGED_ITEM_CONTROLLER = 'claim/damagedItem';
const CLAIM_INVOLVED_PARTY_CONTROLLER = 'claim/involvedParty';
// tel
const TEL_CONTROLLER = 'tel';
const TEL_NOTE_CONTROLLER = 'tel/note';
const TEL_RATE_CONTROLLER = 'tel/rate';
const TEL_EVENT_CONTROLLER = 'tel/event';
const TEL_INVOICE_CONTROLLER = 'tel/invoice';
const TEL_DOCUMENT_CONTROLLER = 'tel/document';
const TEL_REFERENCE_CONTROLLER = 'tel/reference';
const TEL_CHAT_MSG_CONTROLLER = 'tel/chatMessage';
const DRIVER_PAYROLL_CONTROLLER = 'driverPayroll';
const TEL_LOAD_BOARD_CONTROLLER = 'tel/loadBoard';
const TEL_FOR_CARRIER_CONTROLLER = 'tel/forCarrier';
const TEL_RATE_CHARGE_CONTROLLER = 'tel/rate/charge';
const TEL_STATUS_MSG_CONTROLLER = 'tel/statusMessage';
const TEL_DRIVER_CARDS_CONTROLLER = 'tel/driverCards';
const TEL_INVOICE_FLEET_CONTROLLER = 'tel/invoice/fleet';
const TEL_DISPATCHBOARD_CONTROLLER = 'tel/dispatchBoard';
const TEL_LINKED_CLO_LIST_CONTROLLER = 'tel/linkedClo/list';
const TEL_FLEET_ASSIGNMENT_CONTROLLER = 'tel/fleetAssignment';
const DRIVER_PAYROLL_DOCUMENT_CONTROLLER = 'driverPayroll/document';
const TEL_INVOICE_FLEET_VENDOR_CONTROLLER = 'tel/invoice/fleetVendor';
const TEL_CROSS_BORDER_INTEGRATION_CONTROLLER = 'tel/crossBorderIntegration';
// document hub integration config
const DOCUMENT_HUB_INTEGRATION_CONFIG_CONTROLLER = 'documentHubIntegration/config';
// document hub integration config
const ACCOUNTING_INTEGRATION_CONFIG_CONTROLLER = 'accountingIntegrationConfig';
// fleet
const FLEET_TRUCK_CONTROLLER = 'fleet/truck';
const FLEET_VENDOR_CONTROLLER = 'fleetVendor';
const FLEET_DRIVER_CONTROLLER = 'fleet/driver';
const FLEET_TRAILER_CONTROLLER = 'fleet/trailer';
const FLEET_WORK_ORDER_CONTROLLER = 'fleet/workOrder';
const FLEET_INSPECTION_CONTROLLER = 'fleet/inspection';
const FLEET_WORK_ORDER_NOTE_CONTROLLER = 'workOrder/note';
const FLEET_SERVICE_ISSUE_CONTROLLER = 'fleet/serviceIssue';
const FLEET_VENDOR_PAYROLL_CONTROLLER = 'fleetVendorPayroll';
const FLEET_DISPATCHING_GROUP_CONTROLLER = 'dispatchingGroup';
const FLEET_VENDOR_CONTACT_CONTROLLER = 'fleetVendor/contact';
const FLEET_VENDOR_DOCUMENT_CONTROLLER = 'fleetVendor/document';
const FLEET_WORK_ORDER_DOCUMENT_CONTROLLER = 'workOrder/document';
const FLEET_VENDOR_REFERENCE_CONTROLLER = 'fleetVendor/reference';
const FLEET_VENDOR_INSURANCE_CONTROLLER = 'fleetVendor/insurance';
const FLEET_SHARED_COMPONENT_CONTROLLER = 'fleet/sharedComponent';
const FLEET_TRUCK_INSPECTION_CONTROLLER = 'fleet/truck/inspection';
const FLEET_EQUIPMENT_SERVICE_CONTROLLER = 'fleet/equipmentService';
const FLEET_VENDOR_ACCESSORIAL_CONTROLLER = 'fleetVendor/assessorial';
const FLEET_TRAILER_INSPECTION_CONTROLLER = 'fleet/trailer/inspection';
const FLEET_VENDOR_COMPENSATION_CONTROLLER = 'fleetVendor/compensation';
const FLEET_DRIVER_AVAILABILITY_CONTROLLER = 'fleet/driver/availability';
const FLEET_VENDOR_PAY_TO_LOCATION_CONTROLLER = 'fleetVendor/payToLocation';
const FLEET_TRUCK_WORK_ORDER_INVOICE_CONTROLLER = 'truck/workOrder/invoice';
const FLEET_TRAILER_WORK_ORDER_INVOICE_CONTROLLER = 'trailer/workOrder/invoice';
const FLEET_TRUCK_WORK_ORDER_CONTROLLER = `${FLEET_TRUCK_CONTROLLER}/workOrder`;
const FLEET_TRAILER_WORK_ORDER_CONTROLLER = `${FLEET_TRAILER_CONTROLLER}/workOrder`;
const FLEET_VENDOR_PAYROLL_ACCESSORIAL_CONTROLLER = 'fleetVendor/payrollAssessorial';
const FLEET_TRUCK_SERVICE_ISSUE_CONTROLLER = `${FLEET_TRUCK_CONTROLLER}/serviceIssue`;
const FLEET_TRAILER_SERVICE_ISSUE_CONTROLLER = `${FLEET_TRAILER_CONTROLLER}/serviceIssue`;
const FLEET_INSPECTION_SECTION_COMPONENT_CONTROLLER = 'fleet/inspection/section/component';
const FLEET_VENDOR_COMPENSATION_ACCESSORIAL_CONTROLLER = 'fleetVendor/compensation/assessorial';
const FLEET_TRAILER_TRACKING_INTEGRATION_CONFIG_CONTROLLER = 'fleet/trailer/trackingIntegrationConfig';
const FLEET_INSPECTION_SECTION_COMPONENT_DOCUMENT_CONTROLLER = 'fleet/inspection/section/component/document';
// fleet driver safety violation
const SAFETY_VIOLATION_CONTROLLER = 'safety/violation';
const SAFETY_VIOLATION_NOTE_CONTROLLER = 'safety/violation/note';
const SAFETY_VIOLATION_DOCUMENT_CONTROLLER = 'safety/violation/document';
// statistic
const STATISTIC_CHART_CONTROLLER = 'chart';
const STATISTIC_DASHBOARD_CONTROLLER = 'dashboard';
// carrier
const CARRIER_CONTROLLER = 'carrier';
const RATE_ENGINE_CONTROLLER = 'rateEngine';
const CARRIER_MAIL_CONTROLLER = 'carrier/mail';
const SERVICE_VENDOR_CONTROLLER = 'serviceVendor';
const GEO_FENCING_ZONE_CONTROLLER = 'geofencingZone';
const CARRIER_DOCUMENT_CONTROLLER = 'carrier/document';
const CARRIER_TERMINAL_CONTROLLER = 'carrier/terminal';
const CARRIER_SYNCHRONIZATION = 'carrierSynchronization';
const CARRIER_INVOICE_CONTROLLER = 'tel/invoice/carrier';
const CARRIER_INSURANCE_CONTROLLER = 'carrier/insurance';
const CARRIER_SERVICE_VENDOR_CONTROLLER = 'serviceVendor';
const CARRIER_SAFER_WATCH_CONTROLLER = 'carrier/saferWatch';
const CARRIER_HAZMAT_CONTROLLER = 'carrier/hazMatCertificate';
const CARRIER_EDI_LOADER_CONFIG_CONTROLLER = 'edi/loaderConfig';
const CARRIER_FINANCIAL_CONTROLLER = 'carrier/financialDetails';
const CARRIER_REFERENCE_CONTROLLER = 'carrier/carrierReference';
const SERVICE_VENDOR_DOCUMENT_CONTROLLER = 'serviceVendor/document';
const CARRIER_EDI_EXPORTER_CONFIG_CONTROLLER = 'edi/exporterConfig';
const CARRIER_ASSOC_COMPANY_CONTROLLER = 'carrier/associatedCompany';
const CARRIER_CONTRACT_BILL_TO = 'rateEngine/carrierContract/billTo';
const CARRIER_TERMINAL_CONTACT_CONTROLLER = 'carrier/terminalContact';
const SERVICE_VENDOR_INVOICE_CONTROLLER = 'tel/invoice/serviceVendor';
const CUSTOMER_CONTRACT_BILL_TO = 'rateEngine/customerContract/billTo';
const CARRIER_SCORECARD_CONFIG_CONTROLLER = 'carrier/scorecard/config';
const SERVICE_VENDOR_REPAIR_ZONE_CONTROLLER = 'serviceVendor/repairZone';
const CARRIER_ONBOARDING_PACKAGE_CONTROLLER = 'carrier/onboardingPackage';
const CARRIER_INTEGRATION_CONFIG_CONTROLLER = 'carrierIntegration/config';
const CARRIER_INTERNAL_DISPATCH_SETTINGS = 'carrier/internalDispatchSettings';
const CARRIER_LOAD_BOARD_INTEGRATION_CONTROLLER = 'carrier/loadBoardIntegration';
const CARRIER_PICKUP_REQUESTER_CONTROLLER = 'carrierIntegration/config/requester';
const EDI_CARRIER_INVOICE_TRANSACTION_CONTROLLER = 'edi/carrierInvoiceTransaction';
const CARRIER_LOAD_BOARD_SYNCHRONIZATION_CONTROLLER = 'carrier/loadBoardSynchronization';
const USER_TERMS_AND_CONDITIONS_DOCUMENT_CONTROLLER = 'user/termsAndConditions/document';
const CARRIER__INTEGRATION_CUSTOMER_CONFIG_CONTROLLER = 'carrierIntegration/customerConfig';
const CARRIER_TERMS_AND_CONDITIONS_DOCUMENT_CONTROLLER = 'carrier/termsAndConditions/document';
const CUSTOMER_TERMS_AND_CONDITIONS_DOCUMENT_CONTROLLER = 'customer/termsAndConditions/document';
const CARRIER_ONBOARDING_PACKAGE_DOCUMENT_CONTROLLER = `${CARRIER_ONBOARDING_PACKAGE_CONTROLLER}/document`;
const CARRIER_RATING_INTEGRATION_ACCESSORIAL_SERVICE_MAPPING = 'ratingIntegration/assessorialServiceMapping';
// route item
const ROUTE_ITEM_CONTROLLER = 'item';
// load-planner
const LOAD_PLANNER_CONTROLLER = 'loadPlanner';
// load-planner-template
const LOAD_PLANNER_TEMPLATE_CONTROLLER = 'loadPlannerTemplate';
// route
const ROUTE_CONTROLLER = 'route';
// route mass create report
const ROUTE_MASS_CREATE_REPORT_CONTROLLER = 'route/massCreateReport';
// customer integration config
const CUSTOMER_INTEGRATION_CONFIG = 'customerIntegrationConfig';
// customer integration config
const USER_MONITORING_CONFIG = 'userMonitoringConfig';
// order quote
const ROUTE_ORDER_QUOTE_CONTROLLER = 'orderQuote';
// factoring integration config controller
const FACTORING_INTEGRATION_CONFIG_CONTROLLER = 'integration/factoringConfig';
// fleet equipment service document controller
const getFleetEquipmentServiceEntityDocumentControllerByEntityType = (entityType: string = 'truck') =>
  `fleet/${entityType}/${entityType}EquipmentService/document`;
// driver
const DRIVER_EXPENSE_TYPE_CONTROLLER = 'driver/expense/type';
// cross border integration config controller
const CROSS_BORDER_INTEGRATION_CONFIG_CONTROLLER = 'integration/crossBorder/config';

const endpoints = {
  losSsoToken: `${LOS_SERVICE}sso/token`,
  losSsoRedirectUrl: `${LOS_SERVICE}sso/redirectUrl`,
  losSubscriptionConfig: `${LOS_SERVICE}subscriptionConfig`,
  // pricing plan controller
  losPricingPlan: `${LOS_SERVICE}pricingPlan`,
  losPricingPlanList: `${LOS_SERVICE}pricingPlan/list`,
  losPricingPlanByGuid: (guid: string) => `${LOS_SERVICE}pricingPlan/${guid}`,
  // los service subscription controller
  losSubscriptionList: `${LOS_SERVICE_SUBSCRIPTION_CONTROLLER}list`,
  losSubscriptionUnlink: `${LOS_SERVICE_SUBSCRIPTION_CONTROLLER}unlink`,
  losSubscriptionAccept: (guid: string) => `${LOS_SERVICE_SUBSCRIPTION_CONTROLLER}${guid}/accept`,
  losSubscriptionReject: (guid: string) => `${LOS_SERVICE_SUBSCRIPTION_CONTROLLER}${guid}/reject`,
  losSubscriptionGenerateLosLinkingCode: `${LOS_SERVICE_SUBSCRIPTION_CONTROLLER}generateLosLinkingCode`,
  // TASKMANAGEMENT SERVICE
  // taskmanagement task controller
  task: `${TASKMANAGEMENT_SERVICE}task`,
  taskList: `${TASKMANAGEMENT_SERVICE}task/list`,
  taskSummaryByStatus: `${TASKMANAGEMENT_SERVICE}task/summaryByStatus`,
  taskListForAssignee: `${TASKMANAGEMENT_SERVICE}task/list/forAssignee`,
  getTaskByGuid: (guid: string) => `${TASKMANAGEMENT_SERVICE}task/${guid}`,
  changeTaskStatus: (guid: string) => `${TASKMANAGEMENT_SERVICE}task/${guid}/status`,
  // taskmanagement task board controller
  taskBoard: `${TASKMANAGEMENT_SERVICE}task/board`,
  taskBoardList: `${TASKMANAGEMENT_SERVICE}task/board/list`,
  removeTaskBoard: (guid: string) => `${TASKMANAGEMENT_SERVICE}task/board/${guid}`,
  setDefaultTaskBoard: (guid: string) => `${TASKMANAGEMENT_SERVICE}task/board/${guid}/setDefault`,
  // taskmanagement task comment controller
  taskComment: `${TASKMANAGEMENT_SERVICE}task/comment`,
  taskCommentList: `${TASKMANAGEMENT_SERVICE}task/comment/list`,
  taskCommentByGuid: (guid: string) => `${TASKMANAGEMENT_SERVICE}task/comment/${guid}`,
  // taskmanagement task attachment controller
  taskAttachment: `${TASKMANAGEMENT_SERVICE}task/attachment`,
  taskAttachmentList: `${TASKMANAGEMENT_SERVICE}task/attachment/list`,
  taskAttachmentByGuid: (guid: string) => `${TASKMANAGEMENT_SERVICE}task/attachment/${guid}`,
  // MAIL SENDING SERVICE
  // mail service integration config controller
  mailServiceIntegrationConfig: `${MAIL_SENDING_SERVICE}${MAIL_SERVICE_INTEGRATION_CONFIG}`,
  mailServiceIntegrationConfigList: `${MAIL_SENDING_SERVICE}${MAIL_SERVICE_INTEGRATION_CONFIG}/list`,
  getMailServiceIntegrationConfig: (guid: string) =>
    `${MAIL_SENDING_SERVICE}${MAIL_SERVICE_INTEGRATION_CONFIG}/${guid}`,
  getMailServiceIntegrationConfigAvailableIntegrationType:
    `${MAIL_SENDING_SERVICE}${MAIL_SERVICE_INTEGRATION_CONFIG}/availableIntegrationType`,
  removeMailServiceIntegrationConfig: (guid: string) =>
    `${MAIL_SENDING_SERVICE}${MAIL_SERVICE_INTEGRATION_CONFIG}/${guid}`,
  // mail service integration connect controller
  mailServiceIntegrationConnectConnectionRequired:
    `${MAIL_SENDING_SERVICE}${MAIL_SERVICE_INTEGRATION_CONNECT}/connectionRequired`,
  isConnectedToMail: `${MAIL_SENDING_SERVICE}${MAIL_SERVICE_INTEGRATION_CONNECT}/connected`,
  connectToMailForUser: `${MAIL_SENDING_SERVICE}${MAIL_SERVICE_INTEGRATION_CONNECT}/forUser`,
  connectToMailForBranch: `${MAIL_SENDING_SERVICE}${MAIL_SERVICE_INTEGRATION_CONNECT}/forEnterprise`,
  disconnectFromMailIntegration: (guid: string) =>
    `${MAIL_SENDING_SERVICE}${MAIL_SERVICE_INTEGRATION_CONNECT}/${guid}/disconnect`,
  // mail template controller
  mailTemplate: `${MAIL_SENDING_SERVICE}${MAIL_TEMPLATE_CONTROLLER}`,
  mailTemplateList: `${MAIL_SENDING_SERVICE}${MAIL_TEMPLATE_CONTROLLER}/list`,
  mailTemplatePreview: `${MAIL_SENDING_SERVICE}${MAIL_TEMPLATE_CONTROLLER}/preview`,
  getAvailableMailTemplates: `${MAIL_SENDING_SERVICE}${MAIL_TEMPLATE_CONTROLLER}/available`,
  // mail template template controller
  getMailTemplateByKey: `${MAIL_SENDING_SERVICE}${MAIL_TEMPLATE_TEMPLATE_CONTROLLER}`,
  mailTemplateTypes: `${MAIL_SENDING_SERVICE}${MAIL_TEMPLATE_TEMPLATE_CONTROLLER}/types`,
  mailTemplateNames: `${MAIL_SENDING_SERVICE}${MAIL_TEMPLATE_TEMPLATE_CONTROLLER}/names`,
  mailTemplatePreviewDataFile: `${MAIL_SENDING_SERVICE}${MAIL_TEMPLATE_TEMPLATE_CONTROLLER}/previewDataFile`,
  mailTemplateVariables: `${MAIL_SENDING_SERVICE}${MAIL_TEMPLATE_TEMPLATE_CONTROLLER}/previewDataFile/variables`,
  // EXT INTEGRATION SERVICE
  //eia fuel price controller
  averageFuelPriceList: `${EXT_INTEGRATION}${EIA_FUEL_PRICE_CONTROLLER}/list`,
  averageFuelPriceLatest: `${EXT_INTEGRATION}${EIA_FUEL_PRICE_CONTROLLER}/latest`,
  getAllAverageFuelPrices: `${EXT_INTEGRATION}${EIA_FUEL_PRICE_CONTROLLER}/list/all`,
  averageFuelPriceManualCreate: `${EXT_INTEGRATION}${EIA_FUEL_PRICE_CONTROLLER}/manualCreate`,
  averageFuelPriceInternalByDate: `${EXT_INTEGRATION}${EIA_FUEL_PRICE_CONTROLLER}/internal/byDate`,
  // exchange rate controller
  exchangeRateList: `${EXT_INTEGRATION}exchangeRate/list`,
  exchangeRateByDate: `${EXT_INTEGRATION}exchangeRate/byDate`,
  exchangeRateMapByDate: `${EXT_INTEGRATION}exchangeRate/mapByDate`,
  exchangeRateByMasterCurrency: `${EXT_INTEGRATION}exchangeRate/byMasterCurrency`,
  // custom exchange rate controller
  customExchangeRate: `${EXT_INTEGRATION}customExchangeRate`,
  customExchangeRateList: `${EXT_INTEGRATION}customExchangeRate/list`,
  customExchangeRateByGuid: (guid: string) => `${EXT_INTEGRATION}customExchangeRate/${guid}`,
  // USER SERVICE
  userSocket: `${USER_SERVICE}websocket`,
  // auth
  token: `${USER_SERVICE}token`,
  login: `${USER_SERVICE}oauth/token`,
  forgot: `${USER_SERVICE}password/forgot`,
  checkToken: `${USER_SERVICE}oauth/check_token`,
  resetPassword: `${USER_SERVICE}password/reset`,
  updatePassword: `${USER_SERVICE}password/update`,
  createNewPassword: `${USER_SERVICE}password/save`,
  // permissions
  permissionsList: `${USER_SERVICE}permission/list`,
  permissionGroupList: `${USER_SERVICE}permission/group/list`,
  // role controller
  role: `${USER_SERVICE}${USER_ROLE_CONTROLLER}`,
  userRoles: `${USER_SERVICE}${USER_ROLE_CONTROLLER}/list`,
  listRoles: `${USER_SERVICE}${USER_ROLE_CONTROLLER}/list/owned`,
  listRolesAvailable: `${USER_SERVICE}${USER_ROLE_CONTROLLER}/list/available`,
  grantRolesToChildren: `${USER_SERVICE}${USER_ROLE_CONTROLLER}/grantToChildren`,
  roleTypePermissions: `${USER_SERVICE}${USER_ROLE_CONTROLLER}/roleTypePermissions`,
  getRoleEndpoint: (guid: string) => `${USER_SERVICE}${USER_ROLE_CONTROLLER}/${guid}`,
  availableRolesByType: `${USER_SERVICE}${USER_ROLE_CONTROLLER}/list/availableByType`,
  revokeRolesFromChildren: `${USER_SERVICE}${USER_ROLE_CONTROLLER}/revokeFromChildren`,
  getRoleGrantedBranchGuidsEndpoint: (guid: string) =>
    `${USER_SERVICE}${USER_ROLE_CONTROLLER}/${guid}/grantedEnterpriseGuids`,
  // user controller
  userSsoToken: `${USER_SERVICE}sso/token`,
  createUser: `${USER_SERVICE}${USER_CONTROLLER}`,
  listUsers: `${USER_SERVICE}${USER_CONTROLLER}/list`,
  userSsoRedirectUrl: `${USER_SERVICE}sso/redirectUrl`,
  userSsoByNalId: `${USER_SERVICE}${USER_CONTROLLER}/sso/byNalId`,
  userExportReport: `${USER_SERVICE}${USER_CONTROLLER}/list/export`,
  userListForTask: `${USER_SERVICE}${USER_CONTROLLER}/list/forTask`,
  listUsersGeneral: `${USER_SERVICE}${USER_CONTROLLER}/list/general`,
  assignRoles: `${USER_SERVICE}${USER_CONTROLLER}/assignRolesToUsers`,
  revokeRoles: `${USER_SERVICE}${USER_CONTROLLER}/revokeRolesFromUsers`,
  userListByCarrier: `${USER_SERVICE}${USER_CONTROLLER}/list/byCarrier`,
  getRemoveUserEndpoint: (guid: string) => `${USER_SERVICE}${USER_CONTROLLER}/${guid}`,
  getUserResumeEndpoint: (guid: string) => `${USER_SERVICE}${USER_CONTROLLER}/${guid}/activate`,
  getUserSuspendEndpoint: (guid: string) => `${USER_SERVICE}${USER_CONTROLLER}/${guid}/deactivate`,
  getUserWithProfileEndpoint: (guid: string) => `${USER_SERVICE}${USER_CONTROLLER}/${guid}/withProfile`,
  userListGeneralChildrenAndCurrent: `${USER_SERVICE}${USER_CONTROLLER}/list/general/childrenAndCurrent`,
  // user-profile controller
  updateUserSettings: `${USER_SERVICE}${USER_PROFILE_CONTROLLER}`,
  pinBranch: `${USER_SERVICE}${USER_PROFILE_CONTROLLER}/pinEnterprise`,
  removePhoto: `${USER_SERVICE}${USER_PROFILE_CONTROLLER}/removePhoto`,
  unpinBranch: `${USER_SERVICE}${USER_PROFILE_CONTROLLER}/unpinEnterprise`,
  profilePhotoUrl: `${USER_SERVICE}${USER_PROFILE_CONTROLLER}/profilePhotoUrl`,
  addProfilePhoto: `${USER_SERVICE}${USER_PROFILE_CONTROLLER}/addProfilePhoto`,
  changePinnedMenuItems: `${USER_SERVICE}${USER_PROFILE_CONTROLLER}/pinnedMenuItems`,
  removeProfilePhoto: `${USER_SERVICE}${USER_PROFILE_CONTROLLER}/removeProfilePhoto`,
  userAcceptTermsAndConditions: `${USER_SERVICE}${USER_PROFILE_CONTROLLER}/acceptTermsAndConditions`,
  // user-reference controller
  userReference: `${USER_SERVICE}${USER_REFERENCE_CONTROLLER}`,
  userReferenceList: `${USER_SERVICE}${USER_REFERENCE_CONTROLLER}/list`,
  getRemoveUserRefEndpoint: (guid: string) => `${USER_SERVICE}${USER_REFERENCE_CONTROLLER}/${guid}`,
  // BRANCH SERVICE
  branchSocket: `${ENTERPRISE_SERVICE}websocket`,
  // enterprise controller
  branch: `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}`,
  branchList: `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/list`,
  shareBranch: `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/share`,
  branchesTree: `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/tree`,
  branchBillTo: `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/billTo`,
  shareBranches: `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/addSharing`,
  mastersBranchList: `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/masters`,
  branchBillToSave: `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/billTo/save`,
  getParentBranchGuidsByBranchGuid: (guid: string) =>
    `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/parentsGuids`,
  getBranchListByType: (guid: string) =>
    `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/byType`,
  unshareBranches: `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/removeSharing`,
  branchExportReport: `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/list/export`,
  branchPrimaryLocation: `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/primaryLocation`,
  additionalLocation: `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/additionalLocation`,
  getCurrentBranchEndpoint: (guid: string) => `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}`,
  getBranchBillToEndpoint: (guid: string) => `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/billTo/${guid}`,
  getDeleteBranchEndpoint: (guid: string) => `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/delete`,
  getRestoreBranchEndpoint: (guid: string) => `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/restore`,
  getSuspendBranchEndpoint: (guid: string) => `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/suspend`,
  getActivateBranchEndpoint: (guid: string) => `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/activate`,
  getUnsuspendBranchEndpoint: (guid: string) => `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/unsuspend`,
  getDeactivateBranchEndpoint: (guid: string) => `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/deactivate`,
  getBranchSharedDivisions: (guid: string) => `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/sharedToDivisions`,
  getParentAndCustomerBranchesWithoutRootByBranchGuid: (guid: string) =>
    `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/parentsWithoutRootAndCustomers`,
  deleteAdditionalLocation: (guid: string) =>
  `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/additionalLocation/${guid}`,
  getBranchPrimaryLocationEndpoint: (guid: string) => (
    `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/primaryLocation/${guid}`
  ),
  getBranchActiveChildrenListEndpoint: (guid: string) => (
    `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/activeChildren`
  ),
  getBranchChildrenWithShared: (guid: string) => (
    `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/childrenWithShared`
  ),
  getBranchAllChildrenWithShared: (guid: string) => (
    `${ENTERPRISE_SERVICE}${ENTERPRISE_CONTROLLER}/${guid}/allChildrenWithShared`
  ),
  // enterprise document controller
  branchDocument: `${ENTERPRISE_SERVICE}${ENTERPRISE_DOCUMENT_CONTROLLER}`,
  branchDocumentList: `${ENTERPRISE_SERVICE}${ENTERPRISE_DOCUMENT_CONTROLLER}/list`,
  branchDocumentUpdate: `${ENTERPRISE_SERVICE}${ENTERPRISE_DOCUMENT_CONTROLLER}/update`,
  branchDocumentDownload: `${ENTERPRISE_SERVICE}${ENTERPRISE_DOCUMENT_CONTROLLER}/downloadFile`,
  getBranchDocumentEndpoint: (guid: string) => `${ENTERPRISE_SERVICE}${ENTERPRISE_DOCUMENT_CONTROLLER}/${guid}`,
  // commission assignee controller
  commissionAssignee: `${ENTERPRISE_SERVICE}.${ENTERPRISE_COMMISSION_ASSIGNEE_CONTROLLER}`,
  commissionAssigneeList: `${ENTERPRISE_SERVICE}.${ENTERPRISE_COMMISSION_ASSIGNEE_CONTROLLER}/list`,
  commissionAssigneeAvailableList: `${ENTERPRISE_SERVICE}.${ENTERPRISE_COMMISSION_ASSIGNEE_CONTROLLER}/list/available`,
  // styling controller
  styling: `${ENTERPRISE_SERVICE}${ENTERPRISE_STYLING_CONTROLLER}`,
  stylingUpdate: `${ENTERPRISE_SERVICE}${ENTERPRISE_STYLING_CONTROLLER}/update`,
  getStylingEndpoint: (guid: string) => `${ENTERPRISE_SERVICE}${ENTERPRISE_STYLING_CONTROLLER}/${guid}`,
  getStylingByStylingGuidEndpoint: (branchGuid: string) => (
    `${ENTERPRISE_SERVICE}${ENTERPRISE_STYLING_CONTROLLER}/byEnterpriseGuid/${branchGuid}`
  ),
  getStylingForBranchEndpoint: (branchGuid: string) => (
    `${ENTERPRISE_SERVICE}${ENTERPRISE_STYLING_CONTROLLER}/forEnterprise/${branchGuid}`
  ),
  getLogoByBranchGuid: (branchGuid: string) => (
    `${ENTERPRISE_SERVICE}${ENTERPRISE_STYLING_CONTROLLER}/logoByEnterpriseGuid/${branchGuid}`
  ),
  // splash screen controller
  splashScreen: `${ENTERPRISE_SERVICE}${ENTERPRISE_SPLASH_SCREEN_CONTROLLER}`,
  splashScreenUpdate: `${ENTERPRISE_SERVICE}${ENTERPRISE_SPLASH_SCREEN_CONTROLLER}/update`,
  splashScreenByBranchGuid: `${ENTERPRISE_SERVICE}${ENTERPRISE_SPLASH_SCREEN_CONTROLLER}/byEnterpriseGuid`,
  getSplashScreenEndpoint: (guid: string) => `${ENTERPRISE_SERVICE}${ENTERPRISE_SPLASH_SCREEN_CONTROLLER}/${guid}`,
  // enterprise rest
  branchSetting: `${ENTERPRISE_SERVICE}enterpriseSetting`,
  branchReference: `${ENTERPRISE_SERVICE}enterpriseReference`,
  branchReferenceList: `${ENTERPRISE_SERVICE}enterpriseReference/list`,
  branchImportMappers: `${ENTERPRISE_SERVICE}enterpriseSetting/getAllMappers`,
  branchMasterSetting: `${ENTERPRISE_SERVICE}enterpriseSetting/masterSettings`,
  branchNormalizedCurrency: `${ENTERPRISE_SERVICE}enterpriseSetting/normalizedCurrency`,
  branchOrParentBillToEndpoint: `${ENTERPRISE_SERVICE}enterprise/billTo/currentOrParent`,
  availableBranchImportMappers: `${ENTERPRISE_SERVICE}enterpriseSetting/availableMappers`,
  branchCarrierMobileEnabled: `${ENTERPRISE_SERVICE}enterpriseSetting/carrierMobileEnabled`,
  availableEdiExportMappers: `${ENTERPRISE_SERVICE}enterpriseSetting/availableEdiExportMappers`,
  getRemoveBranchRefEndpoint: (guid: string) => `${ENTERPRISE_SERVICE}enterpriseReference/${guid}`,
  availableCustomExcelExports: `${ENTERPRISE_SERVICE}enterpriseSetting/availableCustomExcelExports`,
  // MENU SERVICE
  menus: `${MENU_SERVICE}menu`,
  menuItem: `${MENU_SERVICE}menu/item`,
  translation: `${MENU_SERVICE}i18nDefaultItemManagement/all`,
  translationDelete: `${MENU_SERVICE}i18nDefaultItemManagement`,
  getMenuEndpoint: (lng: string) => `${MENU_SERVICE}menu/${lng}`,
  getLocaleEndpoint: (lng: string) => `${MENU_SERVICE}i18/${lng}`,
  translationByPattern: `${MENU_SERVICE}i18nDefaultItem/byPattern`,
  // i18nCustomizedItemController
  getAllByEnterpriseGuid: `${MENU_SERVICE}i18`,
  deleteI18nCustomizedItem: `${MENU_SERVICE}i18`,
  i18nCustomizedSaveAll: `${MENU_SERVICE}i18/all`,
  i18nCustomizedByPattern: `${MENU_SERVICE}i18/byPattern`,
  // TEMPLATES SERVICE
  templatesWebsocket: `${TEMPLATES_SERVICE}websocket`,
  // item controller
  item: `${TEMPLATES_SERVICE}${TEMPLATES_ITEM_CONTROLLER}`,
  listItems: `${TEMPLATES_SERVICE}${TEMPLATES_ITEM_CONTROLLER}/list`,
  listItemUp: `${TEMPLATES_SERVICE}${TEMPLATES_ITEM_CONTROLLER}/list/up`,
  listItemDown: `${TEMPLATES_SERVICE}${TEMPLATES_ITEM_CONTROLLER}/list/down`,
  itemUpExportReport: `${TEMPLATES_SERVICE}${TEMPLATES_ITEM_CONTROLLER}/list/up/export`,
  itemDownExportReport: `${TEMPLATES_SERVICE}${TEMPLATES_ITEM_CONTROLLER}/list/down/export`,
  getCurrentItemEndpoint: (guid: string) => `${TEMPLATES_SERVICE}${TEMPLATES_ITEM_CONTROLLER}/${guid}`,
  getActivateItemEndpoint: (guid: string) => `${TEMPLATES_SERVICE}${TEMPLATES_ITEM_CONTROLLER}/${guid}/activate`,
  getDeactivateItemEndpoint: (guid: string) => `${TEMPLATES_SERVICE}${TEMPLATES_ITEM_CONTROLLER}/${guid}/deactivate`,
  // item reference controller
  itemReference: `${TEMPLATES_SERVICE}${TEMPLATES_ITEM_REFERENCE_CONTROLLER}`,
  itemReferenceList: `${TEMPLATES_SERVICE}${TEMPLATES_ITEM_REFERENCE_CONTROLLER}/list`,
  getRemoveItemReferenceEndpoint: (guid: string) => (
    `${TEMPLATES_SERVICE}${TEMPLATES_ITEM_REFERENCE_CONTROLLER}/${guid}`
  ),
  // container template controller
  containerTemplate: `${TEMPLATES_SERVICE}${TEMPLATES_CONTAINER_TEMPLATE_CONTROLLER}`,
  listContainerTemplate: `${TEMPLATES_SERVICE}${TEMPLATES_CONTAINER_TEMPLATE_CONTROLLER}/list`,
  listContainerTemplateUp: `${TEMPLATES_SERVICE}${TEMPLATES_CONTAINER_TEMPLATE_CONTROLLER}/list/up`,
  listContainerTemplateDown: `${TEMPLATES_SERVICE}${TEMPLATES_CONTAINER_TEMPLATE_CONTROLLER}/list/down`,
  containerTemplateUpExportReport: `${TEMPLATES_SERVICE}${TEMPLATES_CONTAINER_TEMPLATE_CONTROLLER}/list/up/export`,
  containerTemplateDownExportReport: `${TEMPLATES_SERVICE}${TEMPLATES_CONTAINER_TEMPLATE_CONTROLLER}/list/down/export`,
  getContainerTemplateEndpoint: (guid: string) => `${TEMPLATES_SERVICE}${TEMPLATES_CONTAINER_TEMPLATE_CONTROLLER}/${guid}`,
  // location template controller
  location: `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_TEMPLATE_CONTROLLER}`,
  listLocations: `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_TEMPLATE_CONTROLLER}/list`,
  listLocationsUp: `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_TEMPLATE_CONTROLLER}/list/up`,
  listLocationsDown: `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_TEMPLATE_CONTROLLER}/list/down`,
  crossDockLocationList: `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_TEMPLATE_CONTROLLER}/list/crossdock`,
  locationUpExportReport: `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_TEMPLATE_CONTROLLER}/list/up/export`,
  locationDownExportReport: `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_TEMPLATE_CONTROLLER}/list/down/export`,
  locationListByBranchGuid: `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_TEMPLATE_CONTROLLER}/list/byEnterpriseGuid`,
  getCurrentLocationEndpoint: (guid: string) => `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_TEMPLATE_CONTROLLER}/${guid}`,
  getActivateLocationEndpoint: (guid: string) => (
    `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_TEMPLATE_CONTROLLER}/${guid}/activate`
  ),
  getDeactivateLocationEndpoint: (guid: string) => (
    `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_TEMPLATE_CONTROLLER}/${guid}/deactivate`
  ),
  locationReference: `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_REFERENCE_CONTROLLER}`,
  locationReferenceList: `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_REFERENCE_CONTROLLER}/list`,
  getRemoveLocationReferenceEndpoint: (guid: string) => (
    `${TEMPLATES_SERVICE}${TEMPLATES_LOCATION_REFERENCE_CONTROLLER}/${guid}`
  ),
  // contact book controller
  contactBook: `${TEMPLATES_SERVICE}${CONTACT_BOOK_CONTROLLER}`,
  contactBookList: `${TEMPLATES_SERVICE}${CONTACT_BOOK_CONTROLLER}/list`,
  contactBookListUp: `${TEMPLATES_SERVICE}${CONTACT_BOOK_CONTROLLER}/list/up`,
  contactBookListDown: `${TEMPLATES_SERVICE}${CONTACT_BOOK_CONTROLLER}/list/down`,
  getContact: (guid: string) => `${TEMPLATES_SERVICE}${CONTACT_BOOK_CONTROLLER}/${guid}`,
  contactBookListUpExport: `${TEMPLATES_SERVICE}${CONTACT_BOOK_CONTROLLER}/list/up/export`,
  contactBookListDownExport: `${TEMPLATES_SERVICE}${CONTACT_BOOK_CONTROLLER}/list/down/export`,
  // compensation template controller
  compensationTemplate: `${TEMPLATES_SERVICE}${TEMPLATES_COMPENSATION_TEMPLATE_CONTROLLER}`,
  compensationTemplateList: `${TEMPLATES_SERVICE}${TEMPLATES_COMPENSATION_TEMPLATE_CONTROLLER}/list`,
  compensationTemplateListUp: `${TEMPLATES_SERVICE}${TEMPLATES_COMPENSATION_TEMPLATE_CONTROLLER}/list/up`,
  compensationTemplateListDown: `${TEMPLATES_SERVICE}${TEMPLATES_COMPENSATION_TEMPLATE_CONTROLLER}/list/down`,
  compensationTemplateMassDelete: `${TEMPLATES_SERVICE}${TEMPLATES_COMPENSATION_TEMPLATE_CONTROLLER}/massDelete`,
  compensationTemplateListUpExport: `${TEMPLATES_SERVICE}${TEMPLATES_COMPENSATION_TEMPLATE_CONTROLLER}/list/up/export`,
  compensationTemplateListDownExport:
    `${TEMPLATES_SERVICE}${TEMPLATES_COMPENSATION_TEMPLATE_CONTROLLER}/list/down/export`,
  getCompensationTemplateEndpoint: (guid: string) =>
    `${TEMPLATES_SERVICE}${TEMPLATES_COMPENSATION_TEMPLATE_CONTROLLER}/${guid}`,
  // FLEET SERVICE
  fleetSocket: `${FLEET_SERVICE}websocket`,
  // fleet profile controller
  getFleetProfilePhoto: (type: string) => `${FLEET_SERVICE}${type}/profilePhotoUrl`,
  addFleetProfilePhoto: (type: string) => `${FLEET_SERVICE}${type}/addProfilePhoto`,
  removeFleetProfilePhoto: (type: string) => `${FLEET_SERVICE}${type}/removeProfilePhoto`,
  //
  getFleetDriver: (guid: string) => `${FLEET_SERVICE}fleet/driver/${guid}`,
  // fleet equipment service entity document controller
  fleetEquipmentServiceEntityDocument: (entityType: string) =>
    `${FLEET_SERVICE}${getFleetEquipmentServiceEntityDocumentControllerByEntityType(entityType)}`,
  updateFleetEquipmentServiceEntityDocument: (entityType: string) =>
    `${FLEET_SERVICE}${getFleetEquipmentServiceEntityDocumentControllerByEntityType(entityType)}/update`,
  fleetEquipmentServiceEntityDocumentList: (entityType: string) =>
    `${FLEET_SERVICE}${getFleetEquipmentServiceEntityDocumentControllerByEntityType(entityType)}/list`,
  fleetEquipmentServiceEntityDocumentDownloadFile: (entityType: string) =>
  `${FLEET_SERVICE}${getFleetEquipmentServiceEntityDocumentControllerByEntityType(entityType)}/downloadFile`,
  removeFleetEquipmentServiceEntityDocument: (guid: string, entityType: string) =>
    `${FLEET_SERVICE}${getFleetEquipmentServiceEntityDocumentControllerByEntityType(entityType)}/${guid}`,
  fleetEquipmentServiceEntityDocumentDownload: (guid: string, entityType: string) =>
    `${FLEET_SERVICE}${getFleetEquipmentServiceEntityDocumentControllerByEntityType(entityType)}/${guid}/download`,
  // advance payment controller
  advancePayment: `${FLEET_SERVICE}${FLEET_ADVANCE_PAYMENT_CONTROLLER}`,
  advancePaymentList: `${FLEET_SERVICE}${FLEET_ADVANCE_PAYMENT_CONTROLLER}/list`,
  advancePaymentChangeStatus: `${FLEET_SERVICE}${FLEET_ADVANCE_PAYMENT_CONTROLLER}/changeStatus`,
  advancePaymentListForPayroll: `${FLEET_SERVICE}${FLEET_ADVANCE_PAYMENT_CONTROLLER}/list/availableForPayroll`,
  // deduction settings
  deductionSettings: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/deductionSettings`,
  // advance payment config controller
  advancePaymentConfig: `${FLEET_SERVICE}${FLEET_ADVANCE_PAYMENT_CONFIG_CONTROLLER}`,
  advancePaymentConfigList: `${FLEET_SERVICE}${FLEET_ADVANCE_PAYMENT_CONFIG_CONTROLLER}/list`,
  advancePaymentConfigRestoreInherited: `${FLEET_SERVICE}${FLEET_ADVANCE_PAYMENT_CONFIG_CONTROLLER}/restoreInherited`,
  // fleet assignment controller
  fleetAssignmentMassCreate: `${FLEET_SERVICE}fleet/assignment/massCreate`,
  // fleet service issue controller
  fleetServiceIssueList: `${FLEET_SERVICE}${FLEET_SERVICE_ISSUE_CONTROLLER}/list`,
  fleetServiceIssueDocument: `${FLEET_SERVICE}${FLEET_SERVICE_ISSUE_CONTROLLER}/document`,
  fleetServiceIssueMassDelete: `${FLEET_SERVICE}${FLEET_SERVICE_ISSUE_CONTROLLER}/massDelete`,
  fleetServiceIssueListExport: `${FLEET_SERVICE}${FLEET_SERVICE_ISSUE_CONTROLLER}/list/export`,
  fleetServiceIssueDocumentList: `${FLEET_SERVICE}${FLEET_SERVICE_ISSUE_CONTROLLER}/document/list`,
  getFleetServiceIssueDocumentEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_SERVICE_ISSUE_CONTROLLER}/document/${guid}`,
  getFleetServiceIssueDocumentDownloadEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_SERVICE_ISSUE_CONTROLLER}/document/${guid}/download`,
  // fleet truck service issue controller
  truckServiceIssue: `${FLEET_SERVICE}${FLEET_TRUCK_SERVICE_ISSUE_CONTROLLER}`,
  truckServiceIssueList: `${FLEET_SERVICE}${FLEET_TRUCK_SERVICE_ISSUE_CONTROLLER}/list`,
  truckServiceIssueMassDelete: `${FLEET_SERVICE}${FLEET_TRUCK_SERVICE_ISSUE_CONTROLLER}/massDelete`,
  getTruckServiceIssueEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRUCK_SERVICE_ISSUE_CONTROLLER}/${guid}`,
  getTruckServiceIssueStatusEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TRUCK_SERVICE_ISSUE_CONTROLLER}/${guid}/status`,
  // fleet trailer service issue controller
  trailerServiceIssue: `${FLEET_SERVICE}${FLEET_TRAILER_SERVICE_ISSUE_CONTROLLER}`,
  trailerServiceIssueList: `${FLEET_SERVICE}${FLEET_TRAILER_SERVICE_ISSUE_CONTROLLER}/list`,
  trailerServiceIssueMassDelete: `${FLEET_SERVICE}${FLEET_TRAILER_SERVICE_ISSUE_CONTROLLER}/massDelete`,
  getTrailerServiceIssueEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRAILER_SERVICE_ISSUE_CONTROLLER}/${guid}`,
  getTrailerServiceIssueStatusEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TRAILER_SERVICE_ISSUE_CONTROLLER}/${guid}/status`,
  // fleet shared component controller
  fleetSharedComponent: `${FLEET_SERVICE}${FLEET_SHARED_COMPONENT_CONTROLLER}`,
  fleetSharedComponentList: `${FLEET_SERVICE}${FLEET_SHARED_COMPONENT_CONTROLLER}/list`,
  reassignFleetSharedComponent: `${FLEET_SERVICE}${FLEET_SHARED_COMPONENT_CONTROLLER}/reassign`,
  availableFleetSharedComponents: `${FLEET_SERVICE}${FLEET_SHARED_COMPONENT_CONTROLLER}/available`,
  getFleetSharedComponent: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_SHARED_COMPONENT_CONTROLLER}/${guid}`,
  // fleet equipment service controller
  fleetEquipmentServiceList: `${FLEET_SERVICE}${FLEET_EQUIPMENT_SERVICE_CONTROLLER}/list`,
  fleetEquipmentServiceListExport: `${FLEET_SERVICE}${FLEET_EQUIPMENT_SERVICE_CONTROLLER}/list/export`,
  // fleet equipment service note controller
  fleetEquipmentServiceNote: `${FLEET_SERVICE}${FLEET_EQUIPMENT_SERVICE_CONTROLLER}/note`,
  fleetEquipmentServiceNoteList: `${FLEET_SERVICE}${FLEET_EQUIPMENT_SERVICE_CONTROLLER}/note/list`,
  fleetEquipmentServiceNoteEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_EQUIPMENT_SERVICE_CONTROLLER}/note/${guid}`,
  // fleet vendor controller
  fleetVendor: `${FLEET_SERVICE}${FLEET_VENDOR_CONTROLLER}`,
  fleetVendorList: `${FLEET_SERVICE}${FLEET_VENDOR_CONTROLLER}/list`,
  fleetVendorListForTask: `${FLEET_SERVICE}${FLEET_VENDOR_CONTROLLER}/list/forTask`,
  getFleetVendorByGuid: (guid: string) => `${FLEET_SERVICE}${FLEET_VENDOR_CONTROLLER}/${guid}`,
  sendFleetVendorListToIntegration: `${FLEET_SERVICE}${FLEET_VENDOR_CONTROLLER}/sendToIntegration`,
  fleetVendorIntegrationInfoList: `${FLEET_SERVICE}${FLEET_VENDOR_CONTROLLER}/list/integrationInfo`,
  fleetVendorIntegrationAuditList: `${FLEET_SERVICE}${FLEET_VENDOR_CONTROLLER}/list/integrationAudit`,
  getFleetVendorEmailByGuid: (guid: string) => `${FLEET_SERVICE}${FLEET_VENDOR_CONTROLLER}/${guid}/email`,
  // fleet vendor assignment
  assignVendorToDriver: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/assignVendor`,
  assignVendor: (type: string, guid: string) => `${FLEET_SERVICE}fleet/${type}/${guid}/assignVendor`,
  unassignVendor: (type: string, guid: string) => `${FLEET_SERVICE}fleet/${type}/${guid}/unassignVendor`,
  getVendorAssignment: (type: string, guid: string) => `${FLEET_SERVICE}fleet/${type}/${guid}/fleetVendorInfo`,
  // fleet assignment controller
  changeVendorAssignment: `${FLEET_SERVICE}${FLEET_VENDOR_CONTROLLER}/fleetAssignment`,
  getVendorAssignments: `${FLEET_SERVICE}${FLEET_VENDOR_CONTROLLER}/fleetAssignment/list`,
  // fleet vendor pay to location controller
  vendorPayToLocation: `${FLEET_SERVICE}${FLEET_VENDOR_PAY_TO_LOCATION_CONTROLLER}`,
  removeVendorPayToLocation: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_VENDOR_PAY_TO_LOCATION_CONTROLLER}/${guid}`,
  // fleet vendor reference controller
  vendorReference: `${FLEET_SERVICE}${FLEET_VENDOR_REFERENCE_CONTROLLER}`,
  vendorReferenceList: `${FLEET_SERVICE}${FLEET_VENDOR_REFERENCE_CONTROLLER}/list`,
  removeVendorReference: (guid: string) => `${FLEET_SERVICE}${FLEET_VENDOR_REFERENCE_CONTROLLER}/${guid}`,
  // fleet vendor contact controller
  vendorContact: `${FLEET_SERVICE}${FLEET_VENDOR_CONTACT_CONTROLLER}`,
  vendorContactList: `${FLEET_SERVICE}${FLEET_VENDOR_CONTACT_CONTROLLER}/list`,
  removeVendorContact: (guid: string) => `${FLEET_SERVICE}${FLEET_VENDOR_CONTACT_CONTROLLER}/${guid}`,
  // fleet vendor insurance controller
  fleetVendorInsurance: `${FLEET_SERVICE}${FLEET_VENDOR_INSURANCE_CONTROLLER}`,
  fleetVendorInsuranceList: `${FLEET_SERVICE}${FLEET_VENDOR_INSURANCE_CONTROLLER}/list`,
  removeVendorInsurance: (guid: string) => `${FLEET_SERVICE}${FLEET_VENDOR_INSURANCE_CONTROLLER}/${guid}`,
  // fleet vendor payroll accessorial controller
  fleetVendorAccessorial: `${FLEET_SERVICE}${FLEET_VENDOR_ACCESSORIAL_CONTROLLER}`,
  fleetVendorAccessorialList: `${FLEET_SERVICE}${FLEET_VENDOR_ACCESSORIAL_CONTROLLER}/list`,
  getVendorAccessorialEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_VENDOR_ACCESSORIAL_CONTROLLER}/${guid}`,
  // fleet vendor payroll accessorial controller
  fleetVendorPayrollAccessorial: `${FLEET_SERVICE}${FLEET_VENDOR_PAYROLL_ACCESSORIAL_CONTROLLER}`,
  fleetVendorPayrollAccessorialList: `${FLEET_SERVICE}${FLEET_VENDOR_PAYROLL_ACCESSORIAL_CONTROLLER}/list`,
  getVendorPayrollAccessorialEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_VENDOR_PAYROLL_ACCESSORIAL_CONTROLLER}/${guid}`,
  // fleet vendor compensation controller
  fleetVendorCompensation: `${FLEET_SERVICE}${FLEET_VENDOR_COMPENSATION_CONTROLLER}`,
  fleetVendorCompensationList: `${FLEET_SERVICE}${FLEET_VENDOR_COMPENSATION_CONTROLLER}/list`,
  fleetVendorCompensationListByReport: `${FLEET_SERVICE}${FLEET_VENDOR_COMPENSATION_CONTROLLER}/listByReport`,
  getVendorCompensationEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_VENDOR_COMPENSATION_CONTROLLER}/${guid}`,
  // fleet vendor accessorial compensation controller
  fleetVendorCompensationAccessorial: `${FLEET_SERVICE}${FLEET_VENDOR_COMPENSATION_ACCESSORIAL_CONTROLLER}`,
  fleetVendorCompensationAccessorialList: `${FLEET_SERVICE}${FLEET_VENDOR_COMPENSATION_ACCESSORIAL_CONTROLLER}/list`,
  removeVendorCompensationAccessorial: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_VENDOR_COMPENSATION_ACCESSORIAL_CONTROLLER}/${guid}`,
  // fleet vendor accessorial compensation controller
  fleetVendorDocument: `${FLEET_SERVICE}${FLEET_VENDOR_DOCUMENT_CONTROLLER}`,
  fleetVendorDocumentList: `${FLEET_SERVICE}${FLEET_VENDOR_DOCUMENT_CONTROLLER}/list`,
  fleetVendorDocumentUpdate: `${FLEET_SERVICE}${FLEET_VENDOR_DOCUMENT_CONTROLLER}/update`,
  fleetVendorDocumentDownloadFile: `${FLEET_SERVICE}${FLEET_VENDOR_DOCUMENT_CONTROLLER}/downloadFile`,
  removeVendorDocument: (guid: string) => `${FLEET_SERVICE}${FLEET_VENDOR_DOCUMENT_CONTROLLER}/${guid}`,
  // fleet vendor monthly payroll deduction
  vendorMonthlyPayment: `${FLEET_SERVICE}${FLEET_VENDOR_MONTHLY_PAYMENT_CONTROLLER}`,
  vendorMonthlyPaymentList: `${FLEET_SERVICE}${FLEET_VENDOR_MONTHLY_PAYMENT_CONTROLLER}/list`,
  vendorMonthlyPaymentByDateRange: `${FLEET_SERVICE}${FLEET_VENDOR_MONTHLY_PAYMENT_CONTROLLER}/internal/byDateRange`,
  getVendorMonthlyPaymentEndpoint: (guid: string) => (
    `${FLEET_SERVICE}${FLEET_VENDOR_MONTHLY_PAYMENT_CONTROLLER}/${guid}`
  ),
  // fleet work order controller
  workOrderList: `${FLEET_SERVICE}${FLEET_WORK_ORDER_CONTROLLER}/list`,
  workOrderListExport: `${FLEET_SERVICE}${FLEET_WORK_ORDER_CONTROLLER}/list/export`,
  // fleet truck work order controller
  truckWordOrder: `${FLEET_SERVICE}${FLEET_TRUCK_WORK_ORDER_CONTROLLER}`,
  truckWordOrderList: `${FLEET_SERVICE}${FLEET_TRUCK_WORK_ORDER_CONTROLLER}/list`,
  getCurrentTruckWordOrderEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRUCK_WORK_ORDER_CONTROLLER}/${guid}`,
  getTruckWordOrderStatusEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TRUCK_WORK_ORDER_CONTROLLER}/${guid}/status`,
  // fleet truck work order invoice controller
  truckWordOrderInvoice: `${FLEET_SERVICE}${FLEET_TRUCK_WORK_ORDER_INVOICE_CONTROLLER}`,
  truckWordOrderInvoiceList: `${FLEET_SERVICE}${FLEET_TRUCK_WORK_ORDER_INVOICE_CONTROLLER}/list`,
  getTruckWorkOrderInvoiceEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TRUCK_WORK_ORDER_INVOICE_CONTROLLER}/${guid}`,
  // fleet trailer work order controller
  trailerWordOrder: `${FLEET_SERVICE}${FLEET_TRAILER_WORK_ORDER_CONTROLLER}`,
  trailerWordOrderList: `${FLEET_SERVICE}${FLEET_TRAILER_WORK_ORDER_CONTROLLER}/list`,
  getCurrentTrailerWordOrderEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TRAILER_WORK_ORDER_CONTROLLER}/${guid}`,
  getTrailerWordOrderStatusEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TRAILER_WORK_ORDER_CONTROLLER}/${guid}/status`,
  // fleet trailer work order invoice controller
  trailerWordOrderInvoice: `${FLEET_SERVICE}${FLEET_TRAILER_WORK_ORDER_INVOICE_CONTROLLER}`,
  trailerWordOrderInvoiceList: `${FLEET_SERVICE}${FLEET_TRAILER_WORK_ORDER_INVOICE_CONTROLLER}/list`,
  getTrailerWorkOrderInvoiceEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TRAILER_WORK_ORDER_INVOICE_CONTROLLER}/${guid}`,
  // fleet work order document controller
  workOrderDocument: `${FLEET_SERVICE}${FLEET_WORK_ORDER_DOCUMENT_CONTROLLER}`,
  workOrderDocumentList: `${FLEET_SERVICE}${FLEET_WORK_ORDER_DOCUMENT_CONTROLLER}/list`,
  getWorkOrderDocumentEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_WORK_ORDER_DOCUMENT_CONTROLLER}/${guid}`,
  getWorkOrderDocumentDownloadEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_WORK_ORDER_DOCUMENT_CONTROLLER}/${guid}/download`,
  // fleet work order note controller
  workOrderNote: `${FLEET_SERVICE}${FLEET_WORK_ORDER_NOTE_CONTROLLER}`,
  workOrderNoteList: `${FLEET_SERVICE}${FLEET_WORK_ORDER_NOTE_CONTROLLER}/list`,
  getWorkOrderNoteEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_WORK_ORDER_NOTE_CONTROLLER}/${guid}`,
  // fuel card controller
  fuelCard: `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}`,
  fuelCardList: `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}/list`,
  fuelCardMassDelete: `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}/massDelete`,
  uploadFuelCardDocument: `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}/upload`,
  fuelCardExportReport: `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}/list/export`,
  fuelCardByGuid: (guid: string) => `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}/${guid}`,
  fuelCardListForPayroll: `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}/list/availableForPayroll`,
  getFuelCardTransactionNumbersByGuids: `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}/guidTransactionNumber`,
  fuelCardListForVendorPayroll: `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}/list/availableForVendorPayroll`,
  fuelCardsForPayrollByVendor: `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}/list/availableForPayrollByVendor`,
  updateTruckFuelCard: (guid: string) => `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}/${guid}/updateTruckGuid`,
  updateDriverFuelCard: (guid: string) => `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}/${guid}/updateDriverGuid`,
  fuelCardLinesByFuelCardGuid: (guid: string) => `${FLEET_SERVICE}${FLEET_FUEL_CARD_CONTROLLER}/list/${guid}/lines`,
  // fleet driver controller
  fuelCardConfig: `${FLEET_SERVICE}fuelCard/config`,
  driver: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}`,
  driverDoc: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/document`,
  driverPrint: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/export`,
  listFleetDrivers: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/list`,
  driverHours: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/driverHours`,
  driverHideIssues: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/hideIssues`,
  driverDocList: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/document/list`,
  driverContact: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/contactDetails`,
  driversLNL: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/latestLocation/list`,
  listFleetDriversDown: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/list/down`,
  driverReference: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/driverReference`,
  driverDocUpdate: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/document/update`,
  driverCompensation: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/compensation`,
  driverCitizen: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/citizenshipDetails`,
  driverLicense: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/licenseDetails/update`,
  getAvailableDrivers: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/list/assignable`,
  driverAccessorial: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/driverAssessorial`,
  fleetDriverExportReport: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/list/export`,
  driverLatestLocation: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/latestLocation`,
  removeFuelCardConfig: (guid: string) => `${FLEET_SERVICE}fuelCard/config/${guid}`,
  assignTrailerEndpoint: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/assignTrailers`,
  payrollAssessorial: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/payrollAssessorial`,
  driverContactList: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/contactDetails/list`,
  listFleetDriversFullName: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/list/fullName`,
  driverDownloadDoc: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/document/downloadFile`,
  driverCertification: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/driverCertification`,
  driverCompensationList: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/compensation/list`,
  driverReferenceList: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/driverReference/list`,
  updateDriverCitizen: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/citizenshipDetails/update`,
  driverAccessorialsList: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/driverAssessorial/list`,
  payrollAssessorialList: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/payrollAssessorial/list`,
  driverCertificationList: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/driverCertification/list`,
  getCurrentDriverEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/${guid}`,
  listFleetDriversWithAssignment: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/list/withAssignment`,
  listFleetDriversFullNameInTree: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/list/fullNameInTree`,
  updateDriverCertification: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/driverCertification/update`,
  getDriverXMLEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/${guid}/xmlFile`,
  changeDriverAssignedToDivision: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/changeAssignedToDivision`,
  driverCompensationListByReport: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/compensation/listByReport`,
  assignTruckEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/${guid}/assignTruck`,
  driverCardsInfo: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/${guid}/driverCardsInfo`,
  driverWithLastLocationList: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/list/withLastLocation/fleetMap`,
  getFleetDriverEmailByGuid: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/${guid}/email`,
  unassignTruckEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/${guid}/unassignTruck`,
  getAssignInfoEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/${guid}/assignmentInfo`,
  getCurrentDocumentsEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/document/${guid}`,
  downloadDriverCertificationFile: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/driverCertification/downloadFile`,
  getCurrentContactEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/contactDetails/${guid}`,
  assignTeamDriverEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/${guid}/assignTeamDriver`,
  getDriverCompensationEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/compensation/${guid}`,
  unassignTrailerEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/${guid}/unassignAllTrailers`,
  getCurrentReferenceEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/driverReference/${guid}`,
  unassignTeamDriverEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/${guid}/unassignTeamDriver`,
  getPayrollAssessorialEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/payrollAssessorial/${guid}`,
  getDriverAccessorialEndpoint: (guid: string) => (
    `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/driverAssessorial/${guid}`
  ),
  getCurrentCertificateEndpoint: (guid: string) => (
    `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/driverCertification/${guid}`
  ),
  getAvailableDriversColorEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/${guid}/availableDriversColor`,
  driverLicenseEndpoint: (guid: string) => (
    `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/licenseDetails/getLicenseDetailsByDriver/${guid}`
  ),
  // fleet driver onboarding
  fleetDriverOnboardingReport: `${FLEET_SERVICE}driver/onboarding/report`,
  fleetDriverOnboardingReportList: `${FLEET_SERVICE}driver/onboarding/report/list`,
  getDriversByOnboardingGuid: (guid: string) => `${FLEET_SERVICE}driver/onboarding/report/${guid}/drivers`,
  // fleet driver daily log
  driverDailyLog: `${FLEET_SERVICE}${FLEET_DRIVER_DAILY_LOG_CONTROLLER}`,
  driverDailyLogList: `${FLEET_SERVICE}${FLEET_DRIVER_DAILY_LOG_CONTROLLER}/list`,
  driverDailyLogMassCreate: `${FLEET_SERVICE}${FLEET_DRIVER_DAILY_LOG_CONTROLLER}/massCreate`,
  driverDailyLogListExport: `${FLEET_SERVICE}${FLEET_DRIVER_DAILY_LOG_CONTROLLER}/list/export`,
  driverDailyLogListSummary: `${FLEET_SERVICE}${FLEET_DRIVER_DAILY_LOG_CONTROLLER}/list/summary`,
  driverDailyLogByGuid: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_DAILY_LOG_CONTROLLER}/${guid}`,
  driverDailyLogCreateFromTruckLog: `${FLEET_SERVICE}${FLEET_DRIVER_DAILY_LOG_CONTROLLER}/createFromTruckLog`,
  // fleet driver hos log
  driverHosLogList: `${FLEET_SERVICE}${FLEET_DRIVER_CONTROLLER}/hosLog/list`,
  // fleet driver monthly payroll deduction
  monthlyPayment: `${FLEET_SERVICE}${FLEET_DRIVER_MONTHLY_PAYMENT_CONTROLLER}`,
  monthlyPaymentList: `${FLEET_SERVICE}${FLEET_DRIVER_MONTHLY_PAYMENT_CONTROLLER}/list`,
  monthlyPaymentByDateRange: `${FLEET_SERVICE}${FLEET_DRIVER_MONTHLY_PAYMENT_CONTROLLER}/internal/byDateRange`,
  getMonthlyPaymentEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_MONTHLY_PAYMENT_CONTROLLER}/${guid}`,
  // driver payroll compensation
  payrollCompensation: `${FLEET_SERVICE}${DRIVER_PAYROLL_COMPENSATION_CONTROLLER}`,
  payrollCompensationByDateRange: `${FLEET_SERVICE}${DRIVER_PAYROLL_COMPENSATION_CONTROLLER}/internal/byDateRange`,
  getPayrollCompensationEndpoint: (guid: string) => `${FLEET_SERVICE}${DRIVER_PAYROLL_COMPENSATION_CONTROLLER}/${guid}`,
  // fleet driver safety violation
  safetyViolation: `${FLEET_SERVICE}${SAFETY_VIOLATION_CONTROLLER}`,
  safetyViolationList: `${FLEET_SERVICE}${SAFETY_VIOLATION_CONTROLLER}/list`,
  removeSafetyViolationByGuid: (guid: string) => `${FLEET_SERVICE}${SAFETY_VIOLATION_CONTROLLER}/${guid}`,
  // fleet driver safety violation note
  safetyViolationNote: `${FLEET_SERVICE}${SAFETY_VIOLATION_NOTE_CONTROLLER}`,
  safetyViolationNoteList: `${FLEET_SERVICE}${SAFETY_VIOLATION_NOTE_CONTROLLER}/list/bySafetyViolationGuid`,
  removeSafetyViolationNoteByGuid: (guid: string) => `${FLEET_SERVICE}${SAFETY_VIOLATION_NOTE_CONTROLLER}/${guid}`,
  // fleet driver safety violation document
  safetyViolationDocument: `${FLEET_SERVICE}${SAFETY_VIOLATION_DOCUMENT_CONTROLLER}`,
  safetyViolationDocumentList: `${FLEET_SERVICE}${SAFETY_VIOLATION_DOCUMENT_CONTROLLER}/list`,
  safetyViolationDocumentUpdate: `${FLEET_SERVICE}${SAFETY_VIOLATION_DOCUMENT_CONTROLLER}/update`,
  safetyViolationDocumentDownloadFile: `${FLEET_SERVICE}${SAFETY_VIOLATION_DOCUMENT_CONTROLLER}/downloadFile`,
  removeSafetyViolationDocumentByGuid: (guid: string) =>
    `${FLEET_SERVICE}${SAFETY_VIOLATION_DOCUMENT_CONTROLLER}/${guid}`,
  // toll charges
  tollChargesList: `${FLEET_SERVICE}${FLEET_TOLL_CHARGE_CONTROLLER}/list`,
  tollChargeAssign: `${FLEET_SERVICE}${FLEET_TOLL_CHARGE_CONTROLLER}/assign`,
  tollChargeExportReport: `${FLEET_SERVICE}${FLEET_TOLL_CHARGE_CONTROLLER}/list/export`,
  tollChargesListForPayroll: `${FLEET_SERVICE}${FLEET_TOLL_CHARGE_CONTROLLER}/list/availableForPayroll`,
  tollChargesForPayrollByVendor: `${FLEET_SERVICE}${FLEET_TOLL_CHARGE_CONTROLLER}/list/availableForPayrollByVendor`,
  tollChargesListForVendorPayroll: `${FLEET_SERVICE}${FLEET_TOLL_CHARGE_CONTROLLER}/list/availableForVendorPayroll`,
  updateTollChargeTruck: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TOLL_CHARGE_CONTROLLER}/${guid}/updateTruckGuid`,
  updateTollChargeDriver: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TOLL_CHARGE_CONTROLLER}/${guid}/updateDriverGuid`,
  // toll charge config
  tollChargeConfig: `${FLEET_SERVICE}${TOLL_CHARGE_CONFIG_CONTROLLER}`,
  tollChargeConfigList: `${FLEET_SERVICE}${TOLL_CHARGE_CONFIG_CONTROLLER}/list`,
  tollChargeConfigRestoreInherited: `${FLEET_SERVICE}${TOLL_CHARGE_CONFIG_CONTROLLER}/restoreInherited`,
  // eld integration config
  eldIntegrationConfig: `${FLEET_SERVICE}${ELD_INTEGRATION_CONFIG_CONTROLLER}`,
  eldIntegrationConfigList: `${FLEET_SERVICE}${ELD_INTEGRATION_CONFIG_CONTROLLER}/list`,
  eldIntegrationConfigRestoreInherited: `${FLEET_SERVICE}${ELD_INTEGRATION_CONFIG_CONTROLLER}/restoreInherited`,
  // driver onboarding integration config
  driverOnboardingIntegrationConfig: `${FLEET_SERVICE}${DRIVER_ONBOARDING_INTEGRATION_CONFIG}`,
  driverOnboardingIntegrationConfigList: `${FLEET_SERVICE}${DRIVER_ONBOARDING_INTEGRATION_CONFIG}/list`,
  driverOnboardingIntegrationConfigShowReportTab:
    `${FLEET_SERVICE}${DRIVER_ONBOARDING_INTEGRATION_CONFIG}/showReportTab`,
  removeDriverOnboardingIntegrationConfig: (guid: string) =>
    `${FLEET_SERVICE}${DRIVER_ONBOARDING_INTEGRATION_CONFIG}/${guid}`,
  // external driver
  fleetDriverExternal: `${FLEET_SERVICE}fleet/external/driver`,
  fleetDriverExternalFuelCards: `${FLEET_SERVICE}fleet/external/driver/fuelCards`,
  fleetDriverExternalByGuid: (guid: string) => `${FLEET_SERVICE}fleet/external/driver/${guid}`,
  fleetDriverExternalEnableByGuid: (guid: string) => `${FLEET_SERVICE}fleet/external/driver/enable/${guid}`,
  fleetDriverExternalDisableByGuid: (guid: string) => `${FLEET_SERVICE}fleet/external/driver/disable/${guid}`,
  // fleet driver availability controller
  availableDriverList: `${FLEET_SERVICE}${FLEET_DRIVER_AVAILABILITY_CONTROLLER}/list`,
  availableDriverNote: `${FLEET_SERVICE}${FLEET_DRIVER_AVAILABILITY_CONTROLLER}/note`,
  availableDriverReserve: `${FLEET_SERVICE}${FLEET_DRIVER_AVAILABILITY_CONTROLLER}/reserve`,
  driverAvailabilityStatus: `${FLEET_SERVICE}${FLEET_DRIVER_AVAILABILITY_CONTROLLER}/status`,
  availableDriverNoteList: `${FLEET_SERVICE}${FLEET_DRIVER_AVAILABILITY_CONTROLLER}/note/list`,
  driverAvailabilityAvailable: `${FLEET_SERVICE}${FLEET_DRIVER_AVAILABILITY_CONTROLLER}/available`,
  driverAvailabilityTotalCount: `${FLEET_SERVICE}${FLEET_DRIVER_AVAILABILITY_CONTROLLER}/totalCount`,
  driverAvailabilityAllDriversList: `${FLEET_SERVICE}${FLEET_DRIVER_AVAILABILITY_CONTROLLER}/showAll`,
  driverAvailabilityUnavailable: `${FLEET_SERVICE}${FLEET_DRIVER_AVAILABILITY_CONTROLLER}/unavailable`,
  availableDriverDeleteReservation: `${FLEET_SERVICE}${FLEET_DRIVER_AVAILABILITY_CONTROLLER}/reserve/delete`,
  getDriverAvailabilityEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_AVAILABILITY_CONTROLLER}/${guid}`,
  // fleet truck controller
  truck: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}`,
  truckDoc: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/document`,
  truckPrint: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/export`,
  listFleetTrucks: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/list`,
  syncTruck: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/enrichByVim`,
  truckLocations: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/location`,
  truckOwnership: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/ownership`,
  truckInsurance: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/insurance`,
  truckDocList: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/document/list`,
  truckHideIssues: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/hideIssues`,
  trucksLNL: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/latestLocation/list`,
  truckReference: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckReference`,
  truckComponent: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckComponent`,
  truckDocUpdate: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/document/update`,
  truckDailyLogList: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/dailyLog/list`,
  truckSpecification: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/specification`,
  truckInsuranceList: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/insurance/list`,
  getAvailableTrucks: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/list/assignable`,
  fleetTruckExportReport: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/list/export`,
  truckService: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckEquipmentService`,
  truckLatestLocation: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/latestLocation`,
  syncTruckByReport: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/enrichByVimByReport`,
  truckReferenceList: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckReference/list`,
  truckDownloadDoc: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/document/downloadFile`,
  truckComponentList: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckComponent/list`,
  syncSelectedTrucks: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/enrichByVimSelected`,
  truckChangeExternalId: (guid: string) => `${FLEET_SERVICE}fleet/external/truck/${guid}`,
  truckServiceList: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckEquipmentService/list`,
  truckDailyLogListExport: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/dailyLog/list/export`,
  truckDailyLogListSummary: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/dailyLog/list/summary`,
  getCurrentTruckEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/${guid}`,
  truckDailyLogListAvailable: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/dailyLog/list/available`,
  truckListChildrenAndCurrent: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/list/childrenAndCurrent`,
  getTruckXMLEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/${guid}/xmlFile`,
  changeTruckAssignedToDivision: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/changeAssignedToDivision`,
  truckWithLastLocationList: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/list/withLastLocation/fleetMap`,
  getCurrentTruckDocEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/document/${guid}`,
  getCurrentTruckLocationEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/location/${guid}`,
  getCurrentTruckRefEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckReference/${guid}`,
  getCurrentTruckInsuranceEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/insurance/${guid}`,
  availableForServiceTruckComponentList: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckComponent/availableForService`,
  getCurrentTruckComponentEndpoint: (guid: string) => (
    `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckComponent/${guid}`
  ),
  getCurrentTruckServiceEndpoint: (guid: string) => (
    `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckEquipmentService/${guid}`
  ),
  // fleet trailer equipment service document
  truckEquipmentServiceDocumentList:
    `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckEquipmentService/document/list`,
  getTruckEquipmentServiceDocumentEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckEquipmentService/document/${guid}`,
  truckEquipmentServiceDocumentDownloadEndpoint:
    `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckEquipmentService/document/downloadFile`,
  truckEquipmentServiceDocument: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckEquipmentService/document`,
  truckEquipmentServiceDocumentBatch: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/truckEquipmentService/document/batch`,
  // ifta
  iftaList: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/ifta/list`,
  exportIFTA: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/ifta/export`,
  generateIfta: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/ifta/generate`,
  removeIFTA: (guid: string) => `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/ifta/${guid}`,
  generateIftaByReport: `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/ifta/generateByReport`,
  getIFTAXml: (guid: string) => `${FLEET_SERVICE}${FLEET_TRUCK_CONTROLLER}/ifta/${guid}/xmlFile`,
  // external truck
  truckCreateExternalId: `${FLEET_SERVICE}fleet/external/truck`,
  // fleet trailer controller
  trailer: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}`,
  shareTrailer: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/share`,
  trailerCreateExternalId: `${FLEET_SERVICE}fleet/external/trailer`,
  trailerDoc: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/document`,
  trailerPrint: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/export`,
  listFleetTrailers: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/list`,
  syncTrailer: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/enrichByVim`,
  fleetExternalTrailerList: `${FLEET_SERVICE}fleet/external/trailer/list`,
  trailerLocations: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/location`,
  trailerOwnership: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/ownership`,
  trailerHideIssues: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/hideIssues`,
  trailerDocList: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/document/list`,
  trailersLNL: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/latestLocation/list`,
  trailerDocUpdate: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/document/update`,
  trailerReference: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerReference`,
  trailerInsurance: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerInsurance`,
  trailerComponent: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerComponent`,
  trailerCancelSharing: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/cancelSharing`,
  getAvailableTrailers: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/list/assignable`,
  fleetTrailerExportReport: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/list/export`,
  trailerLatestLocation: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/latestLocation`,
  trailerService: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerEquipmentService`,
  syncTrailerByReport: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/enrichByVimByReport`,
  syncSelectedTrailers: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/enrichByVimSelected`,
  trailerDownloadDoc: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/document/downloadFile`,
  trailerMilestoneLogList: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/milestoneLog/list`,
  listFleetTrailersInService: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/list/inService`,
  trailerSpecification: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerSpecification`,
  trailerReferenceList: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerReference/list`,
  trailerInsuranceList: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerInsurance/list`,
  trailerComponentList: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerComponent/list`,
  trailerChangeExternalId: (guid: string) => `${FLEET_SERVICE}fleet/external/trailer/${guid}`,
  trailerServiceList: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerEquipmentService/list`,
  trailerWithLastLocationList: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/list/withLastLocation`,
  getCurrentTrailerEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/${guid}`,
  trailerListChildrenAndCurrent: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/list/childrenAndCurrent`,
  trailerCurrentLocation: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/latestLocation/currentLocation`,
  getTrailerXMLEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/${guid}/xmlFile`,
  changeTrailerAssignedToDivision: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/changeAssignedToDivision`,
  getCurrentTrailerDocEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/document/${guid}`,
  trailerWithLastLocationListFleetMap: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/list/withLastLocation/fleetMap`,
  getCurrentTrailerLocationEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/location/${guid}`,
  availableForServiceTrailerComponentList:
    `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerComponent/availableForService`,
  getCurrentTrailerRefEndpoint: (guid: string) => (
    `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerReference/${guid}`
  ),
  getCurrentTrailerInsuranceEndpoint: (guid: string) => (
    `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerInsurance/${guid}`
  ),
  getCurrentTrailerComponentEndpoint: (guid: string) => (
    `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerComponent/${guid}`
  ),
  getCurrentTrailerServiceEndpoint: (guid: string) => (
    `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerEquipmentService/${guid}`
  ),
  // fleet trailer equipment service document
  trailerEquipmentServiceDocumentList:
    `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerEquipmentService/document/list`,
  getTrailerEquipmentServiceDocumentEndpoint: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerEquipmentService/document/${guid}`,
  trailerEquipmentServiceDocumentDownloadEndpoint:
    `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerEquipmentService/document/downloadFile`,
  trailerEquipmentServiceDocument: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerEquipmentService/document`,
  trailerEquipmentServiceDocumentBatch: `${FLEET_SERVICE}${FLEET_TRAILER_CONTROLLER}/trailerEquipmentService/document/batch`,
  // fleet inspections
  fleetInspections: `${FLEET_SERVICE}${FLEET_INSPECTION_CONTROLLER}/list`,
  fleetInspectionsDelete: `${FLEET_SERVICE}${FLEET_INSPECTION_CONTROLLER}/massDelete`,
  fleetInspectionSections: `${FLEET_SERVICE}${FLEET_INSPECTION_CONTROLLER}/section/list`,
  fleetInspectionsListExport: `${FLEET_SERVICE}${FLEET_INSPECTION_CONTROLLER}/list/export`,
  getFleetInspectionsDownloadSignature: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_INSPECTION_CONTROLLER}/${guid}/downloadSignature`,
  fleetInspectionSectionComponents: `${FLEET_SERVICE}${FLEET_INSPECTION_SECTION_COMPONENT_CONTROLLER}/list`,
  // fleet inspection document
  getFleetInspectionComponentDocument: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_INSPECTION_SECTION_COMPONENT_DOCUMENT_CONTROLLER}/${guid}/download`,
  // truck inspections
  truckInspections: `${FLEET_SERVICE}${FLEET_TRUCK_INSPECTION_CONTROLLER}/list`,
  truckInspectionsDelete: `${FLEET_SERVICE}${FLEET_TRUCK_INSPECTION_CONTROLLER}/massDelete`,
  getTruckInspectionEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRUCK_INSPECTION_CONTROLLER}/${guid}`,
  getTruckInspectionDetails: (guid: string) => `${FLEET_SERVICE}${FLEET_TRUCK_INSPECTION_CONTROLLER}/${guid}/details`,
  // trailer inspections
  trailerInspections: `${FLEET_SERVICE}${FLEET_TRAILER_INSPECTION_CONTROLLER}/list`,
  trailerInspectionsDelete: `${FLEET_SERVICE}${FLEET_TRAILER_INSPECTION_CONTROLLER}/massDelete`,
  getTrailerInspectionEndpoint: (guid: string) => `${FLEET_SERVICE}${FLEET_TRAILER_INSPECTION_CONTROLLER}/${guid}`,
  getTrailerInspectionDetails: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TRAILER_INSPECTION_CONTROLLER}/${guid}/details`,
  // fleet trailer controller
  fleetDispatchingGroup: `${FLEET_SERVICE}${FLEET_DISPATCHING_GROUP_CONTROLLER}`,
  fleetDispatchingGroupByBranchGuid: `${FLEET_SERVICE}${FLEET_DISPATCHING_GROUP_CONTROLLER}/list/byEnterpriseGuid`,
  // fleet trailer tracking integration controller
  fleetTrailerTrackingIntegration: `${FLEET_SERVICE}${FLEET_TRAILER_TRACKING_INTEGRATION_CONFIG_CONTROLLER}`,
  fleetTrailerTrackingIntegrationList: `${FLEET_SERVICE}${FLEET_TRAILER_TRACKING_INTEGRATION_CONFIG_CONTROLLER}/list`,
  removeFleetTrailerTrackingIntegration: (guid: string) =>
    `${FLEET_SERVICE}${FLEET_TRAILER_TRACKING_INTEGRATION_CONFIG_CONTROLLER}/${guid}`,
  // vin lookup config
  fleetVinLookupConfig: `${FLEET_SERVICE}vinLookupConfig`,
  // CONFIGURATION SERVICE
  // document sending config controller
  documentSendingConfig: `${CONFIGURATION_SERVICE}documentSendingConfig`,
  documentSendingConfigList: `${CONFIGURATION_SERVICE}documentSendingConfig/list`,
  documentSendingConfigRestoreInherited: `${CONFIGURATION_SERVICE}documentSendingConfig/restoreInherited`,
  // task type controller
  taskTypeConfig: `${CONFIGURATION_SERVICE}${TASK_TYPE_CONTROLLER}`,
  taskTypeConfigList: `${CONFIGURATION_SERVICE}${TASK_TYPE_CONTROLLER}/list`,
  taskTypeConfigRestoreInherited: `${CONFIGURATION_SERVICE}${TASK_TYPE_CONTROLLER}/restoreInherited`,
  // task status controller
  taskStatusConfig: `${CONFIGURATION_SERVICE}${TASK_STATUS_CONTROLLER}`,
  taskStatusConfigList: `${CONFIGURATION_SERVICE}${TASK_STATUS_CONTROLLER}/list`,
  taskStatusConfigRestoreInherited: `${CONFIGURATION_SERVICE}${TASK_STATUS_CONTROLLER}/restoreInherited`,
  groupedByTaskTypesStatusMap: `${CONFIGURATION_SERVICE}${TASK_STATUS_CONTROLLER}/groupedByTaskTypes/forMap`,
  // carrier scorecard config controller
  carrierScorecardConfig: `${CONFIGURATION_SERVICE}${CARRIER_SCORECARD_CONFIG_CONTROLLER}`,
  carrierScorecardConfigList: `${CONFIGURATION_SERVICE}${CARRIER_SCORECARD_CONFIG_CONTROLLER}/list`,
  carrierScorecardConfigRestoreInherited:
    `${CONFIGURATION_SERVICE}${CARRIER_SCORECARD_CONFIG_CONTROLLER}/restoreInherited`,
  // default order accessorial
  defaultOrderAccessorial: `${CONFIGURATION_SERVICE}defaultOrderAssessorial`,
  defaultOrderAccessorialList: `${CONFIGURATION_SERVICE}defaultOrderAssessorial/list`,
  // carrier terms and conditions document controller
  carrierTermsAndConditionsDocument: `${CONFIGURATION_SERVICE}${CARRIER_TERMS_AND_CONDITIONS_DOCUMENT_CONTROLLER}`,
  updateCarrierTermsAndConditionsDocument:
    `${CONFIGURATION_SERVICE}${CARRIER_TERMS_AND_CONDITIONS_DOCUMENT_CONTROLLER}/update`,
  // equipment mapping
  equipmentMapping: `${CONFIGURATION_SERVICE}equipment/mapping`,
  equipmentMappingList: `${CONFIGURATION_SERVICE}equipment/mapping/list`,
  equipmentMappingRestoreInherited: `${CONFIGURATION_SERVICE}equipment/mapping/restoreInherited`,
  // transportation mode mapping
  transportationModeMapping: `${CONFIGURATION_SERVICE}transportationMode/mapping`,
  transportationModeMappingList: `${CONFIGURATION_SERVICE}transportationMode/mapping/list`,
  transportationModeMappingRestoreInherited: `${CONFIGURATION_SERVICE}transportationMode/mapping/restoreInherited`,
  // transportation mode icon mapping
  transportationModeIconMapping: `${CONFIGURATION_SERVICE}transportationModeIconMapping`,
  transportationModeIconMappingList: `${CONFIGURATION_SERVICE}transportationModeIconMapping/list`,
  transportationModeIconMappingRestoreInherited:
    `${CONFIGURATION_SERVICE}transportationModeIconMapping/restoreInherited`,
  // customer terms and conditions document controller
  customerTermsAndConditionsDocument: `${CONFIGURATION_SERVICE}${CUSTOMER_TERMS_AND_CONDITIONS_DOCUMENT_CONTROLLER}`,
  downloadCustomerTermsAndConditionsDocument:
    `${CONFIGURATION_SERVICE}${CUSTOMER_TERMS_AND_CONDITIONS_DOCUMENT_CONTROLLER}/downloadFile`,
  updateCustomerTermsAndConditionsDocument:
    `${CONFIGURATION_SERVICE}${CUSTOMER_TERMS_AND_CONDITIONS_DOCUMENT_CONTROLLER}/update`,
  // user terms and conditions document controller
  userTermsAndConditionsDocument: `${CONFIGURATION_SERVICE}${USER_TERMS_AND_CONDITIONS_DOCUMENT_CONTROLLER}`,
  downloadUserTermsAndConditionsDocument:
    `${CONFIGURATION_SERVICE}${USER_TERMS_AND_CONDITIONS_DOCUMENT_CONTROLLER}/downloadFile`,
  existsUserTermsAndConditionsDocument:
    `${CONFIGURATION_SERVICE}${USER_TERMS_AND_CONDITIONS_DOCUMENT_CONTROLLER}/exists`,
  updateUserTermsAndConditionsDocument:
    `${CONFIGURATION_SERVICE}${USER_TERMS_AND_CONDITIONS_DOCUMENT_CONTROLLER}/update`,
  // document type mapping
  documentTypeMapping: `${CONFIGURATION_SERVICE}${CONFIG_DOCUMENT_TYPE_MAPPING_CONTROLLER}`,
  documentTypeMappingList: `${CONFIGURATION_SERVICE}${CONFIG_DOCUMENT_TYPE_MAPPING_CONTROLLER}/list`,
  restoreInheritedDocumentTypeMapping:
    `${CONFIGURATION_SERVICE}${CONFIG_DOCUMENT_TYPE_MAPPING_CONTROLLER}/restoreInherited`,
  // default carrier accessorial
  defaultCarrierAccessorial: `${CONFIGURATION_SERVICE}${CONFIG_DEFAULT_CARRIER_ACCESSORIAL_CONTROLLER}`,
  defaultCarrierAccessorialList: `${CONFIGURATION_SERVICE}${CONFIG_DEFAULT_CARRIER_ACCESSORIAL_CONTROLLER}/list`,
  defaultCarrierAccessorialRestoreInherited:
    `${CONFIGURATION_SERVICE}${CONFIG_DEFAULT_CARRIER_ACCESSORIAL_CONTROLLER}/restoreInherited`,
  // sequence controller
  sequence: `${CONFIGURATION_SERVICE}${CONFIG_SEQUENCE_CONTROLLER}`,
  listSequences: `${CONFIGURATION_SERVICE}${CONFIG_SEQUENCE_CONTROLLER}/list`,
  sequencesGuidNameMap: `${CONFIGURATION_SERVICE}${CONFIG_SEQUENCE_CONTROLLER}/guidNameMap`,
  sequenceTransformersList: `${CONFIGURATION_SERVICE}${CONFIG_SEQUENCE_CONTROLLER}/transformersList`,
  getSequenceEndpoint: (guid: string) => `${CONFIGURATION_SERVICE}${CONFIG_SEQUENCE_CONTROLLER}/${guid}`,
  getSequenceNextValEndpoint: (guid: string) => `${CONFIGURATION_SERVICE}${CONFIG_SEQUENCE_CONTROLLER}/${guid}/nextval`,
  // qb account mapping controller
  quickBooksAccountMapping: `${CONFIGURATION_SERVICE}${QB_ACCOUNT_MAPPING_CONTROLLER}`,
  quickBooksAccountMappingList: `${CONFIGURATION_SERVICE}${QB_ACCOUNT_MAPPING_CONTROLLER}/list`,
  quickBooksAccountMappingRestoreInherited: `${CONFIGURATION_SERVICE}${QB_ACCOUNT_MAPPING_CONTROLLER}/restoreInherited`,
  // qb custom field mapping controller
  quickBooksCustomFieldMapping: `${CONFIGURATION_SERVICE}${QB_CUSTOM_FIELD_MAPPING_CONTROLLER}`,
  quickBooksCustomFieldMappingList: `${CONFIGURATION_SERVICE}${QB_CUSTOM_FIELD_MAPPING_CONTROLLER}/list`,
  quickBooksCustomFieldMappingRestoreInherited:
    `${CONFIGURATION_SERVICE}${QB_CUSTOM_FIELD_MAPPING_CONTROLLER}/restoreInherited`,
  // qb iif asseccorial mapping controller
  quickBooksIffAssessorialMapping: `${CONFIGURATION_SERVICE}${QB_IIF_ASSESSORIAL_MAPPING_CONTROLLER}`,
  quickBooksIffAssessorialMappingList: `${CONFIGURATION_SERVICE}${QB_IIF_ASSESSORIAL_MAPPING_CONTROLLER}/list`,
  quickBooksIffAssessorialMappingRestoreInherited:
    `${CONFIGURATION_SERVICE}${QB_IIF_ASSESSORIAL_MAPPING_CONTROLLER}/restoreInherited`,
  // qb account mapping controller
  glCodeMapping: `${CONFIGURATION_SERVICE}${GL_CODE_MAPPING_CONTROLLER}`,
  glCodeMappingList: `${CONFIGURATION_SERVICE}${GL_CODE_MAPPING_CONTROLLER}/list`,
  glCodeRestoreInherited: `${CONFIGURATION_SERVICE}${GL_CODE_MAPPING_CONTROLLER}/restoreInherited`,
  // qb account mapping controller
  defaultInvoiceStatusRule: `${CONFIGURATION_SERVICE}${CONFIG_DEFAULT_INVOICE_STATUS_RULE_CONTROLLER}`,
  defaultInvoiceStatusRuleList: `${CONFIGURATION_SERVICE}${CONFIG_DEFAULT_INVOICE_STATUS_RULE_CONTROLLER}/list`,
  restoreInheritedDefaultInvoiceStatusRule:
    `${CONFIGURATION_SERVICE}${CONFIG_DEFAULT_INVOICE_STATUS_RULE_CONTROLLER}/restoreInherited`,
  // sage account mapping controller
  sageAccountMapping: `${CONFIGURATION_SERVICE}${SAGE_ACCOUNT_MAPPING_CONTROLLER}`,
  sageAccountMappingList: `${CONFIGURATION_SERVICE}${SAGE_ACCOUNT_MAPPING_CONTROLLER}/list`,
  sageAccountMappingRestoreInherited: `${CONFIGURATION_SERVICE}${SAGE_ACCOUNT_MAPPING_CONTROLLER}/restoreInherited`,
  // customer id mapping
  customerIdMapping: `${CONFIGURATION_SERVICE}${CUSTOMER_ID_MAPPING_CONTROLLER}`,
  customerIdMappingList: `${CONFIGURATION_SERVICE}${CUSTOMER_ID_MAPPING_CONTROLLER}/list`,
  customerIdMappingRestoreInherited: `${CONFIGURATION_SERVICE}${CUSTOMER_ID_MAPPING_CONTROLLER}/restoreInherited`,
  // department id mapping
  departmentIdMapping: `${CONFIGURATION_SERVICE}${DEPARTMENT_ID_MAPPING_CONTROLLER}`,
  departmentIdMappingList: `${CONFIGURATION_SERVICE}${DEPARTMENT_ID_MAPPING_CONTROLLER}/list`,
  departmentIdMappingListRestoreInherited:
    `${CONFIGURATION_SERVICE}${DEPARTMENT_ID_MAPPING_CONTROLLER}/restoreInherited`,
  // margin violation rule
  marginViolationRule: `${CONFIGURATION_SERVICE}${MARGIN_VIOLATION_RULE_CONTROLLER}`,
  marginViolationRuleList: `${CONFIGURATION_SERVICE}${MARGIN_VIOLATION_RULE_CONTROLLER}/list`,
  marginViolationRuleListRestoreInherited:
  `${CONFIGURATION_SERVICE}${MARGIN_VIOLATION_RULE_CONTROLLER}/restoreInherited`,
  marginViolationRuleByTransportationMode:
    `${CONFIGURATION_SERVICE}${MARGIN_VIOLATION_RULE_CONTROLLER}/getOneByTransportationMode`,
  // status code controller
  statusCode: `${CONFIGURATION_SERVICE}${CONFIG_STATUS_CODE_CONTROLLER}`,
  statusCodeList: `${CONFIGURATION_SERVICE}${CONFIG_STATUS_CODE_CONTROLLER}/list`,
  statusMessagesEndpoint: `${CONFIGURATION_SERVICE}${CONFIG_STATUS_CODE_CONTROLLER}`,
  statusMessagesListEndpoint: `${CONFIGURATION_SERVICE}${CONFIG_STATUS_CODE_CONTROLLER}/list`,
  statusCodesGuidNameMap: `${CONFIGURATION_SERVICE}${CONFIG_STATUS_CODE_CONTROLLER}/restoreInherited`,
  getStatusCodeEndpoint: (guid: string) => `${CONFIGURATION_SERVICE}${CONFIG_STATUS_CODE_CONTROLLER}/${guid}`,
  restoreInheritedStatusMessagesEndpoint: `${CONFIGURATION_SERVICE}${CONFIG_STATUS_CODE_CONTROLLER}/restoreInherited`,
  getDeleteStatusMessageEndpoint: (guid: string) => `${CONFIGURATION_SERVICE}${CONFIG_STATUS_CODE_CONTROLLER}/${guid}`,
  getReturnInheritedStatusMessagesEndpoint:
    (guid: string) => `${CONFIGURATION_SERVICE}${CONFIG_STATUS_CODE_CONTROLLER}/resetByEnterpriseGuid/${guid}`,
  // status code mapping controller
  statusCodeMapping: (type: string = 'inboundMapping') => `${CONFIGURATION_SERVICE}statusCode/${type}`,
  statusCodeMappingList: (type: string = 'inboundMapping') => `${CONFIGURATION_SERVICE}statusCode/${type}/list`,
  statusCodeMappingRestoreInherited: (type: string) =>
    `${CONFIGURATION_SERVICE}statusCode/${type}/restoreInherited`,
  // status reason code mapping controller
  statusReasonCodeMapping: (type: string = 'inboundMapping') => `${CONFIGURATION_SERVICE}statusReasonCode/${type}`,
  statusReasonCodeMappingList: (type: string = 'inboundMapping') =>
    `${CONFIGURATION_SERVICE}statusReasonCode/${type}/list`,
  statusReasonCodeMappingRestoreInherited: (type: string) =>
    `${CONFIGURATION_SERVICE}statusReasonCode/${type}/restoreInherited`,
  // service type mapping controller
  serviceTypeMapping: `${CONFIGURATION_SERVICE}${CONFIG_SERVICE_TYPE_MAPPING_CONTROLLER}`,
  serviceTypeMappingList: `${CONFIGURATION_SERVICE}${CONFIG_SERVICE_TYPE_MAPPING_CONTROLLER}/list`,
  serviceTypeMappingRestoreInherited:
    `${CONFIGURATION_SERVICE}${CONFIG_SERVICE_TYPE_MAPPING_CONTROLLER}/restoreInherited`,
  // configs controller
  branchConfigsEndpoint: `${CONFIGURATION_SERVICE}${CONFIGS_CONTROLLER}`,
  branchConfigsByNames: `${CONFIGURATION_SERVICE}${CONFIGS_CONTROLLER}/byNames`,
  getDropDownsByNames: `${CONFIGURATION_SERVICE}${CONFIGS_CONTROLLER}/dropdowns`,
  configsByBranches: `${CONFIGURATION_SERVICE}${CONFIGS_CONTROLLER}/configsByEnterprises`,
  availableDocumentTypes: `${CONFIGURATION_SERVICE}${CONFIGS_CONTROLLER}/availableDocumentTypes`,
  restoreInheritedDropdownEndpoint: `${CONFIGURATION_SERVICE}${CONFIGS_CONTROLLER}/restoreInheritedDropdown`,
  // notification controller
  createNotificationEndpoind: `${CONFIGURATION_SERVICE}${CONFIG_NOTIFICATION_CONTROLLER}`,
  notificationListTriggers: `${CONFIGURATION_SERVICE}${CONFIG_NOTIFICATION_CONTROLLER}/list`,
  conditionTypeEndpoint: `${CONFIGURATION_SERVICE}${CONFIG_NOTIFICATION_CONTROLLER}/conditionValues`,
  notificationTriggersEndpoint: `${CONFIGURATION_SERVICE}${CONFIG_NOTIFICATION_CONTROLLER}/triggers`,
  notificationsRestoreInherited: `${CONFIGURATION_SERVICE}${CONFIG_NOTIFICATION_CONTROLLER}/restoreInherited`,
  getNotificationConfigEndpoint: (guid: string) => `${CONFIGURATION_SERVICE}${CONFIG_NOTIFICATION_CONTROLLER}/${guid}`,
  // accessorial
  accessorialsEndpoint: `${CONFIGURATION_SERVICE}${CONFIG_ASSESSORIAL_CONTROLLER}`,
  accessorialsListEndpoint: `${CONFIGURATION_SERVICE}${CONFIG_ASSESSORIAL_CONTROLLER}/list`,
  restoreInheritedAccessorialsEndpoint: `${CONFIGURATION_SERVICE}${CONFIG_ASSESSORIAL_CONTROLLER}/restoreInherited`,
  getDeleteAccessorialEndpoint: (guid: string) => `${CONFIGURATION_SERVICE}${CONFIG_ASSESSORIAL_CONTROLLER}/${guid}`,
  getReturnInheritedAccessorialEndpoint:
    (guid: string) => `${CONFIGURATION_SERVICE}${CONFIG_ASSESSORIAL_CONTROLLER}/resetByEnterpriseGuid/${guid}`,
  // shared assessorial
  sharedAccessorial: `${CONFIGURATION_SERVICE}${CONFIG_SHARED_ACCESSORIAL_CONTROLLER}`,
  importIndex: `${CONFIGURATION_SERVICE}${CONFIG_SHARED_ACCESSORIAL_CONTROLLER}/importIndex`,
  sharedAccessorialList: `${CONFIGURATION_SERVICE}${CONFIG_SHARED_ACCESSORIAL_CONTROLLER}/list`,
  sharedAccessorialGuidNameMap: `${CONFIGURATION_SERVICE}${CONFIG_SHARED_ACCESSORIAL_CONTROLLER}/guidNameMap`,
  sharedAccessorialListApplyTo: `${CONFIGURATION_SERVICE}${CONFIG_SHARED_ACCESSORIAL_CONTROLLER}/list/applyTo`,
  downloadIndexTemplate: `${CONFIGURATION_SERVICE}${CONFIG_SHARED_ACCESSORIAL_CONTROLLER}/importIndex/templateFile`,
  restoreInheritedSharedAccessorial: `${CONFIGURATION_SERVICE}${CONFIG_SHARED_ACCESSORIAL_CONTROLLER}/restoreInherited`,
  getSharedAccessorialByGuid: (guid: string) =>
    `${CONFIGURATION_SERVICE}${CONFIG_SHARED_ACCESSORIAL_CONTROLLER}/${guid}`,
  // customer invoice status controller
  customerInvoiceStatus: `${CONFIGURATION_SERVICE}${CONFIG_CI_STATUS_CONTROLLER}`,
  customerInvoiceStatuses: `${CONFIGURATION_SERVICE}${CONFIG_CI_STATUS_CONTROLLER}/list`,
  restoreCustomerInvoiceStatuses: `${CONFIGURATION_SERVICE}${CONFIG_CI_STATUS_CONTROLLER}/restoreInherited`,
  getDeleteCustomerInvoiceStatus: (guid: string) => `${CONFIGURATION_SERVICE}${CONFIG_CI_STATUS_CONTROLLER}/${guid}`,
  // driver invoice status controller
  driverPayrollStatus: `${CONFIGURATION_SERVICE}${CONFIG_DI_STATUS_CONTROLLER}`,
  driverPayrollStatuses: `${CONFIGURATION_SERVICE}${CONFIG_DI_STATUS_CONTROLLER}/list`,
  restoreDriverPayrollStatuses: `${CONFIGURATION_SERVICE}${CONFIG_DI_STATUS_CONTROLLER}/restoreInherited`,
  getDeleteDriverPayrollStatus: (guid: string) => `${CONFIGURATION_SERVICE}${CONFIG_DI_STATUS_CONTROLLER}/${guid}`,
  // carrier invoice status controller
  carrierInvoiceStatus: `${CONFIGURATION_SERVICE}${CONFIG_CARRIER_INVOICE_STATUS_CONTROLLER}`,
  carrierInvoiceStatuses: `${CONFIGURATION_SERVICE}${CONFIG_CARRIER_INVOICE_STATUS_CONTROLLER}/list`,
  restoreCarrierInvoiceStatuses: `${CONFIGURATION_SERVICE}${CONFIG_CARRIER_INVOICE_STATUS_CONTROLLER}/restoreInherited`,
  getDeleteCarrierInvoiceStatus: (guid: string) => (
    `${CONFIGURATION_SERVICE}${CONFIG_CARRIER_INVOICE_STATUS_CONTROLLER}/${guid}`
  ),
  // service invoice status controller
  fleetServiceInvoiceStatus: `${CONFIGURATION_SERVICE}${CONFIG_FLEET_SERVICE_INVOICE_STATUS_CONTROLLER}`,
  fleetServiceInvoiceStatuses: `${CONFIGURATION_SERVICE}${CONFIG_FLEET_SERVICE_INVOICE_STATUS_CONTROLLER}/list`,
  restoreFleetServiceInvoiceStatuses:
    `${CONFIGURATION_SERVICE}${CONFIG_FLEET_SERVICE_INVOICE_STATUS_CONTROLLER}/restoreInherited`,
  getDeleteFleetServiceInvoiceStatus: (guid: string) => (
    `${CONFIGURATION_SERVICE}${CONFIG_FLEET_SERVICE_INVOICE_STATUS_CONTROLLER}/${guid}`
  ),
  // carrier deactivation rule controller
  carrierDeactivationRule: `${CONFIGURATION_SERVICE}${CONFIG_CARRIER_DEACTIVATION_RULE_CONTROLLER}`,
  carrierDeactivationRuleList: `${CONFIGURATION_SERVICE}${CONFIG_CARRIER_DEACTIVATION_RULE_CONTROLLER}/list`,
  getCarrierDeactivationRule:
    (guid: string) => `${CONFIGURATION_SERVICE}${CONFIG_CARRIER_DEACTIVATION_RULE_CONTROLLER}/${guid}`,
  carrierDeactivationRuleRestoreInherited:
    `${CONFIGURATION_SERVICE}${CONFIG_CARRIER_DEACTIVATION_RULE_CONTROLLER}/restoreInherited`,
  // user monitoring config
  userMonitoringConfig: `${CONFIGURATION_SERVICE}${USER_MONITORING_CONFIG}`,
  userMonitoringConfigList: `${CONFIGURATION_SERVICE}${USER_MONITORING_CONFIG}/list`,
  userMonitoringConfigListForUse: `${CONFIGURATION_SERVICE}${USER_MONITORING_CONFIG}/list/forUse`,
  userMonitoringConfigRestoreInherited: `${CONFIGURATION_SERVICE}${USER_MONITORING_CONFIG}/restoreInherited`,
  // CLO DISPATCH BOARD ACTION PANEL
  cloDispatchBoardActionPanel: `${CONFIGURATION_SERVICE}${CONFIG_CLO_DISPATCH_BOARD_ACTION_PANEL_CONTROLLER}`,
  getCloDispatchBoardActionList: `${CONFIGURATION_SERVICE}${CONFIG_CLO_DISPATCH_BOARD_ACTION_PANEL_CONTROLLER}/list`,
  getCloDispatchBoardActionEndpoint: (guid: string) => (
    `${CONFIGURATION_SERVICE}${CONFIG_CLO_DISPATCH_BOARD_ACTION_PANEL_CONTROLLER}/${guid}`
  ),
  // TEL DISPATCH BOARD ACTION PANEL
  telDispatchBoardActionPanel: `${CONFIGURATION_SERVICE}${CONFIG_TEL_DISPATCH_BOARD_ACTION_PANEL_CONTROLLER}`,
  getTelDispatchBoardActionList: `${CONFIGURATION_SERVICE}${CONFIG_TEL_DISPATCH_BOARD_ACTION_PANEL_CONTROLLER}/list`,
  getTelDispatchBoardActionEndpoint: (guid: string) => (
    `${CONFIGURATION_SERVICE}${CONFIG_TEL_DISPATCH_BOARD_ACTION_PANEL_CONTROLLER}/${guid}`
  ),
  // transportation mode grouping controller
  transportationModeGrouping: `${CONFIGURATION_SERVICE}transportationModeGrouping`,
  transportationModeGroupingList: `${CONFIGURATION_SERVICE}transportationModeGrouping/list`,
  transportationModeGroupingRestoreInherited: `${CONFIGURATION_SERVICE}transportationModeGrouping/restoreInherited`,
  // cross border integration config
  crossBorderIntegrationConfig: `${LOAD_SERVICE}${CROSS_BORDER_INTEGRATION_CONFIG_CONTROLLER}`,
  crossBorderIntegrationConfigList: `${LOAD_SERVICE}${CROSS_BORDER_INTEGRATION_CONFIG_CONTROLLER}/list`,
  crossBorderIntegrationConfigIsAnyEnabled: `${LOAD_SERVICE}${CROSS_BORDER_INTEGRATION_CONFIG_CONTROLLER}/isAnyEnabled`,
  removeCrossBorderIntegrationConfig: (guid: string) =>
    `${LOAD_SERVICE}${CROSS_BORDER_INTEGRATION_CONFIG_CONTROLLER}/${guid}`,
  // truck type mapping controller
  truckTypeMapping: `${CONFIGURATION_SERVICE}${CONFIG_TRUCK_TYPE_MAPPING_CONTROLLER}`,
  truckTypeMappingList: `${CONFIGURATION_SERVICE}${CONFIG_TRUCK_TYPE_MAPPING_CONTROLLER}/list`,
  truckTypeMappingRestoreInherited: `${CONFIGURATION_SERVICE}${CONFIG_TRUCK_TYPE_MAPPING_CONTROLLER}/restoreInherited`,
  // trailer type mapping controller
  trailerTypeMapping: `${CONFIGURATION_SERVICE}${CONFIG_TRAILER_TYPE_MAPPING_CONTROLLER}`,
  trailerTypeMappingList: `${CONFIGURATION_SERVICE}${CONFIG_TRAILER_TYPE_MAPPING_CONTROLLER}/list`,
  trailerTypeMappingRestoreInherited: `${CONFIGURATION_SERVICE}${CONFIG_TRAILER_TYPE_MAPPING_CONTROLLER}/restoreInherited`,
  // EXTERNAL QUOTES
  quoteDelete: `${CUSTOMER_SERVICE}quoteRequest`,
  quoteList: `${CUSTOMER_SERVICE}quoteRequest/list`,
  quoteAccept: (guid: string) => `${CUSTOMER_SERVICE}quoteRequest/${guid}/accept`,
  quoteDecline: (guid: string) => `${CUSTOMER_SERVICE}quoteRequest/${guid}/decline`,
  getQuoteRequestByGuid: (guid: string) => `${CUSTOMER_SERVICE}quoteRequest/${guid}`,
  // excel mass rating controller
  excelMassRatingResultList: `${LOAD_SERVICE}excelMassRating/result/list`,
  excelMassRatingProcessFile: `${LOAD_SERVICE}excelMassRating/processFile`,
  excelMassRatingSummaryList: `${LOAD_SERVICE}excelMassRating/summary/list`,
  excelMassRatingTemplateFile: `${LOAD_SERVICE}excelMassRating/templateFile`,
  excelMassRatingMassDelete: `${LOAD_SERVICE}excelMassRating/summary/massDelete`,
  excelMassRatingResultPriceList: `${LOAD_SERVICE}excelMassRating/result/price/list`,
  excelMassRatingResultListExport: `${LOAD_SERVICE}excelMassRating/result/list/export`,
  excelMassRatingResultUpdatePrices: `${LOAD_SERVICE}excelMassRating/result/updatePrices`,
  excelMassRatingResultUpdateAllPrices: `${LOAD_SERVICE}excelMassRating/summary/updateAllResults`,
  removeExcelMassRatingSummary: (guid: string) => `${LOAD_SERVICE}excelMassRating/summary/${guid}`,
  // factoring integration config controller
  factoringIntegrationConfig: `${LOAD_SERVICE}${FACTORING_INTEGRATION_CONFIG_CONTROLLER}`,
  factoringIntegrationConfigList: `${LOAD_SERVICE}${FACTORING_INTEGRATION_CONFIG_CONTROLLER}/list`,
  getActiveFactoringIntegration: `${LOAD_SERVICE}${FACTORING_INTEGRATION_CONFIG_CONTROLLER}/active`,
  removeFactoringIntegrationConfig: (guid: string) =>
    `${LOAD_SERVICE}${FACTORING_INTEGRATION_CONFIG_CONTROLLER}/${guid}`,
  factoringIntegrationConfigRestoreInherited:
    `${LOAD_SERVICE}${FACTORING_INTEGRATION_CONFIG_CONTROLLER}/restoreInherited`,
  // ORDER QUOTE
  orderQuoteExport: `${LOAD_SERVICE}${ROUTE_ORDER_QUOTE_CONTROLLER}/export`,
  getOrderQuoteXmlEndpoint: (guid: string) => `${LOAD_SERVICE}${ROUTE_ORDER_QUOTE_CONTROLLER}/${guid}/xmlFile`,
  orderQuote: `${LOAD_SERVICE}${ROUTE_ORDER_QUOTE_CONTROLLER}`,
  orderQuoteList: `${LOAD_SERVICE}${ROUTE_ORDER_QUOTE_CONTROLLER}/list`,
  orderQuoteSendToCustomer: `${LOAD_SERVICE}${ROUTE_ORDER_QUOTE_CONTROLLER}/sendToCustomer`,
  orderQuoteGetByGuid: (guid: string) => `${LOAD_SERVICE}${ROUTE_ORDER_QUOTE_CONTROLLER}/${guid}`,
  orderQuoteCancel: (guid: string) => `${LOAD_SERVICE}${ROUTE_ORDER_QUOTE_CONTROLLER}/${guid}/cancel`,
  orderQuoteAccept: (guid: string) => `${LOAD_SERVICE}${ROUTE_ORDER_QUOTE_CONTROLLER}/${guid}/accept`,
  orderQuoteDecline: (guid: string) => `${LOAD_SERVICE}${ROUTE_ORDER_QUOTE_CONTROLLER}/${guid}/decline`,
  getOrderQuoteByToken: (token: string) => `${LOAD_SERVICE}${ROUTE_ORDER_QUOTE_CONTROLLER}/${token}/byToken`,
  orderQuoteAcceptFromMail: (token: string) => `${LOAD_SERVICE}${ROUTE_ORDER_QUOTE_CONTROLLER}/${token}/mailAccept`,
  orderQuoteDeclineFromMail: (token: string) => `${LOAD_SERVICE}${ROUTE_ORDER_QUOTE_CONTROLLER}/${token}/mailDecline`,
  // REFERENCE SERVICE
  referenceType: `${REFERENCE_SERVICE}referenceType`,
  referenceTypeScopes: `${REFERENCE_SERVICE}referenceType/scopes`,
  allReferenceTypesList: `${REFERENCE_SERVICE}referenceType/list/all`,
  ownedReferenceTypesList: `${REFERENCE_SERVICE}referenceType/list/owned`,
  referenceTypeChangeOwner: `${REFERENCE_SERVICE}referenceType/changeOwner`,
  referenceTypeGuidsNamesMap: `${REFERENCE_SERVICE}referenceType/guidNameMap`,
  allAvailableReferenceTypesList: `${REFERENCE_SERVICE}referenceType/list/allAvailable`,
  availableReferenceTypesForScope: `${REFERENCE_SERVICE}referenceType/list/availableForScope`,
  grantToEnterpricesReferenceTypes: `${REFERENCE_SERVICE}referenceType/grantAllToEnterprises`,
  getCurrentReferenceTypeEndpoint: (guid: string) => `${REFERENCE_SERVICE}referenceType/${guid}`,
  getAllReferenceTypesEndpoint: (branchGuid: string) => (
    `${REFERENCE_SERVICE}referenceType/list/${branchGuid}/all`
  ),
  getOwnedReferenceTypesEndpoint: (branchGuid: string) => (
    `${REFERENCE_SERVICE}referenceType/list/${branchGuid}/owned`
  ),
  getAvailableReferenceTypesEndpoint: (branchGuid: string) => (
    `${REFERENCE_SERVICE}referenceType/list/${branchGuid}/available`
  ),
  getUngrantToEnterpricesReferenceTypeEndpoint:
    (guid: string) => `${REFERENCE_SERVICE}referenceType/${guid}/grantToEnterprises`,
  // REPORT SERVICE
  report: `${REPORT_SERVICE}report`,
  pinReport: `${REPORT_SERVICE}report/pin`,
  listReports: `${REPORT_SERVICE}report/list`,
  unpinReport: `${REPORT_SERVICE}report/unpin`,
  reportPivot: `${REPORT_SERVICE}report/pivot`,
  changeOwnerReport: `${REPORT_SERVICE}report/changeOwner`,
  reportExportReport: `${REPORT_SERVICE}report/list/export`,
  changeDefaultReport: `${REPORT_SERVICE}report/setDefault`,
  changePinnedReports: `${REPORT_SERVICE}report/changePinned`,
  getCurrentReportEndpoint: (guid: string) => `${REPORT_SERVICE}report/${guid}`,
  // DOCUMENT TEMPLATE_SERVICE
  documentEndpoint: `${DOCUMENT_TEMPLATE_SERVICE}config/templates`,
  documentListEndpoint: `${DOCUMENT_TEMPLATE_SERVICE}config/templates/list`,
  dataSourceList: `${DOCUMENT_TEMPLATE_SERVICE}config/templates/dataSourceList`,
  documentRestoreInherited: `${DOCUMENT_TEMPLATE_SERVICE}config/templates/restoreInherited`,
  getDeleteDocumentEndpoint: (guid: string) => `${DOCUMENT_TEMPLATE_SERVICE}config/templates/${guid}`,
  availableDocumentTemplates: `${DOCUMENT_TEMPLATE_SERVICE}config/templates/availableDocumentTemplates`,
  downloadDocTemplateEndpoint: (guid: string) => (
    `${DOCUMENT_TEMPLATE_SERVICE}config/templates/${guid}/download`
  ),
  // ROUTE SERVICE
  routeSocket: `${LOAD_SERVICE}websocket`,
  // item
  routeItem: `${LOAD_SERVICE}${ROUTE_ITEM_CONTROLLER}`,
  routeItemList: `${LOAD_SERVICE}${ROUTE_ITEM_CONTROLLER}/list`,
  // container
  routeContainer: `${LOAD_SERVICE}container`,
  routeContainerByGuid: (guid: string) => `${LOAD_SERVICE}container/${guid}`,
  // email rate quote
  getEmailRateQuoteInfo: (token: string) => `${LOAD_SERVICE}emailRateQuote/${token}/info`,
  emailRateQuoteDecline: (token: string) => `${LOAD_SERVICE}emailRateQuote/${token}/decline`,
  emailRateQuoteAccept: (token: string) => `${LOAD_SERVICE}emailRateQuote/${token}/provideRate`,
  getEmailRateQuoteDeclineReasonCodes: (token: string) => `${LOAD_SERVICE}emailRateQuote/${token}/declineReasonCodes`,
  // damage report
  damageReport: `${LOAD_SERVICE}damageReport`,
  damageReportUpdate: `${LOAD_SERVICE}damageReport/update`,
  damageReportDownloadFile: `${LOAD_SERVICE}damageReport/downloadFile`,
  damageReportByGuid: (guid: string) => `${LOAD_SERVICE}damageReport/${guid}`,
  getDamageReportListByItemGuids: `${LOAD_SERVICE}damageReport/list/byItemGuids`,
  // dispatch integration config
  dispatchIntegrationConfig: `${LOAD_SERVICE}dispatchIntegrationConfig`,
  dispatchIntegrationList: `${LOAD_SERVICE}dispatchIntegrationConfig/list`,
  dispatchIntegrationRestoreInherited: `${LOAD_SERVICE}dispatchIntegrationConfig/restoreInherited`,
  // terminal integration config
  terminalIntegrationConfig: `${LOAD_SERVICE}terminalIntegrationConfig`,
  terminalIntegrationList: `${LOAD_SERVICE}terminalIntegrationConfig/list`,
  terminalIntegrationConfigByGuid: (guid: string) => `${LOAD_SERVICE}terminalIntegrationConfig/${guid}`,
  // tel rate request controller
  telRateRequestList: `${LOAD_SERVICE}telRateRequest/list`,
  // macro point
  macroPointInfo: `${LOAD_SERVICE}${TEL_CONTROLLER}/macroPoint/info`,
  sendToMacroPoint: `${LOAD_SERVICE}${TEL_CONTROLLER}/macroPoint/send`,
  macroPointStopOrder: `${LOAD_SERVICE}${TEL_CONTROLLER}/macroPoint/stopOrder`,
  // fast load
  markAsFastLoadByLoadType: (guid: string, loadType: string) => `${LOAD_SERVICE}${loadType}/${guid}/markAsFast`,
  removeFastMarkFromLoadByLoadType:
    (guid: string, loadType: string) => `${LOAD_SERVICE}${loadType}/${guid}/removeFastMark`,
  // hot load
  markAsHotLoadByLoadType: (guid: string, loadType: string) => `${LOAD_SERVICE}${loadType}/${guid}/markAsHot`,
  removeHotMarkFromLoadByLoadType:
    (guid: string, loadType: string) => `${LOAD_SERVICE}${loadType}/${guid}/removeHotMark`,
  // clo
  clo: `${LOAD_SERVICE}${CLO_CONTROLLER}`,
  createLoad: `${LOAD_SERVICE}${CLO_CONTROLLER}`,
  cloList: `${LOAD_SERVICE}${CLO_CONTROLLER}/list`,
  cancelCLO: `${LOAD_SERVICE}${CLO_CONTROLLER}/cancel`,
  cloExport: `${LOAD_SERVICE}${CLO_CONTROLLER}/export`,
  cloBillTo: `${LOAD_SERVICE}${CLO_CONTROLLER}/billTo`,
  cloRouteList: `${LOAD_SERVICE}${CLO_CONTROLLER}/routeList`,
  cloListByVin: `${LOAD_SERVICE}${CLO_CONTROLLER}/list/byVin`,
  cloRouteSummary: `${LOAD_SERVICE}${CLO_CONTROLLER}/summary`,
  cloBillToList: `${LOAD_SERVICE}${CLO_CONTROLLER}/billTo/list`,
  cloExportReport: `${LOAD_SERVICE}${CLO_CONTROLLER}/list/export`,
  cloFromTemplate: `${LOAD_SERVICE}${CLO_CONTROLLER}/fromTemplate`,
  changeCloBranch: `${LOAD_SERVICE}${CLO_CONTROLLER}/changeEnterprise`,
  changeCloDivision: `${LOAD_SERVICE}${CLO_CONTROLLER}/changeDivision`,
  getCloListXML: `${LOAD_SERVICE}${CLO_CONTROLLER}/list/xmlFileByReport`,
  changeSalePersons: `${LOAD_SERVICE}${CLO_CONTROLLER}/changeSalePersons`,
  cloListForCustomer: `${LOAD_SERVICE}${CLO_CONTROLLER}/list/forCustomer`,
  getCloEntity: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}`,
  cloCancel: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/cancel`,
  changeAccountManagers: `${LOAD_SERVICE}${CLO_CONTROLLER}/changeAccountManagers`,
  cloUnquote: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/unquote`,
  approveClo: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/approve`,
  cloRestore: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/restore`,
  cloListForMasterInvoice: `${LOAD_SERVICE}${CLO_CONTROLLER}/list/forMasterInvoice`,
  getCloEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/details`,
  exportCloListForCustomer: `${LOAD_SERVICE}${CLO_CONTROLLER}/list/forCustomer/export`,
  cloChildrenAndCurrentList: `${LOAD_SERVICE}${CLO_CONTROLLER}/list/childrenAndCurrent`,
  relatedTelsByClo: `${LOAD_SERVICE}${CLO_CONTROLLER}/relatedTelsPrimaryReferenceValue`,
  getCloXMLEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/xmlFile`,
  cloListExportAddedToCredit: `${LOAD_SERVICE}${CLO_CONTROLLER}/list/export/addedToCredit`,
  getCloStatusEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/status`,
  changeCloSaleRepresentatives: `${LOAD_SERVICE}${CLO_CONTROLLER}/changeSaleRepresentatives`,
  getCloDivisionGuid: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/divisionGuid`,
  getCloChangeCustomStatus: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/changeCustomStatus`,
  getCloCostAllocationsEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/costAllocations`,
  getRouteBuilderCloEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/routeBuilder/details`,
  getCloAutoApplySharedAssessorials: (guid: string) => (
    `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/autoApplySharedAssessorials`
  ),
  getCloRecalculatedCostAllocationsEndpoint: (guid: string) => (
    `${LOAD_SERVICE}${CLO_CONTROLLER}/${guid}/recalculatedCostAllocations`
  ),
  // clo dispatch board controller
  cloDispatchBoard: `${LOAD_SERVICE}${CLO_DISPATCHBOARD_CONTROLLER}`,
  rateInfoByCloGuid: `${LOAD_SERVICE}${CLO_DISPATCHBOARD_CONTROLLER}/rateInfo`,
  cloDispatchBoardSummary: `${LOAD_SERVICE}${CLO_DISPATCHBOARD_CONTROLLER}/summary`,
  cloDispatchBoardByTelGuid: `${LOAD_SERVICE}${CLO_DISPATCHBOARD_CONTROLLER}/byTel`,
  cloDispatchBoardByCloGuid: `${LOAD_SERVICE}${CLO_DISPATCHBOARD_CONTROLLER}/byCloGuid`,
  cloDispatchBoardForCustomer: `${LOAD_SERVICE}${CLO_DISPATCHBOARD_CONTROLLER}/forCustomer`,
  // customer integration config
  customerIntegrationConfig: `${CUSTOMER_SERVICE}${CUSTOMER_INTEGRATION_CONFIG}`,
  customerIntegrationConfigList: `${CUSTOMER_SERVICE}${CUSTOMER_INTEGRATION_CONFIG}/list`,
  customerIntegrationConfigRestoreInherited: `${CUSTOMER_SERVICE}${CUSTOMER_INTEGRATION_CONFIG}/restoreInherited`,
  // clo template controller
  cloTemplate: `${LOAD_SERVICE}${CLO_CONTROLLER}/template`,
  cloTemplateList: `${LOAD_SERVICE}${CLO_CONTROLLER}/template/list`,
  cloTemplateFromClo: `${LOAD_SERVICE}${CLO_CONTROLLER}/template/fromClo`,
  cloTemplateListAvailable: `${LOAD_SERVICE}${CLO_CONTROLLER}/template/list/available`,
  getCloTemplateByGuid: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/template/${guid}`,
  // load-planner controller
  loadPlanner: `${LOAD_SERVICE}${LOAD_PLANNER_CONTROLLER}`,
  telListLoadPlanner: `${LOAD_SERVICE}${LOAD_PLANNER_CONTROLLER}/tel/list`,
  cloEventListLoadPlanner: `${LOAD_SERVICE}${LOAD_PLANNER_CONTROLLER}/event/list`,
  rateListByGuidsLoadPlanner: `${LOAD_SERVICE}${LOAD_PLANNER_CONTROLLER}/rate/list/byGuids`,
  eventListByGuidsLoadPlanner: `${LOAD_SERVICE}${LOAD_PLANNER_CONTROLLER}/event/list/byGuids`,
  getTelLoadPlannerEndpoint: (guid: string) => `${LOAD_SERVICE}${LOAD_PLANNER_CONTROLLER}/tel/${guid}`,
  // load-planner-template-controller
  loadPlannerTemplate: `${LOAD_SERVICE}${LOAD_PLANNER_TEMPLATE_CONTROLLER}`,
  loadPlannerTemplateListAvailable: `${LOAD_SERVICE}${LOAD_PLANNER_TEMPLATE_CONTROLLER}/list/available`,
  getRemoveLoadPlannerTemplateEndpoint: (guid: string) => `${LOAD_SERVICE}${LOAD_PLANNER_TEMPLATE_CONTROLLER}/${guid}`,
  // clo event controller
  cloEvent: `${LOAD_SERVICE}${CLO_EVENT_CONTROLLER}`,
  cloEventList: `${LOAD_SERVICE}${CLO_EVENT_CONTROLLER}/list`,
  cloEventUpdateSingle: `${LOAD_SERVICE}${CLO_EVENT_CONTROLLER}`,
  cloEventContacts: `${LOAD_SERVICE}${CLO_EVENT_CONTROLLER}/contacts`,
  cloEventAppointment: `${LOAD_SERVICE}${CLO_EVENT_CONTROLLER}/appointment`,
  cloEventListByCloGuids: `${LOAD_SERVICE}${CLO_EVENT_CONTROLLER}/list/byCloGuids`,
  getCloEventInfoForMapEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_EVENT_CONTROLLER}/${guid}/loadPlannerMapInfo`,
  // clo chat message controller
  cloChatMessage: `${LOAD_SERVICE}${CLO_CHAT_MSG_CONTROLLER}`,
  cloChatMessageList: `${LOAD_SERVICE}${CLO_CHAT_MSG_CONTROLLER}/list`,
  getCloChatMessageEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_CHAT_MSG_CONTROLLER}/${guid}`,
  // clo document controller
  cloDocument: `${LOAD_SERVICE}${CLO_DOCUMENT_CONTROLLER}`,
  cloDocumentList: `${LOAD_SERVICE}${CLO_DOCUMENT_CONTROLLER}/list`,
  cloExportDocuments: `${LOAD_SERVICE}${CLO_DOCUMENT_CONTROLLER}/pack`,
  cloDocumentUpdate: `${LOAD_SERVICE}${CLO_DOCUMENT_CONTROLLER}/update`,
  cloDocumentDownloadFile: `${LOAD_SERVICE}${CLO_DOCUMENT_CONTROLLER}/downloadFile`,
  cloDocumentPrintByReport: `${LOAD_SERVICE}${CLO_DOCUMENT_CONTROLLER}/printByReport`,
  getCloDocumentEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_DOCUMENT_CONTROLLER}/${guid}`,
  cloAttachableVendorDocuments: `${LOAD_SERVICE}${CLO_DOCUMENT_CONTROLLER}/attachableVendorDocuments`,
  // clo note controller
  cloNote: `${LOAD_SERVICE}${CLO_NOTE_CONTROLLER}`,
  cloNoteList: `${LOAD_SERVICE}${CLO_NOTE_CONTROLLER}/list`,
  getCloNoteEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_NOTE_CONTROLLER}/${guid}`,
  // clo reference controller
  cloReference: `${LOAD_SERVICE}${CLO_REFERENCE_CONTROLLER}`,
  cloReferenceList: `${LOAD_SERVICE}${CLO_REFERENCE_CONTROLLER}/list`,
  saveCloReferences: `${LOAD_SERVICE}${CLO_REFERENCE_CONTROLLER}/save`,
  cloMassReference: `${LOAD_SERVICE}${CLO_REFERENCE_CONTROLLER}/massCreate`,
  cloReferenceByEventGuids: `${LOAD_SERVICE}${CLO_REFERENCE_CONTROLLER}/byEventGuids`,
  getCloReferenceEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_REFERENCE_CONTROLLER}/${guid}`,
  // clo rate controller
  cloRate: `${LOAD_SERVICE}${CLO_RATE_CONTROLLER}`,
  cloRateList: `${LOAD_SERVICE}${CLO_RATE_CONTROLLER}/list`,
  cloRateSyncEndpoint: `${LOAD_SERVICE}${CLO_RATE_CONTROLLER}/sync`,
  cloRateSyncOnCreateEndpoint: `${LOAD_SERVICE}${CLO_RATE_CONTROLLER}/syncOnCreate`,
  cloRateAvailableCarriers: `${LOAD_SERVICE}${CLO_RATE_CONTROLLER}/availableCarriers`,
  getCloRateItemEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_RATE_CONTROLLER}/${guid}`,
  getCloRateSyncEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_RATE_CONTROLLER}/${guid}/sync`,
  getCloRateSelectEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_RATE_CONTROLLER}/${guid}/select`,
  serviceTypeTransportationMode: `${LOAD_SERVICE}${CLO_RATE_CONTROLLER}/serviceTypeTransportationMode`,
  // clo status message controller
  cloStatusMessage: `${LOAD_SERVICE}${CLO_STATUS_MSG_CONTROLLER}`,
  cloStatusMessageList: `${LOAD_SERVICE}${CLO_STATUS_MSG_CONTROLLER}/list`,
  getCloStatusMessageEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_STATUS_MSG_CONTROLLER}/${guid}`,
  // clo fleet assignment controller
  cloFleetAssignment: `${LOAD_SERVICE}${CLO_FLEET_ASSIGNMENT_CONTROLLER}`,
  cloAvailableTrucks: `${LOAD_SERVICE}${CLO_FLEET_ASSIGNMENT_CONTROLLER}/availableTrucks`,
  cloAvailableDrivers: `${LOAD_SERVICE}${CLO_FLEET_ASSIGNMENT_CONTROLLER}/availableDrivers`,
  cloAvailableTrailers: `${LOAD_SERVICE}${CLO_FLEET_ASSIGNMENT_CONTROLLER}/availableTrailers`,
  getCloFleetAssignmentEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_FLEET_ASSIGNMENT_CONTROLLER}/${guid}`,
  // clo rate charge controller
  cloRateChargeList: `${LOAD_SERVICE}${CLO_RATE_CHARGE_CONTROLLER}/list`,
  cloRateChargeListExport: `${LOAD_SERVICE}${CLO_RATE_CHARGE_CONTROLLER}/list/export`,
  // clo external system controller
  cloExternalSystemList: `${LOAD_SERVICE}${CLO_CONTROLLER}/externalSystem/list`,
  sendCloToExternalSystem: `${LOAD_SERVICE}${CLO_CONTROLLER}/externalSystem/send`,
  removeCloFromExternalSystemByGuid: (guid: string) => `${LOAD_SERVICE}${CLO_CONTROLLER}/externalSystem/${guid}`,
  // ship unit controller
  getShipUnitByTelGuid: `${LOAD_SERVICE}shipUnit/list/byTelGuid`,
  getShipUnitByCloGuid: `${LOAD_SERVICE}shipUnit/list/byCloGuid`,
  // tel controller
  routeList: `${LOAD_SERVICE}route/list`,
  tel: `${LOAD_SERVICE}${TEL_CONTROLLER}`,
  telList: `${LOAD_SERVICE}${TEL_CONTROLLER}/list`,
  routeListExport: `${LOAD_SERVICE}route/list/export`,
  telWS: `${LOAD_SERVICE}${TEL_CONTROLLER}/websocket`,
  routeListByLoad: `${LOAD_SERVICE}route/list/byLoad`,
  telBillTo: `${LOAD_SERVICE}${TEL_CONTROLLER}/billTo`,
  telByClo: `${LOAD_SERVICE}${TEL_CONTROLLER}/list/byClo`,
  telRouteList: `${LOAD_SERVICE}${TEL_CONTROLLER}/routeList`,
  telRouteSummary: `${LOAD_SERVICE}${TEL_CONTROLLER}/summary`,
  telExportReport: `${LOAD_SERVICE}${TEL_CONTROLLER}/list/export`,
  routeListByLoadExport: `${LOAD_SERVICE}route/list/byLoad/export`,
  telDriverCards: `${LOAD_SERVICE}${TEL_CONTROLLER}/driverCard/list`,
  callStatusCheck: `${LOAD_SERVICE}${TEL_CONTROLLER}/callStatusCheck`,
  telInvoiceExport: `${LOAD_SERVICE}${TEL_CONTROLLER}/invoice/export`,
  telByCloExport: `${LOAD_SERVICE}${TEL_CONTROLLER}/list/byClo/export`,
  changeTelBranch: `${LOAD_SERVICE}${TEL_CONTROLLER}/changeEnterprise`,
  telDispatchDocumentExport: `${LOAD_SERVICE}${TEL_CONTROLLER}/export`,
  getTelListXML: `${LOAD_SERVICE}${TEL_CONTROLLER}/list/xmlFileByReport`,
  sendQuoteRequests: `${LOAD_SERVICE}${TEL_CONTROLLER}/sendQuoteRequests`,
  telInvoicesCount: `${LOAD_SERVICE}${TEL_CONTROLLER}/invoice/invoiceCount`,
  detailsForPostLoad: `${LOAD_SERVICE}${TEL_CONTROLLER}/detailsForPostLoad`,
  changeBrokerAgents: `${LOAD_SERVICE}${TEL_CONTROLLER}/changeBrokerAgents`,
  exportTelToLoadBoard: `${LOAD_SERVICE}${TEL_CONTROLLER}/loadBoard/export`,
  getTelEntity: (guid: string) => `${LOAD_SERVICE}${TEL_CONTROLLER}/${guid}`,
  removeTelFromLoadBoard: `${LOAD_SERVICE}${TEL_CONTROLLER}/loadBoard/removeShipment`,
  getTelEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_CONTROLLER}/${guid}/details`,
  getTelCloList: (guid: string) => `${LOAD_SERVICE}${TEL_CONTROLLER}/${guid}/clo/list`,
  getTelXMLEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_CONTROLLER}/${guid}/xmlFile`,
  telInvoiceFleetExportReport: `${LOAD_SERVICE}${TEL_CONTROLLER}/invoice/fleet/list/export`,
  getTelPrimaryReferenceValue: `${LOAD_SERVICE}${TEL_CONTROLLER}/guidPrimaryReferenceValue`,
  changeTelSaleRepresentatives: `${LOAD_SERVICE}${TEL_CONTROLLER}/changeSaleRepresentatives`,
  changeCarrierRepresentatives: `${LOAD_SERVICE}${TEL_CONTROLLER}/changeCarrierRepresentatives`,
  getTelDriverCardColor: (guid: string) => `${LOAD_SERVICE}${TEL_CONTROLLER}/${guid}/driverCardColor`,
  getTelChangeCustomStatus: (guid: string) => `${LOAD_SERVICE}${TEL_CONTROLLER}/${guid}/changeCustomStatus`,
  getTelCostAllocationsEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_CONTROLLER}/${guid}/costAllocations`,
  getTelActionEndpoint: (guid: string, action: string) => `${LOAD_SERVICE}${TEL_CONTROLLER}/${guid}/${action}`,
  getTelAutoApplySharedAssessorials: (guid: string) => (
    `${LOAD_SERVICE}${TEL_CONTROLLER}/${guid}/autoApplySharedAssessorials`
  ),
  getTelRecalculatedCostAllocationsEndpoint: (guid: string) => (
    `${LOAD_SERVICE}${TEL_CONTROLLER}/${guid}/recalculatedCostAllocations`
  ),
  // tel linked clo list controller
  telLinkedCloCloInfos: `${LOAD_SERVICE}${TEL_LINKED_CLO_LIST_CONTROLLER}/cloInfos`,
  telLinkedCloTelInfos: `${LOAD_SERVICE}${TEL_LINKED_CLO_LIST_CONTROLLER}/telInfos`,
  telLinkedCloDocuments: `${LOAD_SERVICE}${TEL_LINKED_CLO_LIST_CONTROLLER}/documents`,
  telLinkedCloInvoices: `${LOAD_SERVICE}${TEL_LINKED_CLO_LIST_CONTROLLER}/cloInvoices`,
  telLinkedCloReferences: `${LOAD_SERVICE}${TEL_LINKED_CLO_LIST_CONTROLLER}/references`,
  // tel dispatch board controller
  telDispatchBoard: `${LOAD_SERVICE}${TEL_DISPATCHBOARD_CONTROLLER}`,
  rateInfoByTelGuid: `${LOAD_SERVICE}${TEL_DISPATCHBOARD_CONTROLLER}/rateInfo`,
  telDispatchBoardSummary: `${LOAD_SERVICE}${TEL_DISPATCHBOARD_CONTROLLER}/summary`,
  telDispatchBoardByCloGuid: `${LOAD_SERVICE}${TEL_DISPATCHBOARD_CONTROLLER}/byClo`,
  telDispatchBoardByTelGuid: `${LOAD_SERVICE}${TEL_DISPATCHBOARD_CONTROLLER}/byTelGuid`,
  // tel event controller
  telEvent: `${LOAD_SERVICE}${TEL_EVENT_CONTROLLER}`,
  telEventList: `${LOAD_SERVICE}${TEL_EVENT_CONTROLLER}/list`,
  telEventUpdateSingle: `${LOAD_SERVICE}${TEL_EVENT_CONTROLLER}`,
  telEventContacts: `${LOAD_SERVICE}${TEL_EVENT_CONTROLLER}/contacts`,
  telEventListExport: `${LOAD_SERVICE}${TEL_EVENT_CONTROLLER}/list/export`,
  telEventAppointment: `${LOAD_SERVICE}${TEL_EVENT_CONTROLLER}/appointment`,
  // tel chat message controller
  telChatMessage: `${LOAD_SERVICE}${TEL_CHAT_MSG_CONTROLLER}`,
  telChatMessageList: `${LOAD_SERVICE}${TEL_CHAT_MSG_CONTROLLER}/list`,
  telChatMessageUpdate: `${LOAD_SERVICE}${TEL_CHAT_MSG_CONTROLLER}/update`,
  telChatMessageDownloadFileList: `${LOAD_SERVICE}${TEL_CHAT_MSG_CONTROLLER}/downloadFile`,
  getTelChatMessageEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_CHAT_MSG_CONTROLLER}/${guid}`,
  // tel document controller
  telDocument: `${LOAD_SERVICE}${TEL_DOCUMENT_CONTROLLER}`,
  telDocumentList: `${LOAD_SERVICE}${TEL_DOCUMENT_CONTROLLER}/list`,
  telExportDocuments: `${LOAD_SERVICE}${TEL_DOCUMENT_CONTROLLER}/pack`,
  telDocumentUpdate: `${LOAD_SERVICE}${TEL_DOCUMENT_CONTROLLER}/update`,
  telDocumentDownloadFile: `${LOAD_SERVICE}${TEL_DOCUMENT_CONTROLLER}/downloadFile`,
  telDocumentPrintByReport: `${LOAD_SERVICE}${TEL_DOCUMENT_CONTROLLER}/printByReport`,
  getTelVendorDocuments: `${LOAD_SERVICE}${TEL_DOCUMENT_CONTROLLER}/getVendorDocuments`,
  getTelDocumentEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_DOCUMENT_CONTROLLER}/${guid}`,
  getDocumentTemplatesByTelGuid: `${LOAD_SERVICE}${TEL_DOCUMENT_CONTROLLER}/list/documentTemplates`,
  // tel note controller
  telNote: `${LOAD_SERVICE}${TEL_NOTE_CONTROLLER}`,
  telNoteList: `${LOAD_SERVICE}${TEL_NOTE_CONTROLLER}/list`,
  getTelNoteEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_NOTE_CONTROLLER}/${guid}`,
  // tel reference controller
  telReference: `${LOAD_SERVICE}${TEL_REFERENCE_CONTROLLER}`,
  telReferenceList: `${LOAD_SERVICE}${TEL_REFERENCE_CONTROLLER}/list`,
  saveTelReferences: `${LOAD_SERVICE}${TEL_REFERENCE_CONTROLLER}/save`,
  telMassReference: `${LOAD_SERVICE}${TEL_REFERENCE_CONTROLLER}/massCreate`,
  getTelReferenceEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_REFERENCE_CONTROLLER}/${guid}`,
  // tel external system controller
  getExternalSystemList: `${LOAD_SERVICE}${TEL_CONTROLLER}/externalSystem`,
  sendToExternalSystem: `${LOAD_SERVICE}${TEL_CONTROLLER}/externalSystem/send`,
  removeFromExternalSystem: `${LOAD_SERVICE}${TEL_CONTROLLER}/externalSystem/remove`,
  sendMassToExternalSystem: `${LOAD_SERVICE}${TEL_CONTROLLER}/externalSystem/send/mass`,
  // tel rate controller
  telRate: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}`,
  telFleetRate: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/fleet`,
  telCarrierRate: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier`,
  telRateApprove: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/approve`,
  massDispatch: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/massDispatch`,
  telCarrierRateDispatch: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/dispatch`,
  telCarrierRateForCarrier: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/forCarrier`,
  telRateCarrierRatingInfo: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/ratingInfo`,
  telCarrierRateMassCreate: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/massCreate`,
  telCarrierRateLaneHistory: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/laneHistory`,
  telCarrierRateRatingReport: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/ratingReport`,
  getTelRateItemEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/${guid}`,
  telAvailableCarriersRate: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/availableCarriers`,
  telCarrierRateRatingReportList: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/ratingReport/list`,
  getTelRateSelectEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/${guid}/select`,
  telCarrierRateMassCreateByReport: `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/massCreateByReport`,
  getTelRateAcceptEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/fleet/${guid}/accept`,
  getTelRateDeclineEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/fleet/${guid}/decline`,
  sendCarrierRateUpdate: (guid: string) => `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/${guid}/sendUpdate`,
  getTelCarrierRateAcceptEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/${guid}/accept`,
  resumeTelCarrierRateEmails: (guid: string) => `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/${guid}/resumeEmails`,
  getTelRateMailAcceptEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/${guid}/mailAccept`,
  getTelCarrierRateDeclineEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/${guid}/decline`,
  suspendTelCarrierRateEmails: (guid: string) => `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/${guid}/suspendEmails`,
  getTelRateMailDeclineEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/${guid}/mailDecline`,
  telCarrierRateMassCreateByDispatchBoardReport:
    `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/massCreateByDispatchBoardReport`,
  downloadCarrierTermsAndConditions: (token: string) =>
    `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/${token}/termsAndConditions/downloadFile`,
  downloadCarrierRateConfirmation: (token: string) =>
    `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/${token}/rateConfirmation/downloadFile`,
  getTelAcceptRateInfo: (guid: string) => (
    `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/${guid}/acceptRateInfo`
  ),
  getTelRateDeclineReasonCodes: (guid: string) => (
    `${LOAD_SERVICE}${TEL_RATE_CONTROLLER}/carrier/${guid}/declineReasonCodes`
  ),
  // tel for carrier controller
  getCarrierDetailPageEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_FOR_CARRIER_CONTROLLER}/${guid}`,
  addChatMessage: (token: string) => `${LOAD_SERVICE}${TEL_FOR_CARRIER_CONTROLLER}/${token}/addChatMessage`,
  setStatusMessageEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_FOR_CARRIER_CONTROLLER}/${guid}/addStatusMessage`,
  getTelChatMessagesByTelGuid: (token: string) => `${LOAD_SERVICE}${TEL_FOR_CARRIER_CONTROLLER}/${token}/chatMessages`,
  addTelChatMessagesByTelGuid: (token: string) => (
    `${LOAD_SERVICE}${TEL_FOR_CARRIER_CONTROLLER}/${token}/addChatMessage`
  ),
  carrierDetailPageCreateDocEndpoint: (guid: string) => (
    `${LOAD_SERVICE}${TEL_FOR_CARRIER_CONTROLLER}/${guid}/createDocument`
  ),
  getTelLocationInfoByAddress: `${LOAD_SERVICE}${TEL_FOR_CARRIER_CONTROLLER}/locationInfoByAddress`,
  // tel status message controller
  telStatusMessage: `${LOAD_SERVICE}${TEL_STATUS_MSG_CONTROLLER}`,
  telStatusMessageList: `${LOAD_SERVICE}${TEL_STATUS_MSG_CONTROLLER}/list`,
  getTelStatusMessageEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_STATUS_MSG_CONTROLLER}/${guid}`,
  // tel fleet assignment controller
  vendorRates: `${LOAD_SERVICE}${TEL_FLEET_ASSIGNMENT_CONTROLLER}/vendorRates`,
  availableTrucks: `${LOAD_SERVICE}${TEL_FLEET_ASSIGNMENT_CONTROLLER}/availableTrucks`,
  availableDrivers: `${LOAD_SERVICE}${TEL_FLEET_ASSIGNMENT_CONTROLLER}/availableDrivers`,
  availableTrailers: `${LOAD_SERVICE}${TEL_FLEET_ASSIGNMENT_CONTROLLER}/availableTrailers`,
  driverCompensations: `${LOAD_SERVICE}${TEL_FLEET_ASSIGNMENT_CONTROLLER}/driverCompensations`,
  availableTeamDrivers: `${LOAD_SERVICE}${TEL_FLEET_ASSIGNMENT_CONTROLLER}/availableTeamDrivers`,
  // tel driver cards controller
  telDriverCardsInfo: `${LOAD_SERVICE}${TEL_DRIVER_CARDS_CONTROLLER}/info`,
  assignedLoads: `${LOAD_SERVICE}${TEL_DRIVER_CARDS_CONTROLLER}/assignedLoads`,
  unassignedLoads: `${LOAD_SERVICE}${TEL_DRIVER_CARDS_CONTROLLER}/unassignedLoads`,
  assignedLoadsList: `${LOAD_SERVICE}${TEL_DRIVER_CARDS_CONTROLLER}/assignedLoads/list`,
  telDriverCardsByTruckInfo: `${LOAD_SERVICE}${TEL_DRIVER_CARDS_CONTROLLER}/byTruck/info`,
  notGroupedAssignedLoads: `${LOAD_SERVICE}${TEL_DRIVER_CARDS_CONTROLLER}/notGroupedAssignedLoads`,
  telDriverCardsByTruckAssignedLoadsList: `${LOAD_SERVICE}${TEL_DRIVER_CARDS_CONTROLLER}/byTruck/assignedLoads/list`,
  getTelDriverCardsDriverPayrollInfo:
    (guid: string) => `${LOAD_SERVICE}${TEL_DRIVER_CARDS_CONTROLLER}/${guid}/payrollInfo`,
  // tel rate charge controller
  telRateChargeList: `${LOAD_SERVICE}${TEL_RATE_CHARGE_CONTROLLER}/list`,
  telRateChargeListExport: `${LOAD_SERVICE}${TEL_RATE_CHARGE_CONTROLLER}/list/export`,
  // statistic board
  statistic: `${LOAD_SERVICE}statistic`,
  // route controller
  route: `${LOAD_SERVICE}${ROUTE_CONTROLLER}`,
  listRoutes: `${LOAD_SERVICE}${ROUTE_CONTROLLER}/list`,
  cloListRouteBuilder: `${LOAD_SERVICE}${ROUTE_CONTROLLER}/clo/list`,
  telListRouteBuilder: `${LOAD_SERVICE}${ROUTE_CONTROLLER}/tel/list`,
  changeRouteBranch: `${LOAD_SERVICE}${ROUTE_CONTROLLER}/changeEnterprise`,
  getRouteByGuidEndpoint: (guid: string) => `${LOAD_SERVICE}${ROUTE_CONTROLLER}/${guid}`,
  getRouteBuilderCloDetailsEndpoint: (guid: string) => `${LOAD_SERVICE}${ROUTE_CONTROLLER}/clo/${guid}/details`,
  deleteWithoutUpdateRelations: (guid: string) => `${LOAD_SERVICE}${ROUTE_CONTROLLER}/${guid}/deleteWithoutUpdateRelations`,
  // route mass create report
  routeMassCreateReport: `${LOAD_SERVICE}${ROUTE_MASS_CREATE_REPORT_CONTROLLER}`,
  routeMassCreateReportList: `${LOAD_SERVICE}${ROUTE_MASS_CREATE_REPORT_CONTROLLER}/list`,
  // load custom status
  loadCustomStatus: `${LOAD_SERVICE}loadCustomStatus`,
  loadCustomStatusList: `${LOAD_SERVICE}loadCustomStatus/list`,
  loadCustomStatusListAvailable: `${LOAD_SERVICE}loadCustomStatus/list/available`,
  loadCustomStatusRestoreInherited: `${LOAD_SERVICE}loadCustomStatus/restoreInherited`,
  // route template controller
  routeTemplate: `${LOAD_SERVICE}${ROUTE_TEMPLATE_CONTROLLER}`,
  routeTemplateList: `${LOAD_SERVICE}${ROUTE_TEMPLATE_CONTROLLER}/list`,
  pureUpdateRouteTemplate: `${LOAD_SERVICE}${ROUTE_TEMPLATE_CONTROLLER}/pureUpdate`,
  createRouteFromTemplate: `${LOAD_SERVICE}${ROUTE_TEMPLATE_CONTROLLER}/createRoute`,
  createRoutesFromTemplate: `${LOAD_SERVICE}${ROUTE_TEMPLATE_CONTROLLER}/createRoutes`,
  routeTemplateListLaneExport: `${LOAD_SERVICE}${ROUTE_TEMPLATE_CONTROLLER}/list/lane/export`,
  getRouteTemplateByGuid: (guid: string) => `${LOAD_SERVICE}${ROUTE_TEMPLATE_CONTROLLER}/${guid}`,
  routeTemplateListWithSingleClo: `${LOAD_SERVICE}${ROUTE_TEMPLATE_CONTROLLER}/list/withSingleClo`,
  getActivateRouteTemplateEndpoint: (guid: string) => `${LOAD_SERVICE}${ROUTE_TEMPLATE_CONTROLLER}/${guid}/activate`,
  getDeactivateRouteTemplateEndpoint: (guid: string) =>
    `${LOAD_SERVICE}${ROUTE_TEMPLATE_CONTROLLER}/${guid}/deactivate`,
  // draft
  draftRoute: `${LOAD_SERVICE}draftRoute`,
  draftRouteList: `${LOAD_SERVICE}draftRoute/list`,
  // mail
  mailSending: `${LOAD_SERVICE}mail`,
  getMailGuidEndpoint: (guid: string) => `${LOAD_SERVICE}mail/${guid}/downloadMailMessage`,
  // driver payroll controller
  driverPayroll: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}`,
  driverPayrollList: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/list`,
  payrollDriverList: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/driver/list`,
  driverPayrollInvoices: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/invoice/list`,
  driverPayrollMassCreate: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/massCreate`,
  driverPayrollExportIIF: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/qb/exportIIF`,
  payrollSendToQuickBooks: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/toQuickBooks`,
  driverPayrollListCharges: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/list/charges`,
  driverPayrollReportExport: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/list/export`,
  driverPayrollChaseExport: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/chase/export`,
  driverPayrollCustomExport: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/customExport`,
  updateDriverPayrollInvoices: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/updateInvoices`,
  getDriverPayrollListByGuids: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/list/guidNumber`,
  removeInvoiceFromDriverPayroll: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/removeInvoice`,
  driverPayrollXmlFileByReport: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/list/xmlFileByReport`,
  payrollSendToQuickBooksDesktop: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/toQuickBooksDesktop`,
  driverPayrollCustomExportByReport: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/customExportByReport`,
  getInvoicesToUpdateDriverPayroll: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/invoice/list/forUpdate`,
  getDriverPayrollDetailsEndpoint: (guid: string) => `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/${guid}`,
  driverPayrollChargesIsPaidOnPayroll: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/charges/isPaidOnPayroll`,
  getDriverPayrollXMLEndpoint: (guid: string) => `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/${guid}/xmlFile`,
  driverPayrollsForVendorPayroll: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/list/availableForVendorPayroll`,
  driverPayrollChargesByMonthlyPaymentGuid: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/charges/byMonthlyPayment`,
  driverPayrollChargesByPayrollAssessorial: `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/charges/byPayrollAssessorial`,
  driverPayrollAssessorialsPaidOnPayroll:
    `${LOAD_SERVICE}${DRIVER_PAYROLL_CONTROLLER}/charges/payrollAssessorials/isPaidOnPayroll`,
  // driver payroll document controller
  driverPayrollDocument: `${LOAD_SERVICE}${DRIVER_PAYROLL_DOCUMENT_CONTROLLER}`,
  driverPayrollExport: `${LOAD_SERVICE}${DRIVER_PAYROLL_DOCUMENT_CONTROLLER}/print`,
  driverPayrollDocuments: `${LOAD_SERVICE}${DRIVER_PAYROLL_DOCUMENT_CONTROLLER}/list`,
  driverPayrollSendViaEmail: `${LOAD_SERVICE}${DRIVER_PAYROLL_DOCUMENT_CONTROLLER}/sendViaEmail`,
  driverPayrollPrintByReport: `${LOAD_SERVICE}${DRIVER_PAYROLL_DOCUMENT_CONTROLLER}/printByReport`,
  driverPayrollDownloadDocument: `${LOAD_SERVICE}${DRIVER_PAYROLL_DOCUMENT_CONTROLLER}/downloadFile`,
  driverPayrollDocumentEnableOnMobile: `${LOAD_SERVICE}${
    DRIVER_PAYROLL_DOCUMENT_CONTROLLER}/makeAvailableOnDriverMobile`,
  driverPayrollDocumentDisableOnMobile: `${LOAD_SERVICE}${
    DRIVER_PAYROLL_DOCUMENT_CONTROLLER}/makeUnavailableOnDriverMobile`,
  // fleet vendor payroll controller
  vendorPayroll: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}`,
  vendorPayrollList: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/list`,
  vendorPayrollDocument: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/document`,
  vendorPayrollInvoices: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/invoice/list`,
  vendorPayrollExport: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/document/print`,
  vendorPayrollExportIIF: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/qb/exportIIF`,
  vendorPayrollDocuments: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/document/list`,
  vendorPayrollChaseExport: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/chase/export`,
  vendorPayrollReportExport: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/list/export`,
  vendorPayrollListCharges: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/list/charges`,
  vendorPayrollVendorList: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/fleetVendor/list`,
  vendorPayrollListByGuids: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/list/guidNumber`,
  updateVendorPayrollInvoices: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/updateInvoices`,
  vendorPayrollWithoutTrips: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/createWithoutTrips`,
  removeInvoiceFromVendorPayroll: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/removeInvoice`,
  vendorPayrollExportToQuickBooks: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/toQuickBooks`,
  vendorPayrollStatisticExport: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/statistic/export`,
  vendorPayrollSendViaEmail: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/document/sendViaEmail`,
  sendVendorPayrollListToSageIntacct: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/toSageIntacct`,
  vendorPayrollPrintByReport: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/document/printByReport`,
  vendorPayrollXmlFileByReport: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/list/xmlFileByReport`,
  vendorPayrollDownloadDocument: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/document/downloadFile`,
  getInvoicesToUpdateVendorPayroll: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/invoice/list/forUpdate`,
  vendorPayrollSendToQuickBooksDesktop: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/toQuickBooksDesktop`,
  getVendorPayrollDetailsEndpoint: (guid: string) => `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/${guid}`,
  vendorPayrollChargesIsPaidOnPayroll: `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/charges/isPaidOnPayroll`,
  getVendorPayrollXMLEndpoint: (guid: string) => `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/${guid}/xmlFile`,
  getVendorPayrollStatisticEndpoint: (guid: string) =>
    `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/${guid}/statistic`,
  getVendorPayrollNewXMLEndpoint: (guid: string) =>
    `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/${guid}/newXmlFile`,
  vendorPayrollChargesByMonthlyPaymentGuid:
    `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/charges/byMonthlyPayment`,
  vendorPayrollChargesByPayrollAssessorial:
  `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/charges/byPayrollAssessorial`,
  getVendorDriverRatesXMLEndpoint: (guid: string) =>
    `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/${guid}/vendorDriverRates/xmlFile`,
  getVendorPayrollXMLByTruck: (guid: string) =>
    `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/${guid}/xmlFile/ByTruck`,
  vendorPayrollAssessorialsPaidOnPayroll:
    `${LOAD_SERVICE}${FLEET_VENDOR_PAYROLL_CONTROLLER}/charges/payrollAssessorials/isPaidOnPayroll`,
  // customer master invoice controller
  customerMasterInvoice: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}`,
  customerMasterInvoiceList: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/list`,
  customerMasterInvoiceDocument: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/document`,
  customerMasterInvoiceExport: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/document/print`,
  customerMasterInvoiceInvoices: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/invoice/list`,
  customerMasterInvoiceExportIIF: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/qb/exportIIF`,
  customerMasterInvoiceDocuments: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/document/list`,
  customerMasterInvoiceReportExport: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/list/export`,
  customerMasterInvoiceCustomExport: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/customExport`,
  exportCustomerMasterInvoiceToEdi: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/list/export/edi`,
  customerMasterInvoiceDocumentsZip: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/document/toZip`,
  customerMasterInvoiceMassPrint: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/document/massPrint`,
  customerMasterInvoiceGroupedInvoices: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/grouped/list`,
  customerMasterInvoiceNoGroupInvoices: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/noGroup/list`,
  customerMasterInvoiceSendToQuickBooks: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/toQuickBooks`,
  updateCustomerMasterInvoiceInvoices: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/updateInvoices`,
  customerMasterInvoiceXmlFile: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/masterInvoicesXmlFile`,
  removeInvoiceFromCustomerMasterInvoice: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/removeInvoice`,
  sendCustomerMasterInvoiceToSageIntacct: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/toSageIntacct`,
  customerMasterInvoicePrintByReport: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/document/printByReport`,
  customerMasterInvoiceXmlFileByReport: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/list/xmlFileByReport`,
  getCustomerMasterInvoiceEndpoint: (guid: string) => `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/${guid}`,
  customerMasterInvoiceDownloadDocument: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/document/downloadFile`,
  customerMasterInvoiceSendToQuickBooksDesktop: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/toQuickBooksDesktop`,
  customerMasterInvoiceInvoicesForUpdate: (
    `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/invoice/list/forUpdate`
  ),
  customerMasterInvoiceInvoicesExport: (
    `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/document/printCloInvoice`
  ),
  getCustomerMasterInvoiceXMLEndpoint: (guid: string) => (
    `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_CONTROLLER}/${guid}/xmlFile`
  ),
  // master-invoice factoring
  exportFactoringDocument: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_FACTORING_CONTROLLER}/generateDataFile`,
  customerMasterInvoiceFactoringExport: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_FACTORING_CONTROLLER}/export`,
  createFactoringDocument: `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_FACTORING_CONTROLLER}/generateExportPackage`,
  createAndExportFactoringDocument:
    `${LOAD_SERVICE}${CUSTOMER_MASTER_INVOICE_FACTORING_CONTROLLER}/generatePackageAndExport`,
  // customer invoice controller
  cloInvoice: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}`,
  customerInvoiceList: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/list`,
  customerInvoicesPrint: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/print`,
  customerInvoiceExport: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/export`,
  cloInvoicesCount: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/invoiceCount`,
  cloInvoiceTotals: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/invoiceTotals`,
  customerInvoiceReference: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/reference`,
  customerInvoiceMassUpdate: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/massUpdate`,
  cloInvoicePrintByReport: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/printByReport`,
  customerInvoiceExportIIF: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/qb/exportIIF`,
  customerInvoiceXml: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/list/xmlFileByReport`,
  customerInvoiceExportReport: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/list/export`,
  exportCustomerInvoicesToEdi: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/list/ediExport`,
  customerInvoiceFinancialExport: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/toQuickBooks`,
  sendCustomerInvoiceListToBC: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/toBusinessCentral`,
  sendCustomerInvoiceListToSageIntacct: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/toSageIntacct`,
  getCloInvoiceItemEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/${guid}`,
  customerInvoiceToQuickBooksDesktop: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/toQuickBooksDesktop`,
  getCloInvoiceXMLEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/${guid}/xmlFile`,
  customerInvoiceMarkAsReadyForBilling: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/markAsReadyForBilling`,
  customerInvoiceUnamrkAsReadyForBilling: `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/unmarkAsReadyForBilling`,
  getCloInvoiceExportPackEndpoint: (guid: string) => `${LOAD_SERVICE}${CLO_INVOICE_CONTROLLER}/${guid}/exportAndPack`,
  // tel invoice fleet/fleetVendor controller
  telInvoiceReject: `${LOAD_SERVICE}${TEL_INVOICE_CONTROLLER}/reject`,
  telInvoiceApprove: `${LOAD_SERVICE}${TEL_INVOICE_CONTROLLER}/approve`,
  telInvoiceAssignments: `${LOAD_SERVICE}${TEL_INVOICE_CONTROLLER}/list`,
  telInvoiceTotals: `${LOAD_SERVICE}${TEL_INVOICE_CONTROLLER}/invoiceTotals`,
  getReconciliation: (guid: string) => `${LOAD_SERVICE}${TEL_INVOICE_CONTROLLER}/${guid}/reconciliation`,
  // tel cross border integration manifest controller
  telCrossBorderIntegration: `${LOAD_SERVICE}${TEL_CROSS_BORDER_INTEGRATION_CONTROLLER}`,
  telCrossBorderIntegrationList: `${LOAD_SERVICE}${TEL_CROSS_BORDER_INTEGRATION_CONTROLLER}/list`,
  removeTelCrossBorderIntegration: (guid: string) => `${LOAD_SERVICE}${TEL_CROSS_BORDER_INTEGRATION_CONTROLLER}/${guid}`,
  sendTelCrossBorderIntegration: (guid: string) => `${LOAD_SERVICE}${TEL_CROSS_BORDER_INTEGRATION_CONTROLLER}/${guid}/sendToIntegration`,
  // driver
  telInvoice: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_CONTROLLER}`,
  telFleetInvoice: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_CONTROLLER}`,
  driverPayrollInvoiceList: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_CONTROLLER}/list`,
  driverPayrollInvoiceReference: `${LOAD_SERVICE}${TEL_CONTROLLER}/invoice/reference`,
  telInvoiceByTelGuid: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_CONTROLLER}/createByTelGuid`,
  driverInvoiceExportIIF: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_CONTROLLER}/qb/exportIIF`,
  driverInvoiceXml: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_CONTROLLER}/list/xmlFileByReport`,
  driverInvoicePrintByReport: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_CONTROLLER}/printByReport`,
  driverPayrollInvoiceMassUpdate: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_CONTROLLER}/massUpdate`,
  driverPayrollFinancialExport: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_CONTROLLER}/toQuickBooks`,
  driverPayrollInvoiceExportReport: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_CONTROLLER}/list/export`,
  getTelInvoiceItemEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_INVOICE_FLEET_CONTROLLER}/${guid}`,
  // document hub integration config
  documentHubIntegrationConfig: `${LOAD_SERVICE}${DOCUMENT_HUB_INTEGRATION_CONFIG_CONTROLLER}`,
  documentHubIntegrationConfigList: `${LOAD_SERVICE}${DOCUMENT_HUB_INTEGRATION_CONFIG_CONTROLLER}/list`,
  removeDocumentHubIntegrationConfig: (guid: string) =>
    `${LOAD_SERVICE}${DOCUMENT_HUB_INTEGRATION_CONFIG_CONTROLLER}/${guid}`,
  // accounting integration config
  accountingIntegrationConfig: `${LOAD_SERVICE}${ACCOUNTING_INTEGRATION_CONFIG_CONTROLLER}`,
  accountingIntegrationConfigList: `${LOAD_SERVICE}${ACCOUNTING_INTEGRATION_CONFIG_CONTROLLER}/list`,
  removeAccountingIntegrationConfig: (guid: string) =>
    `${LOAD_SERVICE}${ACCOUNTING_INTEGRATION_CONFIG_CONTROLLER}/${guid}`,
  // vendor
  telFleetVendorInvoice: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_VENDOR_CONTROLLER}`,
  telFleetVendorInvoiceList: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_VENDOR_CONTROLLER}/list`,
  telFleetVendorInvoiceMassUpdate: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_VENDOR_CONTROLLER}/massUpdate`,
  sendVendorInvoiceListToBC: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_VENDOR_CONTROLLER}/toBusinessCentral`,
  telFleetVendorInvoiceExportIIF: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_VENDOR_CONTROLLER}/qb/exportIIF`,
  telFleetVendorInvoiceExportReport: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_VENDOR_CONTROLLER}/list/export`,
  telFleetVendorInvoicePrintByReport: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_VENDOR_CONTROLLER}/printByReport`,
  sendVendorInvoiceListToSageIntacct: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_VENDOR_CONTROLLER}/toSageIntacct`,
  telFleetVendorInvoiceExportToQuickBooks: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_VENDOR_CONTROLLER}/toQuickBooks`,
  telFleetVendorInvoiceXmlFileByReport: `${LOAD_SERVICE}${TEL_INVOICE_FLEET_VENDOR_CONTROLLER}/list/xmlFileByReport`,
  getFleetVendorInvoiceItemEndpoint: (guid: string) => `${LOAD_SERVICE}${TEL_INVOICE_FLEET_VENDOR_CONTROLLER}/${guid}`,
  // pending activity
  routeFleetDriverPendingActivity: `${LOAD_SERVICE}${FLEET_DRIVER_PENDING_ACTIVITY_CONTROLLER}`,
  getRemoveRouteFleetDriverPendingActivity:
    (guid: string) => `${LOAD_SERVICE}${FLEET_DRIVER_PENDING_ACTIVITY_CONTROLLER}/${guid}`,
  // unavailable period
  routeFleetDriverUnavailablePeriod: `${LOAD_SERVICE}${FLEET_DRIVER_UNAVAILABLE_PERIOD_CONTROLLER}`,
  routeFleetDriverUnavailablePeriodList: `${LOAD_SERVICE}${FLEET_DRIVER_UNAVAILABLE_PERIOD_CONTROLLER}/list`,
  getRemoveRouteFleetDriverUnavailablePeriod: (guid: string) =>
    `${LOAD_SERVICE}${FLEET_DRIVER_UNAVAILABLE_PERIOD_CONTROLLER}/${guid}`,
  // carrier invoice controller
  carrierInvoice: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}`,
  telCarrierInvoice: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}`,
  carrierInvoiceList: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/list`,
  carrierInvoiceExport: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/export`,
  carrierInvoiceMassUpdate: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/massUpdate`,
  carrierInvoiceListExport: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/list/export`,
  carrierInvoiceExportIIF: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/qb/exportIIF`,
  carrierInvoiceXml: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/list/xmlFileByReport`,
  carrierInvoiceByTelGuid: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/createByTelGuid`,
  carrierInvoicePrintByReport: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/printByReport`,
  carrierInvoiceSendToQuickBooks: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/toQuickBooks`,
  getCarrierInvoice: (guid: string) => `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/${guid}`,
  sendCarrierInvoiceListToBC: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/toBusinessCentral`,
  sendCarrierInvoiceListToSageIntacct: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/toSageIntacct`,
  carrierInvoiceSendToQuickBooksDesktop: `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/toQuickBooksDesktop`,
  // service vendor invoice controller
  serviceVendorInvoice: `${LOAD_SERVICE}${SERVICE_VENDOR_INVOICE_CONTROLLER}`,
  serviceVendorInvoiceList: `${LOAD_SERVICE}${SERVICE_VENDOR_INVOICE_CONTROLLER}/list`,
  serviceVendorInvoiceExport: `${LOAD_SERVICE}${SERVICE_VENDOR_INVOICE_CONTROLLER}/export`,
  serviceVendorFinancialExport: `${LOAD_SERVICE}${SERVICE_VENDOR_INVOICE_CONTROLLER}/toQuickBooks`,
  serviceVendorInvoiceMassUpdate: `${LOAD_SERVICE}${SERVICE_VENDOR_INVOICE_CONTROLLER}/massUpdate`,
  serviceVendorInvoiceListExport: `${LOAD_SERVICE}${SERVICE_VENDOR_INVOICE_CONTROLLER}/list/export`,
  serviceVendorInvoiceExportIIF: `${LOAD_SERVICE}${SERVICE_VENDOR_INVOICE_CONTROLLER}/qb/exportIIF`,
  getServiceVendorInvoice: (guid: string) => `${LOAD_SERVICE}${SERVICE_VENDOR_INVOICE_CONTROLLER}/${guid}`,
  getCarrierInvoiceReject: (guid: string) => `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/${guid}/reject`,
  sendServiceVendorInvoiceListToBC: `${LOAD_SERVICE}${SERVICE_VENDOR_INVOICE_CONTROLLER}/toBusinessCentral`,
  sendServiceVendorInvoiceListToSageIntacct: `${LOAD_SERVICE}${SERVICE_VENDOR_INVOICE_CONTROLLER}/toSageIntacct`,
  getCarrierReconciliation: (guid: string) => `${LOAD_SERVICE}${CARRIER_INVOICE_CONTROLLER}/${guid}/reconciliation`,
  // claim controller
  claim: `${LOAD_SERVICE}claim`,
  claimList: `${LOAD_SERVICE}claim/list`,
  getClaimByGuid: (guid: string) => `${LOAD_SERVICE}claim/${guid}`,
  claimGeneralDetailsForDispatchDetails: `${LOAD_SERVICE}claim/details`,
  claimDetails: (guid: string) => `${LOAD_SERVICE}claim/${guid}/details`,
  updateClaimStatus: (guid: string) => `${LOAD_SERVICE}claim/${guid}/updateStatus`,
  relatedTelDetailsByClaimGuid: (guid: string) => `${LOAD_SERVICE}claim/${guid}/relatedTelDetails`,
  // claim-document controller
  claimDocument: `${LOAD_SERVICE}${CLAIM_DOCUMENT_CONTROLLER}`,
  claimDocumentList: `${LOAD_SERVICE}${CLAIM_DOCUMENT_CONTROLLER}/list`,
  claimDocumentUpdate: `${LOAD_SERVICE}${CLAIM_DOCUMENT_CONTROLLER}/update`,
  claimDocumentDownloadFile: `${LOAD_SERVICE}${CLAIM_DOCUMENT_CONTROLLER}/downloadFile`,
  claimDocumentByGuid: (guid: string) => `${LOAD_SERVICE}${CLAIM_DOCUMENT_CONTROLLER}/${guid}`,
  // claim-note controller
  claimNote: `${LOAD_SERVICE}${CLAIM_NOTE_CONTROLLER}`,
  claimNoteList: `${LOAD_SERVICE}${CLAIM_NOTE_CONTROLLER}/list`,
  claimNoteByGuid: (guid: string) => `${LOAD_SERVICE}${CLAIM_NOTE_CONTROLLER}/${guid}`,
  // claim-damaged-item controller
  claimDamagedItem: `${LOAD_SERVICE}${CLAIM_DAMAGED_ITEM_CONTROLLER}`,
  claimDamagedItemList: `${LOAD_SERVICE}${CLAIM_DAMAGED_ITEM_CONTROLLER}/list`,
  claimDamagedItemCloItems: `${LOAD_SERVICE}${CLAIM_DAMAGED_ITEM_CONTROLLER}/list/cloItems`,
  claimDamagedItemByGuid: (guid: string) => `${LOAD_SERVICE}${CLAIM_DAMAGED_ITEM_CONTROLLER}/${guid}`,
  // claim-accident controller
  claimAccident: `${LOAD_SERVICE}${CLAIM_ACCIDENT_CONTROLLER}`,
  claimAccidentByGuid: (guid: string) => `${LOAD_SERVICE}${CLAIM_ACCIDENT_CONTROLLER}/${guid}`,
  // claim-involved-party controller
  claimInvolvedParty: `${LOAD_SERVICE}${CLAIM_INVOLVED_PARTY_CONTROLLER}`,
  claimInvolvedPartyList: `${LOAD_SERVICE}${CLAIM_INVOLVED_PARTY_CONTROLLER}/list`,
  claimInvolvedPartyByGuid: (guid: string) => `${LOAD_SERVICE}${CLAIM_INVOLVED_PARTY_CONTROLLER}/${guid}`,
  // claim-involved-party controller
  claimAttorney: `${LOAD_SERVICE}${CLAIM_ATTORNEY_CONTROLLER}`,
  claimAttorneyList: `${LOAD_SERVICE}${CLAIM_ATTORNEY_CONTROLLER}/list`,
  claimAttorneyByGuid: (guid: string) => `${LOAD_SERVICE}${CLAIM_ATTORNEY_CONTROLLER}/${guid}`,
  // DISTANCE SERVICE
  distanceCalculation: `${DISTANCE_SERVICE}distance`,
  distanceCalculatorConfig: `${DISTANCE_SERVICE}distanceCalculatorConfig`,
  assignDistanceCalculation: `${DISTANCE_SERVICE}distance/multiDestinations`,
  distanceCalculatorConfigList: `${DISTANCE_SERVICE}distanceCalculatorConfig/list`,
  distanceGeofencingLocationInfoByAddress: `${DISTANCE_SERVICE}geofencing/locationInfoByAddress`,
  distanceGeofencingLocationInfoByAddressV2: `${DISTANCE_SERVICE}geofencing/locationInfoByAddress/v2`,
  distanceCalculatorConfigGoogleApiKey: `${DISTANCE_SERVICE}distanceCalculatorConfig/getGoogleApiKey`,
  distanceCalculatorConfigRestoreInherited: `${DISTANCE_SERVICE}distanceCalculatorConfig/restoreInherited`,
  // STATISTIC SERVICE
  auditList: `${STATISTIC_SERVICE}audit/list`,
  appRelease: `${STATISTIC_SERVICE}releaseNotes`,
  statisticWebsocket: `${STATISTIC_SERVICE}websocket`,
  getAuditEndpoint: (guid: string, type: string) => `${STATISTIC_SERVICE}audit?guid=${guid}&type=${type}`,
  // import report controller
  importReport: `${STATISTIC_SERVICE}importReport`,
  importReportList: `${STATISTIC_SERVICE}importReport/list`,
  // importer controller
  importerData: `${STATISTIC_SERVICE}importer/data`,
  importerSave: `${STATISTIC_SERVICE}importer/save`,
  importerUpload: `${STATISTIC_SERVICE}importer/upload`,
  importerTemplate: `${STATISTIC_SERVICE}importer/template`,
  importerTemplateFile: `${STATISTIC_SERVICE}importer/templateFile`,
  importerImportFromFile: `${STATISTIC_SERVICE}importer/importFromFile`,
  // chart controller
  chart: `${STATISTIC_SERVICE}${STATISTIC_CHART_CONTROLLER}`,
  chartList: `${STATISTIC_SERVICE}${STATISTIC_CHART_CONTROLLER}/list`,
  chartData: `${STATISTIC_SERVICE}${STATISTIC_CHART_CONTROLLER}/chartData`,
  chartExportReport: `${STATISTIC_SERVICE}${STATISTIC_CHART_CONTROLLER}/list/export`,
  chartListByCollection: `${STATISTIC_SERVICE}${STATISTIC_CHART_CONTROLLER}/groupedList`,
  getChartEndpointDelete: `${STATISTIC_SERVICE}${STATISTIC_CHART_CONTROLLER}/massDelete`,
  getChartEndpoint: (guid: string) => `${STATISTIC_SERVICE}${STATISTIC_CHART_CONTROLLER}/${guid}`,
  // dashboard controller
  statisticDashboard: `${STATISTIC_SERVICE}statistic/dashboard`,
  dashboard: `${STATISTIC_SERVICE}${STATISTIC_DASHBOARD_CONTROLLER}`,
  statisticDashboardChart: `${STATISTIC_SERVICE}statistic/dashboard/chart`,
  dashboardList: `${STATISTIC_SERVICE}${STATISTIC_DASHBOARD_CONTROLLER}/list`,
  statisticDashboardChartExport: `${STATISTIC_SERVICE}statistic/dashboard/chart/export`,
  getDashboardEndpoint: (guid: string) => `${STATISTIC_SERVICE}${STATISTIC_DASHBOARD_CONTROLLER}/${guid}`,
  // INTEGRATION SERVICE
  quickBooksConnect: `${INTEGRATION_SERVICE}qb/connect`,
  quickBooksDisconnect: `${INTEGRATION_SERVICE}qb/revoke`,
  // qb web connector config controller
  quickBooksDesktopConnection: `${INTEGRATION_SERVICE}${QB_WEB_CONNECTOR_CONFIG}`,
  quickBooksDesktopConnectionList: `${INTEGRATION_SERVICE}${QB_WEB_CONNECTOR_CONFIG}/list`,
  quickBooksDesktopConnectionItem: (guid: string) => `${INTEGRATION_SERVICE}${QB_WEB_CONNECTOR_CONFIG}/${guid}`,
  // CARRIER SERVICE
  carrierSocket: `${CARRIER_SERVICE}websocket`,
  // carrier controller
  carrier: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}`,
  carrierList: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/list`,
  carrierSafety: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/safety`,
  carrierNameMap: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/guidNameMap`,
  carrierHideIssues: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/hideIssues`,
  carrierExportReport: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/list/export`,
  carrierListForContract: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/forContract`,
  carrierChangeBranch: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/changeEnterprise`,
  carrierRateTerminalList: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/terminal/list`,
  sendOnboardingPackage: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/sendOnboardingPackage`,
  getCarrierEndpoint: (guid: string) => `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/${guid}`,
  carrierListParentAndCurrent: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/list/parentAndCurrent`,
  carrierListChildrenAndCurrent: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/list/childrenAndCurrent`,
  // carrier rate request controller
  carrierRateRequestList: `${CARRIER_SERVICE}carrierRateRequest/list`,
  // carrier statistic controller
  carrierStatisticList: `${CARRIER_SERVICE}carrier/statistic/list`,
  // carrier statistic controller
  carrierScorecard: `${CARRIER_SERVICE}carrier/statistic/scorecard`,
  // carrier synchronization controller
  syncCarrier: `${CARRIER_SERVICE}${CARRIER_SYNCHRONIZATION}/sync`,
  syncCarrierByReport: `${CARRIER_SERVICE}${CARRIER_SYNCHRONIZATION}/syncByReport`,
  syncSelectedCarriers: `${CARRIER_SERVICE}${CARRIER_SYNCHRONIZATION}/syncSelected`,
  carrierSynchronizationAddToWatchList: `${CARRIER_SERVICE}${CARRIER_SYNCHRONIZATION}/addToWatchList`,
  carrierSynchronizationAddAllToWatchList: `${CARRIER_SERVICE}${CARRIER_SYNCHRONIZATION}/addAllToWatchList`,
  carrierSynchronizationRemoveFromWatchList: `${CARRIER_SERVICE}${CARRIER_SYNCHRONIZATION}/removeFromWatchList`,
  carrierSynchronizationAddToWatchListByReport: `${CARRIER_SERVICE}${CARRIER_SYNCHRONIZATION}/addToWatchListByReport`,
  // carrier pickup requester controller
  carrierPickupRequester: `${CARRIER_SERVICE}${CARRIER_PICKUP_REQUESTER_CONTROLLER}`,
  carrierPickupRequesterList: `${CARRIER_SERVICE}${CARRIER_PICKUP_REQUESTER_CONTROLLER}/list`,
  carrierPickupRequesterListAvailable: `${CARRIER_SERVICE}${CARRIER_PICKUP_REQUESTER_CONTROLLER}/list/available`,
  removeCarrierPickupRequester: (guid: string) => `${CARRIER_SERVICE}${CARRIER_PICKUP_REQUESTER_CONTROLLER}/${guid}`,
  // carrier edi loader config controller
  carrierEdiLoaderConfig: `${CARRIER_SERVICE}${CARRIER_EDI_LOADER_CONFIG_CONTROLLER}`,
  carrierEdiLoaderConfigList: `${CARRIER_SERVICE}${CARRIER_EDI_LOADER_CONFIG_CONTROLLER}/list`,
  removeCarrierEdiLoaderConfig: (guid: string) => `${CARRIER_SERVICE}${CARRIER_EDI_LOADER_CONFIG_CONTROLLER}/${guid}`,
  // carrier edi exporter config controller
  carrierEdiExporterConfig: `${CARRIER_SERVICE}${CARRIER_EDI_EXPORTER_CONFIG_CONTROLLER}`,
  carrierEdiExporterConfigList: `${CARRIER_SERVICE}${CARRIER_EDI_EXPORTER_CONFIG_CONTROLLER}/list`,
  removeCarrierEdiExporterConfig: (guid: string) =>
    `${CARRIER_SERVICE}${CARRIER_EDI_EXPORTER_CONFIG_CONTROLLER}/${guid}`,
  // edi carrier invoice transaction info controller
  ediCarrierInvoiceTransaction: `${CARRIER_SERVICE}${EDI_CARRIER_INVOICE_TRANSACTION_CONTROLLER}`,
  ediCarrierInvoiceTransactionList: `${CARRIER_SERVICE}${EDI_CARRIER_INVOICE_TRANSACTION_CONTROLLER}/list`,
  ediCarrierInvoiceAssignToTrip: `${CARRIER_SERVICE}${EDI_CARRIER_INVOICE_TRANSACTION_CONTROLLER}/assignToTrip`,
  ediCarrierInvoiceTransactionDownloadFile:
    `${CARRIER_SERVICE}${EDI_CARRIER_INVOICE_TRANSACTION_CONTROLLER}/downloadFile`,
  ediCarrierInvoiceTransactionGetByGuid: (guid: string) =>
    `${CARRIER_SERVICE}${EDI_CARRIER_INVOICE_TRANSACTION_CONTROLLER}/${guid}`,
  // carrier integration customer config controller
  carrierIntegrationCustomerConfig: `${CARRIER_SERVICE}${CARRIER__INTEGRATION_CUSTOMER_CONFIG_CONTROLLER}`,
  carrierIntegrationCustomerConfigList: `${CARRIER_SERVICE}${CARRIER__INTEGRATION_CUSTOMER_CONFIG_CONTROLLER}/list`,
  restoreInheritedCarrierIntegrationCustomerConfig:
    `${CARRIER_SERVICE}${CARRIER__INTEGRATION_CUSTOMER_CONFIG_CONTROLLER}/restoreInherited`,
  // carrier synchronization config controller
  carrierSynchronizationConfig: `${CARRIER_SERVICE}carrierSynchronization/config`,
  removeCarrierSynchronizationConfig: (guid: string) =>
    `${CARRIER_SERVICE}carrierSynchronization/config/${guid}`,
  // carrier additional contacts controller
  additionalCarrierContact: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/contact`,
  additionalCarrierContactList: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/contact/list`,
  removeAdditionalCarrierContact: (guid: string) => `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/contact/${guid}`,
  // carrier integration
  sendCarrierListToIntegration: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/sendToIntegration`,
  carrierIntegrationInfoList: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/list/integrationInfo`,
  carrierIntegrationAuditList: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/list/integrationAudit`,
  // carrier rate integration controller
  carrierRateIntegrationConfig: `${CARRIER_SERVICE}${CARRIER_INTEGRATION_CONFIG_CONTROLLER}`,
  carrierRateIntegrationConfigList: `${CARRIER_SERVICE}${CARRIER_INTEGRATION_CONFIG_CONTROLLER}/list`,
  availableCarrierIntegrationList: `${CARRIER_SERVICE}${CARRIER_INTEGRATION_CONFIG_CONTROLLER}/list/availableTypes`,
  carrierIntegrationDispatchConfigList:
    `${CARRIER_SERVICE}${CARRIER_INTEGRATION_CONFIG_CONTROLLER}/list/dispatchConfigs`,
  carrierRateIntegrationConfigRestoreInherited:
    `${CARRIER_SERVICE}${CARRIER_INTEGRATION_CONFIG_CONTROLLER}/restoreInherited`,
  // carrier load board integration
  carrierLoadBoardIntegrationList: `${CARRIER_SERVICE}${CARRIER_LOAD_BOARD_INTEGRATION_CONTROLLER}/list`,
  // carrier load board synchronization
  addToNetwork: `${CARRIER_SERVICE}${CARRIER_LOAD_BOARD_SYNCHRONIZATION_CONTROLLER}/addToNetwork`,
  allowBookItNow: `${CARRIER_SERVICE}${CARRIER_LOAD_BOARD_SYNCHRONIZATION_CONTROLLER}/allowBookItNow`,
  forbidBookItNow: `${CARRIER_SERVICE}${CARRIER_LOAD_BOARD_SYNCHRONIZATION_CONTROLLER}/forbidBookItNow`,
  removeFromNetwork: `${CARRIER_SERVICE}${CARRIER_LOAD_BOARD_SYNCHRONIZATION_CONTROLLER}/removeFromNetwork`,
  addToNetworkByReport: `${CARRIER_SERVICE}${CARRIER_LOAD_BOARD_SYNCHRONIZATION_CONTROLLER}/addToNetworkByReport`,
  allowBookItNowByReport: `${CARRIER_SERVICE}${CARRIER_LOAD_BOARD_SYNCHRONIZATION_CONTROLLER}/allowBookItNowByReport`,
  // carrier rate integration service mapping
  serviceMapping:
    `${CARRIER_SERVICE}${CARRIER_RATING_INTEGRATION_ACCESSORIAL_SERVICE_MAPPING}`,
  serviceMappingMassCreate:
    `${CARRIER_SERVICE}${CARRIER_RATING_INTEGRATION_ACCESSORIAL_SERVICE_MAPPING}/massCreate`,
  serviceMappingList:
    `${CARRIER_SERVICE}${CARRIER_RATING_INTEGRATION_ACCESSORIAL_SERVICE_MAPPING}/list`,
  accessorialServiceCodes:
    `${CARRIER_SERVICE}${CARRIER_RATING_INTEGRATION_ACCESSORIAL_SERVICE_MAPPING}/serviceCodes`,
  serviceMappingRestoreInherited:
    `${CARRIER_SERVICE}${CARRIER_RATING_INTEGRATION_ACCESSORIAL_SERVICE_MAPPING}/restoreInherited`,
  // carrier contract bill to
  carrierContractBillTo: `${CARRIER_SERVICE}${CARRIER_CONTRACT_BILL_TO}`,
  removeCarrierContractBillTo: (guid: string) => `${CARRIER_SERVICE}${CARRIER_CONTRACT_BILL_TO}/${guid}`,
  // customer contract bill to
  customerContractBillTo: `${CARRIER_SERVICE}${CUSTOMER_CONTRACT_BILL_TO}`,
  removeCustomerContractBillTo: (guid: string) => `${CARRIER_SERVICE}${CUSTOMER_CONTRACT_BILL_TO}/${guid}`,
  getCustomerContractBillToByGuid: (guid: string) => `${CARRIER_SERVICE}${CUSTOMER_CONTRACT_BILL_TO}/${guid}`,
  // carrier default factoring payment term
  carrierDefaultFactoringPaymentTerm: `${CARRIER_SERVICE}defaultFactoringPaymentTerm`,
  carrierDefaultFactoringPaymentList: `${CARRIER_SERVICE}defaultFactoringPaymentTerm/list`,
  removeCarrierDefaultFactoringPaymentTerm: (guid: string) => `${CARRIER_SERVICE}defaultFactoringPaymentTerm/${guid}`,
  // rating geo fencing zone controller
  geoFencingZone: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/${GEO_FENCING_ZONE_CONTROLLER}`,
  geoFencingZoneList: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/${GEO_FENCING_ZONE_CONTROLLER}/list`,
  exportGeoFencingZone: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/${GEO_FENCING_ZONE_CONTROLLER}/export`,
  availableGeoFencingZone: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/${GEO_FENCING_ZONE_CONTROLLER}/available`,
  geoFencingZoneByGuid: (guid: string) =>
    `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/${GEO_FENCING_ZONE_CONTROLLER}/${guid}`,
  // report geo fencing zone controller
  reportGeoFencingZone: `${REPORT_SERVICE}${GEO_FENCING_ZONE_CONTROLLER}`,
  reportGeoFencingZoneList: `${REPORT_SERVICE}${GEO_FENCING_ZONE_CONTROLLER}/list`,
  exportReportGeoFencingZone: `${REPORT_SERVICE}${GEO_FENCING_ZONE_CONTROLLER}/export`,
  availableReportGeoFencingZone: `${REPORT_SERVICE}${GEO_FENCING_ZONE_CONTROLLER}/available`,
  reportGeoFencingZoneByGuid: (guid: string) => `${REPORT_SERVICE}${GEO_FENCING_ZONE_CONTROLLER}/${guid}`,
  // carrier safer watch controller
  carrierSaferWatch: `${CARRIER_SERVICE}${CARRIER_SAFER_WATCH_CONTROLLER}`,
  addToWatchList: `${CARRIER_SERVICE}${CARRIER_SAFER_WATCH_CONTROLLER}/addToWatchList`,
  addAllToWatchList: `${CARRIER_SERVICE}${CARRIER_SAFER_WATCH_CONTROLLER}/addAllToWatchList`,
  carrierSaferWatchMassUpdate: `${CARRIER_SERVICE}${CARRIER_SAFER_WATCH_CONTROLLER}/massUpdate`,
  removeFromWatchList: `${CARRIER_SERVICE}${CARRIER_SAFER_WATCH_CONTROLLER}/removeFromWatchList`,
  addToWatchListByReport: `${CARRIER_SERVICE}${CARRIER_SAFER_WATCH_CONTROLLER}/addToWatchListByReport`,
  carrierSaferWatchMassUpdateByReport: `${CARRIER_SERVICE}${CARRIER_SAFER_WATCH_CONTROLLER}/massUpdateByReport`,
  // carrier reference controller
  carrierReference: `${CARRIER_SERVICE}${CARRIER_REFERENCE_CONTROLLER}`,
  carrierReferenceList: `${CARRIER_SERVICE}${CARRIER_REFERENCE_CONTROLLER}/list`,
  getCarrierRefEndpoint: (guid: string) => `${CARRIER_SERVICE}${CARRIER_REFERENCE_CONTROLLER}/${guid}`,
  // carrier mail controller
  carrierMailList: `${CARRIER_SERVICE}${CARRIER_MAIL_CONTROLLER}/list`,
  downloadCarrierMail: (guid: string) => `${CARRIER_SERVICE}${CARRIER_MAIL_CONTROLLER}/${guid}/downloadMailMessage`,
  // carrier onboarding package controller
  carrierOnboardingPackage: `${CARRIER_SERVICE}${CARRIER_ONBOARDING_PACKAGE_CONTROLLER}`,
  carrierOnboardingPackageList: `${CARRIER_SERVICE}${CARRIER_ONBOARDING_PACKAGE_CONTROLLER}/list`,
  carrierOnboardingPackageRestoreInherited:
    `${CARRIER_SERVICE}${CARRIER_ONBOARDING_PACKAGE_CONTROLLER}/restoreInherited`,
  // carrier onboarding package document controller
  carrierOnboardingPackageDocument: `${CARRIER_SERVICE}${CARRIER_ONBOARDING_PACKAGE_DOCUMENT_CONTROLLER}`,
  carrierOnboardingPackageDocumentList: `${CARRIER_SERVICE}${CARRIER_ONBOARDING_PACKAGE_DOCUMENT_CONTROLLER}/list`,
  updateCarrierOnboardingPackageDocument: `${CARRIER_SERVICE}${CARRIER_ONBOARDING_PACKAGE_DOCUMENT_CONTROLLER}/update`,
  downloadCarrierOnboardingPackageDocument:
    `${CARRIER_SERVICE}${CARRIER_ONBOARDING_PACKAGE_DOCUMENT_CONTROLLER}/downloadFile`,
  deleteCarrierOnboardingPackageDocument: (guid: string) =>
    `${CARRIER_SERVICE}${CARRIER_ONBOARDING_PACKAGE_DOCUMENT_CONTROLLER}/${guid}`,
  // carrier associated company controller
  carrierAssocCompany: `${CARRIER_SERVICE}${CARRIER_ASSOC_COMPANY_CONTROLLER}`,
  carrierAssocCompanyList: `${CARRIER_SERVICE}${CARRIER_ASSOC_COMPANY_CONTROLLER}/list`,
  getCarrierAssocCompanyEndpoint: (guid: string) => `${CARRIER_SERVICE}${CARRIER_ASSOC_COMPANY_CONTROLLER}/${guid}`,
  // carrier insuarance controller
  carrierInsurance: `${CARRIER_SERVICE}${CARRIER_INSURANCE_CONTROLLER}`,
  carrierInsuranceList: `${CARRIER_SERVICE}${CARRIER_INSURANCE_CONTROLLER}/list`,
  carrierInsuranceMassSave: `${CARRIER_SERVICE}${CARRIER_INSURANCE_CONTROLLER}/massSave`,
  carrierInsuranceListExport: `${CARRIER_SERVICE}${CARRIER_INSURANCE_CONTROLLER}/list/export`,
  getCarrierInsuranceEndpoint: (guid: string) => `${CARRIER_SERVICE}${CARRIER_INSURANCE_CONTROLLER}/${guid}`,
  // carrier document controller
  carrierDoc: `${CARRIER_SERVICE}${CARRIER_DOCUMENT_CONTROLLER}`,
  carrierDocList: `${CARRIER_SERVICE}${CARRIER_DOCUMENT_CONTROLLER}/list`,
  carrierDocUpdate: `${CARRIER_SERVICE}${CARRIER_DOCUMENT_CONTROLLER}/update`,
  carrierDownloadDoc: `${CARRIER_SERVICE}${CARRIER_DOCUMENT_CONTROLLER}/downloadFile`,
  getCarrierDocEndpoint: (guid: string) => `${CARRIER_SERVICE}${CARRIER_DOCUMENT_CONTROLLER}/${guid}`,
  // carrier shared accessorials
  addCarrierSharedAccessorials: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/addSharedAssessorials`,
  removeCarrierSharedAccessorials: `${CARRIER_SERVICE}${CARRIER_CONTROLLER}/removeSharedAssessorials`,
  // rate engine controller
  carrierAssessorial: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrier/assessorial`,
  carrierAssessorialList: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrier/assessorial/list`,
  getCarrierAssessorialEndpoint: (guid: string) => `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrier/assessorial/${guid}`,
  // rate engine controller - awarded line
  carrierContractAwardedLineAssessorial: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/awardedLine/assessorial`,
  carrierContractAwardedLineAssessorialList: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/awardedLine/assessorial/list`,
  getCarrierAwardedLineAssessorialEndpoint: (guid: string) => `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/awardedLine/assessorial/${guid}`,
  // rate engine controller - rate price
  carrierRatePrice: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/ratePrice`,
  exportRatePrice: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/ratePrice/export`,
  carrierRatePriceList: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/ratePrice/list`,
  deleteCarrierRatePrices: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/ratePrice/massDelete`,
  carrierContractRateAssessorial: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/ratePrice/assessorial`,
  carrierContractRateAssessorialList: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/ratePrice/assessorial/list`,
  addRatePriceSharedAccessorials: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/ratePrice/addSharedAssessorials`,
  getCarrierRatePriceEndpoint: (guid: string) => `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/ratePrice/${guid}`,
  removeRatePriceSharedAccessorials: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/ratePrice/removeSharedAssessorials`,
  getCarrierContractRateAssessorial: (guid: string) => `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/ratePrice/assessorial/${guid}`,
  // rate engine controller - carrier contract
  carrierContract: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract`,
  carrierContracts: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/list`,
  carrierContractsExport: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/list/export`,
  carrierContractAssessorial: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/assessorial`,
  carrierContractAwardedLine: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/awardedLine`,
  carrierContractsAvailable: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/list/available`,
  getCarrierContract: (guid: string) => `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/${guid}`,
  carrierContractAssessorialList: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/assessorial/list`,
  carrierContractAwardedLineList: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/awardedLine/list`,
  carrierContractsByCarrierGuid: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/list/byCarrierGuid`,
  addCarrierContractSharedAccessorials: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/addSharedAssessorials`,
  removeCarrierContractSharedAccessorials: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/removeSharedAssessorials`,
  getCarrierAwardedLineEndpoint: (guid: string) => `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/awardedLine/${guid}`,
  addAwardedLineSharedAccessorials: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/awardedLine/addSharedAssessorials`,
  removeAwardedLineSharedAccessorials: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/awardedLine/removeSharedAssessorials`,
  // rate engine controller - customer contract
  // TODO: for now we have the same awarded lines endpoints for carrier and customer contracts
  customerContract: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/customerContract`,
  customersContract: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/customerContract/list`,
  customerContractsExport: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/customerContract/list/export`,
  customerContractAwardedLine: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/awardedLine`,
  customerContractAssessorial: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/customerContract/assessorial`,
  getCustomerContract: (guid: string) => `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/customerContract/${guid}`,
  customerContractAwardedLineList: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/awardedLine/list`,
  customerContractAssessorialList: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/customerContract/assessorial/list`,
  customersContractByCarrierGuid: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/customerContract/list/byCarrierGuid`,
  customersContractGrantToBranches: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/customerContract/grantToEnterprises`,
  customersContractRevokeFromBranches: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/customerContract/revokeFromEnterprises`,
  addCustomerContractSharedAccessorials: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/customerContract/addSharedAssessorials`,
  removeCustomerContractSharedAccessorials: `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/customerContract/removeSharedAssessorials`,
  getCustomerContractAwardedLineEndpoint: (guid: string) => `${CARRIER_SERVICE}${RATE_ENGINE_CONTROLLER}/carrierContract/awardedLine/${guid}`,
  // terminal controller
  carrierTerminal: `${CARRIER_SERVICE}${CARRIER_TERMINAL_CONTROLLER}`,
  carrierTerminalList: `${CARRIER_SERVICE}${CARRIER_TERMINAL_CONTROLLER}/list`,
  carrierTerminalFinance: `${CARRIER_SERVICE}${CARRIER_TERMINAL_CONTROLLER}/financialDetails`,
  getCarrierTerminalEndpoint: (guid: string) => `${CARRIER_SERVICE}${CARRIER_TERMINAL_CONTROLLER}/${guid}/`,
  carrierTerminalPayLocation: `${CARRIER_SERVICE}${CARRIER_TERMINAL_CONTROLLER}/financialDetails/payToLocation`,
  getCarrierTerminalDispatchProcessEndpoint: (guid: string) => (
    `${CARRIER_SERVICE}${CARRIER_TERMINAL_CONTROLLER}/${guid}/dispatchProcess`
  ),
  carrierTerminalPayLocationList: (
    `${CARRIER_SERVICE}${CARRIER_TERMINAL_CONTROLLER}/financialDetails/payToLocation/list`
  ),
  getTerminalPayLocationEndpoint: (guid: string) => (
    `${CARRIER_SERVICE}${CARRIER_TERMINAL_CONTROLLER}/financialDetails/payToLocation/${guid}`
  ),
  // carrier terminal contact controller
  carrierTerminalContact: `${CARRIER_SERVICE}${CARRIER_TERMINAL_CONTACT_CONTROLLER}`,
  carrierTerminalContactList: `${CARRIER_SERVICE}${CARRIER_TERMINAL_CONTACT_CONTROLLER}/list`,
  getTerminalContactEndpoint: (guid: string) => `${CARRIER_SERVICE}${CARRIER_TERMINAL_CONTACT_CONTROLLER}/${guid}`,
  // carrier financial controller
  carrierFinance: `${CARRIER_SERVICE}${CARRIER_FINANCIAL_CONTROLLER}`,
  carrierPayLocation: `${CARRIER_SERVICE}${CARRIER_FINANCIAL_CONTROLLER}/payToLocation`,
  carrierPayLocationList: `${CARRIER_SERVICE}${CARRIER_FINANCIAL_CONTROLLER}/payToLocation/list`,
  getCarrierPayLocationEndpoint: (guid: string) => (
    `${CARRIER_SERVICE}${CARRIER_FINANCIAL_CONTROLLER}/payToLocation/${guid}`
  ),
  carrierCertificate: `${CARRIER_SERVICE}${CARRIER_HAZMAT_CONTROLLER}`,
  getCarrierCertificateEndpoint: (guid: string) => `${CARRIER_SERVICE}${CARRIER_HAZMAT_CONTROLLER}/${guid}`,
  // carrier internal dispatch settings controller
  carrierInternalDispatchSettings: `${CARRIER_SERVICE}${CARRIER_INTERNAL_DISPATCH_SETTINGS}`,
  carrierInternalDispatchSettingsByGuid: (guid: string) => `${CARRIER_SERVICE}${CARRIER_INTERNAL_DISPATCH_SETTINGS}/${guid}`,
  // service vendor controller
  serviceVendor: `${FLEET_SERVICE}${SERVICE_VENDOR_CONTROLLER}`,
  serviceVendorList: `${FLEET_SERVICE}${SERVICE_VENDOR_CONTROLLER}/list`,
  serviceVendorExportReport: `${FLEET_SERVICE}${CARRIER_CONTROLLER}/list/export`,
  serviceVendorMassDelete: `${FLEET_SERVICE}${SERVICE_VENDOR_CONTROLLER}/massDelete`,
  serviceVendorListByRepairZone: `${FLEET_SERVICE}${SERVICE_VENDOR_CONTROLLER}/list/byRepairZone`,
  getServiceVendorByGuid: (guid: string) => `${FLEET_SERVICE}${SERVICE_VENDOR_CONTROLLER}/${guid}`,
  serviceVendorListAvailable: `${FLEET_SERVICE}${CARRIER_SERVICE_VENDOR_CONTROLLER}/list/available`,
  sendServiceVendorListToIntegration: `${FLEET_SERVICE}${SERVICE_VENDOR_CONTROLLER}/sendToIntegration`,
  serviceVendorIntegrationInfoList: `${FLEET_SERVICE}${SERVICE_VENDOR_CONTROLLER}/list/integrationInfo`,
  serviceVendorIntegrationAuditList: `${FLEET_SERVICE}${SERVICE_VENDOR_CONTROLLER}/list/integrationAudit`,
  // service vendor document controller
  serviceVendorDoc: `${FLEET_SERVICE}${SERVICE_VENDOR_DOCUMENT_CONTROLLER}`,
  serviceVendorDocList: `${FLEET_SERVICE}${SERVICE_VENDOR_DOCUMENT_CONTROLLER}/list`,
  serviceVendorDocUpdate: `${FLEET_SERVICE}${SERVICE_VENDOR_DOCUMENT_CONTROLLER}/update`,
  serviceVendorDocDownloadFile: `${FLEET_SERVICE}${SERVICE_VENDOR_DOCUMENT_CONTROLLER}/downloadFile`,
  getCurrentServiceVendorDocEndpoint: (guid: string) => `${FLEET_SERVICE}${SERVICE_VENDOR_DOCUMENT_CONTROLLER}/${guid}`,
  // service vendor repair zone controller
  serviceVendorRepairZone: `${FLEET_SERVICE}${SERVICE_VENDOR_REPAIR_ZONE_CONTROLLER}`,
  serviceVendorRepairZoneList: `${FLEET_SERVICE}${SERVICE_VENDOR_REPAIR_ZONE_CONTROLLER}/list`,
  serviceVendorRepairZoneByGuid: (guid: string) => `${FLEET_SERVICE}${SERVICE_VENDOR_REPAIR_ZONE_CONTROLLER}/${guid}`,
  // NOTIFICATIONS SERVICE
  notificationWS: `${NOTIFICATION_SERVICE}websocket`,
  notificationList: `${NOTIFICATION_SERVICE}notification/list`,
  notificationMark: `${NOTIFICATION_SERVICE}notification/mark`,
  hideWarnings: `${NOTIFICATION_SERVICE}notification/hideWarnings`,
  deleteAllNotifications: `${NOTIFICATION_SERVICE}notification/deleteAll`,
  notificationUnreadCount: `${NOTIFICATION_SERVICE}notification/unreadCount`,
  deleteSelectedNotifications: `${NOTIFICATION_SERVICE}notification/deleteSelected`,
  // LOAD BOARDS SERVICE
  loadBoardsSocket: `${LOAD_BOARD_SERVICE}websocket`,
  loadBoardLogin: `${LOAD_BOARD_SERVICE}account/login`,
  loadBoardLogout: `${LOAD_BOARD_SERVICE}account/logout`,
  loadBoardFilter: `${LOAD_BOARD_SERVICE}loadboard/search`,
  loadBoardShipment: `${LOAD_BOARD_SERVICE}loadboard/shipment`,
  getLoadBoardLoginMap: `${LOAD_BOARD_SERVICE}account/getLogin`,
  getLoadBoardFilters: `${LOAD_BOARD_SERVICE}loadboard/search/list`,
  getAvailableLoadBoards: `${LOAD_BOARD_SERVICE}loadBoard/available`,
  loadBoardTemplate: `${LOAD_BOARD_SERVICE}loadboard/search/template`,
  getLoadBoardAlarmShipments: `${LOAD_BOARD_SERVICE}loadboard/search/alarm`,
  loadBoardCompanyFilter: `${LOAD_BOARD_SERVICE}loadboard/search/companyFilter`,
  getLoadBoardTemplateList: `${LOAD_BOARD_SERVICE}loadboard/search/template/list`,
  loadBoardCompanyFilterList: `${LOAD_BOARD_SERVICE}loadboard/search/companyFilter/list`,
  availableForCarrierLoadBoards: `${LOAD_BOARD_SERVICE}loadBoard/availableForEditCarrier`,
  getLoadBoardTemplateEndpoint: (guid: string) => `${LOAD_BOARD_SERVICE}loadboard/search/template/${guid}`,
  getLoadBoardCompanyFilterEndpoint: (guid: string) => (
    `${LOAD_BOARD_SERVICE}loadboard/search/companyFilter/${guid}`
  ),
  // load-board config
  loadBoardConfig: `${LOAD_BOARD_SERVICE}${LOAD_BOARD_CONFIG}`,
  loadBoardConfigList: `${LOAD_BOARD_SERVICE}${LOAD_BOARD_CONFIG}/list`,
  loadBoardConfigRestoreInherited: `${LOAD_BOARD_SERVICE}${LOAD_BOARD_CONFIG}/restoreInherited`,
  // LOAD BOARDS SERVICE NEW
  loadBoardSocketService: `${LOAD_BOARD_SERVICE_NEW}websocket`,
  loadBoardLoadSocketService: `${LOAD_BOARD_LOAD_SERVICE}websocket`,
  // LOAD BOARD LB123 SERVICE
  loadBoard123Details: `${LOAD_BOARD_LB123_SERVICE}load/detail`,
  // tel load board
  postedShipmentsInfo: `${LOAD_SERVICE}${TEL_LOAD_BOARD_CONTROLLER}/postedShipmentsInfo`,
  // search filter
  searchFilter: `${LOAD_BOARD_SERVICE_NEW}${SEARCH_FILTER_CONTROLLER}`,
  searchFilterStatus: `${LOAD_BOARD_SERVICE_NEW}${SEARCH_FILTER_CONTROLLER}/status`,
  searchFilterStatuses: `${LOAD_BOARD_SERVICE_NEW}${SEARCH_FILTER_STATE_CONTROLLER}/statuses`,
  searchFilterById: (id: string) => `${LOAD_BOARD_SERVICE_NEW}${SEARCH_FILTER_CONTROLLER}/${id}`,
  // search filter template
  searchFilterTemplate: `${LOAD_BOARD_SERVICE_NEW}${SEARCH_FILTER_TEMPLATE_CONTROLLER}`,
  searchFilterTemplateById: (id: string) => `${LOAD_BOARD_SERVICE_NEW}${SEARCH_FILTER_TEMPLATE_CONTROLLER}/${id}`,
  // search filter state
  searchFilterState: `${LOAD_BOARD_SERVICE_NEW}${SEARCH_FILTER_STATE_CONTROLLER}`,
  searchFilterStateStatus: `${LOAD_BOARD_SERVICE_NEW}${SEARCH_FILTER_STATE_CONTROLLER}/status`,
  searchFilterStateById: (id: string) => `${LOAD_BOARD_SERVICE_NEW}${SEARCH_FILTER_STATE_CONTROLLER}/${id}`,
  // load board login
  loadBoardLoginNew: `${LOAD_BOARD_SERVICE_NEW}${LB_LOGIN}`,
  loadBoardLoginById: (id: string) => `${LOAD_BOARD_SERVICE_NEW}${LB_LOGIN}/${id}`,
  // load board config
  loadBoardConfigNew: `${LOAD_BOARD_SERVICE_NEW}${LOAD_BOARD_CONFIG_NEW}`,
  loadBoardConfigStatusNew: `${LOAD_BOARD_SERVICE_NEW}${LOAD_BOARD_CONFIG_NEW}/status`,
  loadBoardConfigById: (id: string) => `${LOAD_BOARD_SERVICE_NEW}${LOAD_BOARD_CONFIG_NEW}/${id}`,
  // committed shipment
  committedShipment: `${LOAD_BOARD_SERVICE_NEW}${COMMITTED_SHIPMENT}`,
  committedShipmentPage: `${LOAD_BOARD_SERVICE_NEW}${COMMITTED_SHIPMENT}/page`,
  committedShipmentById: (id: string) => `${LOAD_BOARD_SERVICE_NEW}${COMMITTED_SHIPMENT}/${id}`,
  // booking
  committedShipmentBooking: `${LOAD_BOARD_SERVICE_NEW}${COMMITTED_SHIPMENT}/booking`,
  committedShipmentBookingById: (id: string) => `${LOAD_BOARD_SERVICE_NEW}${COMMITTED_SHIPMENT}/booking/${id}`,
  // denied companies
  deniedCompany: `${LOAD_BOARD_SERVICE_NEW}${DENIED_COMPANY}`,
  deniedCompanyPage: `${LOAD_BOARD_SERVICE_NEW}${DENIED_COMPANY}/page`,
  deniedCompanyById: (id: string) => `${LOAD_BOARD_SERVICE_NEW}${DENIED_COMPANY}/${id}`,
  // posted shipment
  postedShipment: `${LOAD_BOARD_SERVICE_NEW}${POSTED_SHIPMENT}`,
  postedShipmentBulk: `${LOAD_BOARD_SERVICE_NEW}${POSTED_SHIPMENT}/bulk`,
  postedShipmentById: (id: string) => `${LOAD_BOARD_SERVICE_NEW}${POSTED_SHIPMENT}/${id}`,
  // posted shipment state
  postedShipmentState: `${LOAD_BOARD_SERVICE_NEW}${POSTED_SHIPMENT_STATE}`,
  postedShipmentStateById: (id: string) => `${LOAD_BOARD_SERVICE_NEW}${POSTED_SHIPMENT_STATE}/${id}`,
  postedShipmentStateRepost: (id: string) => `${LOAD_BOARD_SERVICE_NEW}${POSTED_SHIPMENT_STATE}/${id}/repost`,
  // CUSTOMER
  // loaderConfig
  loaderConfig: `${CUSTOMER_SERVICE}loaderConfig`,
  loaderConfigList: `${CUSTOMER_SERVICE}loaderConfig/list`,
  getRemoveLoaderConfig: (guid: string) => `${CUSTOMER_SERVICE}loaderConfig/${guid}`,
  // exporterConfig
  exporterConfig: `${CUSTOMER_SERVICE}exporterConfig`,
  exporterConfigConfigList: `${CUSTOMER_SERVICE}exporterConfig/list`,
  getRemoveExporterConfig: (guid: string) => `${CUSTOMER_SERVICE}exporterConfig/${guid}`,
  // extractor config
  extractorConfig: `${CUSTOMER_SERVICE}extractorConfig`,
  extractorConfigList: `${CUSTOMER_SERVICE}extractorConfig/list`,
  getRemoveExtractorConfig: (guid: string) => `${CUSTOMER_SERVICE}extractorConfig/${guid}`,
  // public clo config
  publicCLOConfig: `${CUSTOMER_SERVICE}publicApiOrderConfig`,
  removePublicCLOConfig: (guid: string) => `${CUSTOMER_SERVICE}publicApiOrderConfig/${guid}`,
  // public clo
  publicCLO: `${CUSTOMER_SERVICE}receivedOrderRequest`,
  publicCLOList: `${CUSTOMER_SERVICE}receivedOrderRequest/list`,
  publicCLOGetByGuid: (guid: string) => `${CUSTOMER_SERVICE}receivedOrderRequest/${guid}`,
  // receivedClo
  receivedClo: `${CUSTOMER_SERVICE}receivedClo`,
  receivedCloList: `${CUSTOMER_SERVICE}receivedClo/list`,
  receivedCloAccept: `${CUSTOMER_SERVICE}receivedClo/accept`,
  receivedCloDecline: `${CUSTOMER_SERVICE}receivedClo/decline`,
  receivedCloDownloadFile: `${CUSTOMER_SERVICE}receivedClo/downloadFile`,
  getReceivedClo: (guid: string) => `${CUSTOMER_SERVICE}receivedClo/${guid}`,
  receivedCloAvailableDeclineReasons: `${CUSTOMER_SERVICE}receivedClo/availableDeclineReasons`,
  // container type
  containerType: `${LOAD_SERVICE}${CONTAINER_TYPE_CONTROLLER}`,
  containerTypeList: `${LOAD_SERVICE}${CONTAINER_TYPE_CONTROLLER}/list`,
  getRemoveContainerType: (guid: string) => `${LOAD_SERVICE}${CONTAINER_TYPE_CONTROLLER}/${guid}`,
  // external
  appReleaseByExternalUrl: 'https://api.amousinternational.com/releases/last_version',
  // driver expense type
  driverExpenseType: `${FLEET_SERVICE}${DRIVER_EXPENSE_TYPE_CONTROLLER}`,
  driverExpenseTypeList: `${FLEET_SERVICE}${DRIVER_EXPENSE_TYPE_CONTROLLER}/list`,
  driverExpenseTypeRestoreInherited: `${FLEET_SERVICE}${DRIVER_EXPENSE_TYPE_CONTROLLER}/restoreInherited`,
  // fleet driver expense
  driverExpense: `${FLEET_SERVICE}${FLEET_DRIVER_EXPENSE_CONTROLLER}`,
  driverExpenseList: `${FLEET_SERVICE}${FLEET_DRIVER_EXPENSE_CONTROLLER}/list`,
  driverExpenseByGuid: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_EXPENSE_CONTROLLER}/${guid}`,
  driverExpenseListForPayroll: `${FLEET_SERVICE}${FLEET_DRIVER_EXPENSE_CONTROLLER}/list/availableForPayroll`,
  getDriverExpenseApprove: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_EXPENSE_CONTROLLER}/${guid}/approve`,
  getDriverExpenseDecline: (guid: string) => `${FLEET_SERVICE}${FLEET_DRIVER_EXPENSE_CONTROLLER}/${guid}/decline`,
  driverExpenseListForVendorPayroll: `${FLEET_SERVICE}${
    FLEET_DRIVER_EXPENSE_CONTROLLER}/list/availableForVendorPayroll`,
  // fleet driver expense document
  driverExpenseDocument: `${FLEET_SERVICE}${FLEET_DRIVER_EXPENSE_DOCUMENT_CONTROLLER}`,
  driverExpenseDocumentDownload: `${FLEET_SERVICE}${FLEET_DRIVER_EXPENSE_DOCUMENT_CONTROLLER}/download`,
  // Web socket service
  webSocketServiceToken: `${WEB_SOCKET_SERVICE}token`,
  // template/inspection
  inspectionTemplate: `${FLEET_SERVICE}${INSPECTION_TEMPLATE_CONTROLLER}`,
  inspectionTemplateListUp: `${FLEET_SERVICE}${INSPECTION_TEMPLATE_CONTROLLER}/list`,
  inspectionTemplateListDown: `${FLEET_SERVICE}${INSPECTION_TEMPLATE_CONTROLLER}/list/down`,
  inspectionTemplateByGuid: (guid: string) => `${FLEET_SERVICE}${INSPECTION_TEMPLATE_CONTROLLER}/${guid}`,
  // geoFencingLocation
  geoFencingLocation: `${FLEET_SERVICE}${GEO_FENCING_LOCATION_CONTOILLER}`,
  geoFencingLocationList: `${FLEET_SERVICE}${GEO_FENCING_LOCATION_CONTOILLER}/list`,
  geoFencingLocationAvailableList: `${FLEET_SERVICE}${GEO_FENCING_LOCATION_CONTOILLER}/list/available`,
  geoFencingLocationByGuid: (guid: string) => `${FLEET_SERVICE}${GEO_FENCING_LOCATION_CONTOILLER}/${guid}`,
  //
  aiDocumentRecognize: `${AI_SERVICE}document-recognition/recognize`,
};

export default endpoints;
