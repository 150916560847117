import * as R from 'ramda';
import styled, { css } from 'styled-components';
import {
  space,
  width,
  order,
  color,
  height,
  border,
  display,
  minWidth,
  fontSize,
  borderColor,
  borderRadius,
} from 'styled-system';
// helpers
import * as G from '../helpers';
//////////////////////////////////////////////////

const BtnDefaultStyles = css`
  height: 40px;
  font-weight: 500;
  border-radius: 2px;
`;

export const Button = styled.button`
  ${space}
  ${width}
  ${order}
  ${height}
  ${fontSize}

  outline: 0;
  border: none;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: middle;

  cursor: ${({ cursor }: Object) => cursor || 'pointer'};
`;

const InstanceButton = styled(Button)`
  ${width}
  ${minWidth}
  ${borderRadius}

  display: ${({ display }: Object) => display};
  border: ${({ border }: Object) => border || 'none'};
  z-index: ${({ zIndex }: Object) => zIndex || 'unset'};
  background: ${({ background }: Object) => background};
  font-weight: ${({ fontWeight }: Object) => fontWeight};
  cursor: ${({ cursor }: Object) => cursor || 'pointer'};
  text-transform: ${({ textTransform }: Object) => textTransform};
  visibility: ${({ visibility }: Object) => visibility || 'visible'};
`;

InstanceButton.defaultProps = {
  height: 40,
  minWidth: 100,
  borderRadius: '2px',
};

export const CancelButton = styled(InstanceButton)`
  color: ${({ textColor }: Object) => textColor || G.getTheme('buttons.cancelBtn.textColor')};
  background-color: ${({ bgColor }: Object) => bgColor || G.getTheme('buttons.cancelBtn.bgColor')};
`;

export const ActionButton = styled(InstanceButton)`
  border-color: ${({ borderColor }: Object) => borderColor};
  background-color: ${({ bgColor }: Object) => bgColor || G.getTheme('colors.dark.blue')};
  color: ${({ textColor }: Object) => textColor || G.getTheme('buttons.saveBtn.textColor')};

  ${({ disabled }: Object) => disabled && `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const MainActionButton = styled.button`
  ${space}
  ${color}
  ${width}
  ${border}
  ${display}
  ${height}
  ${display}
  ${fontSize}
  ${minWidth}
  ${borderColor}
  ${borderRadius}

  outline: 0;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: middle;

  cursor: ${({ cursor }: Object) => cursor || 'pointer'};
  text-transform: ${({ textTransform }: Object) => textTransform};

  ${({ disabled }: Object) => disabled && `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

MainActionButton.defaultProps = {
  height: 32,
  bg: 'white',
  p: '4px 8px',
  minWidth: 60,
  fontSize: 14,
  border: '1px solid',
  borderRadius: '5px',
  color: 'dark.darkBlue',
  borderColor: 'dark.darkBlue',
};

export const CustomButton = styled(Button)`
  ${borderRadius}

  color: ${({ textColor }: Object) => textColor || G.getTheme('buttons.saveBtn.textColor')};

  background-color: ${({ bgColor, disabled }: Object) => (
    G.ifElse(
      R.equals(disabled, true),
      G.getTheme('buttons.disabled.bgColor'),
      bgColor || G.getTheme('colors.dark.blue'),
    ))};
`;

CustomButton.defaultProps = {
  height: 40,
  borderRadius: '2px',
};

export const FormSaveAsNewButton = styled(Button)`
  ${BtnDefaultStyles};

  float: none;
  min-width: 100px;

  background-color: ${() => G.getTheme('colors.dark.blue')};
  color: ${() => G.getTheme('buttons.saveAsNewBtn.textColor')};
`;

export const FormCancelButton = styled(Button)`
  ${BtnDefaultStyles};

  color: ${() => G.getTheme('buttons.cancelBtn.textColor')};
  background-color: ${() => G.getTheme('buttons.cancelBtn.bgColor')};
`;

export const FormSaveButton = styled(Button)`
  ${BtnDefaultStyles};

  min-width: 100px;

  background-color: ${({ disabled }: Object) => (
    G.ifElse(
      R.equals(disabled, true),
      G.getTheme('buttons.disabled.bgColor'),
      G.getTheme('colors.dark.blue'),
    ))};
  color: ${({ disabled }: Object) => (
    G.ifElse(
      R.equals(disabled, true),
      G.getTheme('buttons.disabled.textColor'),
      G.getTheme('buttons.saveBtn.textColor'),
    ))};
`;

export const FormAdditionButton = styled(Button)`
  ${BtnDefaultStyles};

  float: none;
  min-width: 100px;

  color: ${() => G.getTheme('buttons.saveAsNewBtn.textColor')};
  background-color: ${({ bgColor }: Object) => bgColor || G.getTheme('colors.dark.blue')};
`;

export const ResetButton = styled(Button)`
  height: 40px;
  min-width: 100px;
  border-radius: 2px;

  color: ${() => G.getTheme('buttons.cancelBtn.textColor')};
  background-color: ${() => G.getTheme('buttons.cancelBtn.bgColor')};
`;

export const DispatchButton = styled(Button)`
  height: 20px;
  min-width: 65px;
  border-radius: 2px;

  color: ${() => G.getTheme('buttons.dispatchBtn.textColor')};
  background-color: ${() => G.getTheme('buttons.dispatchBtn.bgColor')};
`;

export const ModalButton = styled(InstanceButton)`
  height: 32px;
  font-size: 14;
  padding: 4px 8px;
  background: none;
  border: 1px solid;
  border-radius: 5px;
  text-transform: uppercase;
`;

export const ModalCancelButton = styled(ModalButton)`
  color: ${() => G.getTheme('colors.greyMatterhorn')};
  border-color: ${() => G.getTheme('colors.greyMatterhorn')};
`;

export const ModalActionButton = styled(ModalButton)`
  color: ${() => G.getTheme('colors.dark.blue')};
  border-color: ${() => G.getTheme('colors.dark.blue')};
`;
